import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import {
  DxoEditingModule,
  DxoFilterRowModule,
  DxoLoadPanelModule,
  DxoLookupModule,
  DxoScrollingModule,
  DxoSearchPanelModule,
} from 'devextreme-angular/ui/nested';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { ModalWindowModule, DynamicListsGroupService } from 'ag-common-svc/public-api';
import { NotificationAddListsModalComponent } from './notification-add-lists-modal.component';

@NgModule({
  declarations: [NotificationAddListsModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,

    DxoEditingModule,
    DxTemplateModule,
    DxoLookupModule,
    DxoFilterRowModule,
    DxoLoadPanelModule,
    DxoSearchPanelModule,
    DxoScrollingModule,
    DxDataGridModule,
    ModalWindowModule,
  ],
  exports: [NotificationAddListsModalComponent],
  providers: [DynamicListsGroupService],
})
export class NotificationAddListsModalModule {}
