import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ConferenceModalComponent } from './conference-modal.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import {
  DxAccordionModule,
  DxCheckBoxModule,
  DxDateRangeBoxModule,
  DxDropDownButtonModule,
  DxNumberBoxModule,
  DxTabPanelModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { AddressFormModule, HtmlEditorModule, ModalWindowModule } from 'ag-common-svc/public-api';
import { ConferencePrimaryInfoFormComponent } from './conference-primary-info-form/conference-primary-info-form.component';
import { ConferenceEventFormComponent } from './conference-event/conference-event.component';
import { ConferenceHotelInfoFormComponent } from './conference-hotel-info-form/conference-hotel-info-form.component';
import { ConferenceGuestsFormComponent } from './conference-guests-form/conference-guests-form.component';
import { FilterRegistrationTypeLookupPipe } from './conference-hotel-info-form/filter-registration-type-lookup.pipe';
import { LockedDatesPipe } from './conference-hotel-info-form/locked-dates.pipe';
import { ConferenceExcursionsFormComponent } from './conference-excursions-form/conference-excursions-form.component';
import { ExcursionModalModule } from '../excursion-modal/excursion-modal.module';
import { MaxGuestEndAgeForRatePipe } from './conference-guests-form/max-guest-end-rate.pipe';
import { MaxGuestEndAgeForRateDisabledPipe } from './conference-guests-form/max-guest-end-rate-disabled.pipe';
import { MaxGuestStartAgeForRatePipe } from './conference-guests-form/max-guest-start-rate.pipe';
import { ConferenceEmailSenderFormComponent } from './conference-email-sender-form/conference-email-sender-form.component';
import { ConferenceWelcomeInfoFormComponent } from './conference-welcome-info-form/conference-welcome-info-form.component';
import { ImageUploaderComponent } from './conference-welcome-info-form/image-uploader/image-uploader.component';
import { ConferenceStepsInfoConfigurationFormComponent } from './conference-steps-info-configuration-form/conference-steps-info-configuration-form.component';
import { AgMediaUploaderModalModule } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.module';
import { ImageCropperModalModule } from 'ag-common-svc/lib/components/image-cropper-modal/image-cropper-modal.module';
import { ImageWithLoaderModule } from 'ag-common-svc/lib/components/image-with-loader/image-with-loader.module';
import { ConferenceStepInfoModalComponent } from './conference-steps-info-configuration-form/conference-step-info-modal/conference-step-info-modal.component';
import { ConferenceStepNamePipe } from './conference-steps-info-configuration-form/pipes/conference-step-name.pipe';
import { ConferenceExcludedSectionsPipe } from './conference-steps-info-configuration-form/pipes/conference-excluded-sections.pipe';
import { ConferenceRegistrationPipesModule } from 'ag-common-svc/shared/pipes/conference-registration/conference-registration-pipes.module';
import { ConferenceExcludedFieldsPipe } from './conference-steps-info-configuration-form/pipes/conference-excluded-fields.pipe';
import { ConferenceStepStateKeyPipe } from './conference-steps-info-configuration-form/pipes/conference-step-state-key.pipe';
import { ConferencesPipesModule } from '../../pipes/conferences-pipes.module';
import { AgCrmCommonModule } from 'src/app/ag-crm/common/ag-crm-common.module';
import { ConferencePermissionsFormComponent } from './conference-permissions-form/conference-permissions-form.component';
import { ConferencePermissionsModalComponent } from './conference-permissions-form/conference-permissions-modal/conference-permissions-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DxAccordionModule,
    ModalWindowModule,
    AddressFormModule,
    DxDateRangeBoxModule,
    DxValidatorModule,
    ExcursionModalModule,
    DxNumberBoxModule,
    HtmlEditorModule,
    DxTabPanelModule,
    DxDropDownButtonModule,
    AgMediaUploaderModalModule,
    ImageCropperModalModule,
    ImageWithLoaderModule,
    ConferenceRegistrationPipesModule,
    DxCheckBoxModule,
    ConferencesPipesModule,
    AgCrmCommonModule,
  ],
  declarations: [
    ConferenceModalComponent,
    ConferencePrimaryInfoFormComponent,
    ConferenceEventFormComponent,
    ConferenceHotelInfoFormComponent,
    ConferenceGuestsFormComponent,
    FilterRegistrationTypeLookupPipe,
    LockedDatesPipe,
    ConferenceExcursionsFormComponent,
    MaxGuestEndAgeForRatePipe,
    MaxGuestEndAgeForRateDisabledPipe,
    MaxGuestStartAgeForRatePipe,
    ConferenceEmailSenderFormComponent,
    ConferenceWelcomeInfoFormComponent,
    ImageUploaderComponent,
    ConferenceStepsInfoConfigurationFormComponent,
    ConferenceStepNamePipe,
    ConferenceExcludedSectionsPipe,
    ConferenceStepInfoModalComponent,
    ConferenceExcludedFieldsPipe,
    ConferenceStepStateKeyPipe,
    ConferencePermissionsFormComponent,
    ConferencePermissionsModalComponent,
  ],
  exports: [ConferenceModalComponent],
  providers: [CurrencyPipe],
})
export class ConferenceModalModule {}
