import { Injectable } from '@angular/core';
import {
  ConferenceGuestsService,
  ConferenceRegistrantsService,
  ConferenceRegistrationLogsService,
  LookupsService,
} from 'ag-common-svc/public-api';
import { BehaviorSubject, combineLatest, firstValueFrom, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import {
  ActiveLookup,
  AssociationKeys,
  AttendeeKeys,
  AttendeeType,
  BaseModelKeys,
  ConferenceRegistrationCategoryName,
  ConferenceRegistrationCommonTask,
  ConferenceRegistrationLog,
  ConferenceRegistrationStepName,
  FlightBookingKeys,
  FlightInfoKeys,
  GuestKeys,
  HotelReservationKeys,
  LocalDateTimeString,
  LookupId,
  LookupKeys,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrationType,
  TravelMode,
} from 'ag-common-lib/public-api';
import {
  FlightBookingModel,
  FlightBookingStatus,
  FlightBookingStatusMap,
  FlightInformationModel,
} from 'ag-common-lib/lib/models/registration/flight-information.model';
import { isEmpty, pick } from 'lodash';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import * as jsondiffpatch from 'jsondiffpatch';

export const GROUP_TITLE_DIVIDER = ' — ';
export enum AttendeeFlightDataKeys {
  groupTitle = 'groupTitle',
  groupData = 'groupData',
  flightInformation = 'flightInformation',
  flightItinerary = 'flightItinerary',
  order = 'order',
}

export enum AttendeeDrivingDataKeys {
  attendeeTitle = 'attendeeTitle',
  reason = 'reason',
  additionalRequest = 'additionalRequest',
}

export class MainAttendeeFlightGroupData {
  [AttendeeKeys.attendeeType]: AttendeeType;
  [AttendeeKeys.dbId]: string;
  [AttendeeKeys.dob]: LocalDateTimeString;
  [AttendeeKeys.gender]: LookupId<'Genders'>;
  [AttendeeKeys.conferenceDbId]: string;
  [AttendeeKeys.registrantDbId]: string;
}
export class AttendeeFlightGroup extends MainAttendeeFlightGroupData {
  [AttendeeFlightDataKeys.groupTitle]: string;
  [FlightInfoKeys.status]: FlightBookingStatus;
  [AttendeeFlightDataKeys.flightInformation]: FlightInformationModel;
}

export type AttendeeFlightData = {
  [AttendeeKeys.dbId]: string;
  [AttendeeFlightDataKeys.order]: number;
  [AttendeeFlightDataKeys.flightItinerary]: FlightBookingModel;
};

export type AttendeeDrivingData = {
  [AttendeeKeys.dbId]: string;
  [AttendeeKeys.firstName]: string;
  [AttendeeKeys.middleName]: string;
  [AttendeeKeys.lastName]: string;
  [AttendeeDrivingDataKeys.attendeeTitle]: string;
  [AttendeeDrivingDataKeys.reason]: string;
  [AttendeeDrivingDataKeys.additionalRequest]: string;
};

export class AttendeeFlightDataSource {
  flights: AttendeeFlightData[];
  flightGroupsMap: Map<string, AttendeeFlightGroup>;
  driving: AttendeeDrivingData[];
  flightsBookedByOwn: AttendeeFlightData[];
}

@Injectable()
export class AttendeeFlightService {
  flyTaskStatus$: Observable<ConferenceRegistrationCommonTask>;
  flightDataSource$: Observable<AttendeeFlightDataSource>;

  conferenceDbId$ = new BehaviorSubject<string>(null);
  registrantDbId$ = new BehaviorSubject<string>(null);
  private _suffixesLookup: ActiveLookup[];

  assignOwnerList$ = this.attendeeDetailsModalService.assignOwnerList$;

  constructor(
    private lookupsService: LookupsService,
    private conferenceGuestsService: ConferenceGuestsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private conferenceRegistrationLogsService: ConferenceRegistrationLogsService,
  ) {
    this.lookupsService.suffixesLookup$.subscribe(suffixes => (this._suffixesLookup = suffixes));

    this.flyTaskStatus$ = this.attendeeDetailsModalService.registrant$.pipe(
      tap((conferenceRegistration: Registrant) => {
        this.conferenceDbId$.next(conferenceRegistration?.[RegistrantModelKeys.conferenceDbId]);
        this.registrantDbId$.next(conferenceRegistration?.[BaseModelKeys.dbId]);
      }),
      map(registrant => {
        return registrant?.[RegistrantModelKeys.task]?.[
          ConferenceRegistrationCategoryName.registrantFlightInformationCategory
        ];
      }),
      shareReplay(1),
    );

    const registrantGuests$ = this.attendeeDetailsModalService.registrant$.pipe(
      distinctUntilChanged((oldData, newData): boolean => {
        return (
          oldData?.[RegistrantModelKeys.conferenceDbId] === newData?.[RegistrantModelKeys.conferenceDbId] &&
          oldData?.[BaseModelKeys.dbId] === newData?.[BaseModelKeys.dbId]
        );
      }),
      mergeMap((conferenceRegistration: Registrant) => {
        return this.conferenceGuestsService.getList(
          conferenceRegistration?.[RegistrantModelKeys.conferenceDbId],
          conferenceRegistration?.[BaseModelKeys.dbId],
        );
      }),
      shareReplay(1),
    );

    const allBookOwnFlightsValues$ = combineLatest({
      registrant: this.attendeeDetailsModalService.registrant$.pipe(
        distinctUntilChanged((oldData, newData): boolean => {
          const oldDataFlightInfo = oldData?.[RegistrantModelKeys.data]?.[RegistrantKeys.flightInformation];
          const newDataFlightInfo = newData?.[RegistrantModelKeys.data]?.[RegistrantKeys.flightInformation];
          return !jsondiffpatch.diff(oldDataFlightInfo, newDataFlightInfo);
        }),
      ),
      registrantGuests: registrantGuests$,
    }).pipe(
      map(({ registrant, registrantGuests }) => {
        const registrantData = registrant?.[RegistrantModelKeys.data];
        const isCarrierPartner = registrantData?.[RegistrantKeys.registrationType] === RegistrationType.carrierPartner;
        const registrantFlightInformation = registrantData?.[RegistrantKeys.flightInformation];
        const isBookOwnFlights = registrantFlightInformation?.[FlightInfoKeys.isBookOwnFlights];
        const allGuestsBookOwnFlightsValues = registrantGuests?.map(
          ({ flightInformation }) => flightInformation?.[FlightInfoKeys.isBookOwnFlights],
        );
        return isCarrierPartner ? [isBookOwnFlights, ...allGuestsBookOwnFlightsValues] : null;
      }),
      shareReplay(1),
    );

    this.flightDataSource$ = combineLatest({
      registrant: this.attendeeDetailsModalService.registrant$.pipe(
        distinctUntilChanged((oldData, newData): boolean => {
          const oldRegistrantData = oldData?.[RegistrantModelKeys.data];
          const newRegistrantData = newData?.[RegistrantModelKeys.data];
          const oldDataFlightInfo = oldRegistrantData?.[RegistrantKeys.flightInformation];
          const newDataFlightInfo = newRegistrantData?.[RegistrantKeys.flightInformation];
          const oldDataHotelReservation = oldRegistrantData?.[RegistrantKeys.hotelReservation];
          const newDataHotelReservation = newRegistrantData?.[RegistrantKeys.hotelReservation];
          return (
            !jsondiffpatch.diff(oldDataFlightInfo, newDataFlightInfo) &&
            !jsondiffpatch.diff(oldDataHotelReservation, newDataHotelReservation)
          );
        }),
      ),
      registrantGuests: registrantGuests$,
      allBookOwnFlightsValues: allBookOwnFlightsValues$,
    }).pipe(
      map(({ registrant, registrantGuests, allBookOwnFlightsValues }) => {
        const driving: AttendeeDrivingData[] = [];
        const flights: AttendeeFlightData[] = [];
        const flightsBookedByOwn: AttendeeFlightData[] = [];
        const flightGroupsMap = new Map<string, AttendeeFlightGroup>();
        const registrantData = registrant?.[RegistrantModelKeys.data];
        const hotelReservation = registrantData?.[RegistrantKeys.hotelReservation];
        const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
        const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
        const registrantFlightInformation = registrantData?.[RegistrantKeys.flightInformation];
        let registrantTravelMode = registrantFlightInformation?.[FlightInfoKeys.travelMode] ?? TravelMode.flying;
        const equalTravelMode = registrantFlightInformation?.[FlightInfoKeys.equalTravelMode];
        const isBookOwnFlights = registrantFlightInformation?.[FlightInfoKeys.isBookOwnFlights];
        const allBookOwnFlightsFalse = !allBookOwnFlightsValues
          ? false
          : allBookOwnFlightsValues.every(item => item === false);
        // if equal travel mode is false for all registrant and guests => show travel mode as flying for all
        if (allBookOwnFlightsFalse && registrantTravelMode !== TravelMode.flying) {
          registrantTravelMode = TravelMode.flying;
        }

        switch (registrantTravelMode) {
          case TravelMode.flying:
            const registrantFlightGroup: AttendeeFlightGroup = this.getAttendeeFlightGroupData(
              {
                [AttendeeKeys.attendeeType]: AttendeeType.Invitee,
                [AttendeeKeys.dbId]: registrant[BaseModelKeys.dbId],
                [AttendeeKeys.dob]: registrantData?.[RegistrantKeys.dob],
                [AttendeeKeys.gender]: registrantData?.[RegistrantKeys.gender],
                [AttendeeKeys.conferenceDbId]: registrant[RegistrantModelKeys.conferenceDbId],
                [AttendeeKeys.registrantDbId]: registrant[BaseModelKeys.dbId],
              },
              'Qualifier',
              this.getAttendeeFullName(
                registrantData?.[RegistrantKeys.firstName],
                registrantData?.[RegistrantKeys.middleName],
                registrantData?.[RegistrantKeys.lastName],
                registrantData?.[RegistrantKeys.suffix],
              ),
              Object.assign({}, registrantFlightInformation, {
                [FlightInfoKeys.arrivalDate]: registrantFlightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate,
                [FlightInfoKeys.departureDate]:
                  registrantFlightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate,
              }),
            );

            flightGroupsMap.set(registrant[BaseModelKeys.dbId], registrantFlightGroup);

            const registrantBookingInfo = registrantFlightInformation?.[FlightInfoKeys.bookingInfo];

            registrantBookingInfo?.forEach((booking: FlightBookingModel) => {
              const flightBookingData = {
                [AttendeeKeys.dbId]: registrant[BaseModelKeys.dbId],
                [AttendeeFlightDataKeys.order]: flights?.length,
                [AttendeeFlightDataKeys.flightItinerary]: booking,
              };
              isBookOwnFlights ? flightsBookedByOwn.push(flightBookingData) : flights.push(flightBookingData);
            });

            if (!registrantBookingInfo?.length) {
              const noFlightBookingData = {
                [AttendeeKeys.dbId]: registrant[BaseModelKeys.dbId],
                [AttendeeFlightDataKeys.order]: flights?.length,
                [AttendeeFlightDataKeys.flightItinerary]: null,
              };
              isBookOwnFlights ? flightsBookedByOwn.push(noFlightBookingData) : flights.push(noFlightBookingData);
            }
            break;

          case TravelMode.driving:
            driving.push({
              [AttendeeKeys.dbId]: registrant[BaseModelKeys.dbId],
              [AttendeeKeys.firstName]: registrantData?.[RegistrantKeys.firstName],
              [AttendeeKeys.middleName]: registrantData?.[RegistrantKeys.middleName],
              [AttendeeKeys.lastName]: registrantData?.[RegistrantKeys.lastName],
              [AttendeeDrivingDataKeys.attendeeTitle]: 'Qualifier',
              [AttendeeDrivingDataKeys.reason]: 'Driving',
              [AttendeeDrivingDataKeys.additionalRequest]: registrantFlightInformation?.[FlightInfoKeys.additionalRequest],
            });
            break;
        }

        const { numberOfComplimentaryGuests, numberOfAdditionalGuests } = registrantGuests.reduce(
          (acc, guest) => {
            const isComplimentary = guest?.[GuestKeys.isComplimentary];
            isComplimentary ? (acc.numberOfComplimentaryGuests += 1) : (acc.numberOfAdditionalGuests += 1);
            return acc;
          },
          {
            numberOfComplimentaryGuests: 0,
            numberOfAdditionalGuests: 0,
          },
        );

        const guestIndexes = {
          complimentary: 0,
          additional: 0,
        };

        registrantGuests?.forEach(guest => {
          const guestFlightInformation = guest?.[GuestKeys.flightInformation];
          const isComplimentary = guest?.[GuestKeys.isComplimentary];
          let title = isComplimentary ? 'Complimentary Guest' : 'Additional Guest';
          const isGuestBookOwnFlights = guestFlightInformation?.[FlightInfoKeys.isBookOwnFlights];
          let guestTravelMode = equalTravelMode
            ? registrantTravelMode
            : (guestFlightInformation?.[FlightInfoKeys.travelMode] ?? TravelMode.flying);

          if (allBookOwnFlightsFalse && guestTravelMode !== TravelMode.flying) {
            guestTravelMode = TravelMode.flying;
          }

          switch (guestTravelMode) {
            case TravelMode.flying:
              if (isComplimentary && numberOfComplimentaryGuests > 1) {
                guestIndexes.complimentary += 1;
                title += ` ${guestIndexes.complimentary}`;
              }

              if (!isComplimentary && numberOfAdditionalGuests > 1) {
                guestIndexes.additional += 1;
                title += ` ${guestIndexes.additional}`;
              }

              const guestFlightGroup: AttendeeFlightGroup = this.getAttendeeFlightGroupData(
                {
                  [AttendeeKeys.attendeeType]: AttendeeType.Guest,
                  [AttendeeKeys.dbId]: guest[BaseModelKeys.dbId],
                  [AttendeeKeys.dob]: guest[AttendeeKeys.dob],
                  [AttendeeKeys.gender]: guest[AttendeeKeys.gender],
                  [AttendeeKeys.conferenceDbId]: registrant[RegistrantModelKeys.conferenceDbId],
                  [AttendeeKeys.registrantDbId]: registrant[BaseModelKeys.dbId],
                },
                title,
                this.getAttendeeFullName(
                  guest?.[AssociationKeys.firstName],
                  guest?.[AssociationKeys.middleName],
                  guest?.[AssociationKeys.lastName],
                  guest?.[AssociationKeys.suffix],
                ),
                Object.assign({}, guestFlightInformation, {
                  [FlightInfoKeys.arrivalDate]: guestFlightInformation?.[FlightInfoKeys.arrivalDate] ?? checkInDate,
                  [FlightInfoKeys.departureDate]:
                    guestFlightInformation?.[FlightInfoKeys.departureDate] ?? checkOutDate,
                }),
              );
              flightGroupsMap.set(guest[BaseModelKeys.dbId], guestFlightGroup);

              const guestBookingInfo = guestFlightInformation?.[FlightInfoKeys.bookingInfo];

              guestBookingInfo?.forEach(booking => {
                const flightsData = {
                  [AttendeeKeys.dbId]: guest[BaseModelKeys.dbId],
                  [AttendeeFlightDataKeys.order]: flights?.length,
                  [AttendeeFlightDataKeys.flightItinerary]: booking,
                };
                isGuestBookOwnFlights ? flightsBookedByOwn.push(flightsData) : flights.push(flightsData);
              });

              if (!guestBookingInfo?.length) {
                const noBookingInfoFlightsData = {
                  [AttendeeKeys.dbId]: guest[BaseModelKeys.dbId],
                  [AttendeeFlightDataKeys.order]: flights?.length,
                  [AttendeeFlightDataKeys.flightItinerary]: null,
                };
                isGuestBookOwnFlights
                  ? flightsBookedByOwn.push(noBookingInfoFlightsData)
                  : flights.push(noBookingInfoFlightsData);
              }
              break;

            case TravelMode.driving:
              driving.push({
                [AttendeeKeys.dbId]: guest[BaseModelKeys.dbId],
                [AttendeeKeys.firstName]: guest[AssociationKeys.firstName] ?? '',
                [AttendeeKeys.middleName]: guest[AssociationKeys.middleName] ?? '',
                [AttendeeKeys.lastName]: guest[AssociationKeys.lastName] ?? '',
                [AttendeeDrivingDataKeys.attendeeTitle]: title,
                [AttendeeDrivingDataKeys.reason]: 'Driving',
                [AttendeeDrivingDataKeys.additionalRequest]: guestFlightInformation?.[FlightInfoKeys.additionalRequest],
              });
              break;
          }
        });
        return { flights, flightGroupsMap, driving, flightsBookedByOwn };
      }),
      shareReplay(1),
    );
  }

  saveFlightUpdates = (data: AttendeeFlightGroup, updates: Partial<FlightInformationModel>) => {
    const dbId = data?.[AttendeeKeys.dbId];
    const attendeeType = data?.[AttendeeKeys.attendeeType];
    const conferenceDbId = data?.[AttendeeKeys.conferenceDbId];
    const registrantDbId = data?.[AttendeeKeys.registrantDbId];

    if (attendeeType === AttendeeType.Guest) {
      return this.updateGuestFlightInfo(conferenceDbId, registrantDbId, dbId, updates);
    }

    return this.updateInviteeFlightInfo(conferenceDbId, dbId, updates);
  };

  updateInviteeFlightInfo = async (
    conferenceDbId: string,
    registrantDbId: string,
    flightInformation: Partial<FlightInformationModel>,
  ): Promise<any> => {
    const registrantUpdate = this.conferenceRegistrantsService.update(
      conferenceDbId,
      registrantDbId,
      {
        [RegistrantModelKeys.data]: {
          [RegistrantKeys.flightInformation]: flightInformation,
        },
      },
      true,
    );

    const sameForAll = flightInformation?.[FlightInfoKeys.sameForAll];

    if (!sameForAll) {
      return registrantUpdate;
    }

    const guestFlightUpdates = pick(flightInformation, [
      FlightInfoKeys.preferredAirline,
      FlightInfoKeys.otherPreferredAirline,
      FlightInfoKeys.secondChoiceAirline,
      FlightInfoKeys.otherSecondChoiceAirline,
      FlightInfoKeys.preferredDepartureAirport,
    ]);

    if (isEmpty(guestFlightUpdates)) {
      return registrantUpdate;
    }

    const promises = [registrantUpdate];
    const registrantGuests = await firstValueFrom(this.conferenceGuestsService.getList(conferenceDbId, registrantDbId));
    registrantGuests?.forEach(guest => {
      const guestUpdate = this.updateGuestFlightInfo(
        conferenceDbId,
        registrantDbId,
        guest?.[BaseModelKeys.dbId],
        guestFlightUpdates,
      );
      promises.push(guestUpdate);
    });

    return Promise.all(promises);
  };

  updateGuestFlightInfo = (
    conferenceDbId: string,
    registrantDbId: string,
    guestDbId: string,
    flightInformation: Partial<FlightInformationModel>,
  ): Promise<any> => {
    return this.conferenceGuestsService.update(
      conferenceDbId,
      registrantDbId,
      guestDbId,
      { [GuestKeys.flightInformation]: flightInformation },
      true,
    );
  };

  updateFlyTask = async (task: Partial<ConferenceRegistrationCommonTask>) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const registrantId = registrant[BaseModelKeys.dbId];
    const conferenceDbId = registrant[RegistrantModelKeys.conferenceDbId];

    const update = {
      [RegistrantModelKeys.task]: {
        [ConferenceRegistrationCategoryName.registrantFlightInformationCategory]: task,
      },
    };

    await this.conferenceRegistrantsService.update(conferenceDbId, registrantId, update);
  };

  protected getAttendeeFlightGroupData = (
    defaultAttendeeFlightGroup: MainAttendeeFlightGroupData,
    attendeeTitle: string,
    attendeeFullName: string,
    flightInformation: FlightInformationModel,
  ) => {
    const status = flightInformation?.[FlightInfoKeys.status];
    const dateSent = flightInformation?.[FlightInfoKeys.dateSent];
    const {
      [FlightInfoKeys.firstName]: firstName,
      [FlightInfoKeys.middleName]: middleName,
      [FlightInfoKeys.lastName]: lastName,
      [FlightInfoKeys.suffix]: suffixId,
      [FlightInfoKeys.driverLicenseFirstName]: driverFirstName,
      [FlightInfoKeys.driverLicenseMiddleName]: driverMiddleName,
      [FlightInfoKeys.driverLicenseLastName]: driverLastName,
      [FlightInfoKeys.driverLicenseSuffix]: driverSuffixId,
    } = flightInformation ?? {};
    const passportFullName = this.getAttendeeFullName(firstName, middleName, lastName, suffixId);
    const driverLicenseFullName = this.getAttendeeFullName(
      driverFirstName,
      driverMiddleName,
      driverLastName,
      driverSuffixId,
    );
    const titleFullName = passportFullName || driverLicenseFullName || attendeeFullName;
    const flightInfoUniqueId = `${defaultAttendeeFlightGroup[AttendeeKeys.dbId]}__${defaultAttendeeFlightGroup[AttendeeKeys.attendeeType]}`;
    const groupTitle = [titleFullName, attendeeTitle, FlightBookingStatusMap.get(status), flightInfoUniqueId]
      .filter(Boolean)
      .join(GROUP_TITLE_DIVIDER);

    const attendeeFlightGroup: AttendeeFlightGroup = Object.assign({}, defaultAttendeeFlightGroup, {
      [AttendeeFlightDataKeys.groupTitle]: groupTitle,
      [AttendeeFlightDataKeys.flightInformation]: flightInformation,
      [FlightInfoKeys.status]: status,
    });

    return attendeeFlightGroup;
  };

  saveLog = async (log: ConferenceRegistrationLog) => {
    return this.conferenceRegistrationLogsService.create(this.conferenceDbId$.value, this.registrantDbId$.value, log);
  };

  getExcludedFields = (attendeeType: AttendeeType) => {
    const stepNames =
      attendeeType === AttendeeType.Guest
        ? [
            ConferenceRegistrationStepName.registrationGuestFlightInformationStep,
            ConferenceRegistrationStepName.registrantGuestConfigurationStep,
          ]
        : [
            ConferenceRegistrationStepName.registrantFlightInformationStep,
            ConferenceRegistrationStepName.registrantPersonalInformationStep,
          ];
    return this.attendeeDetailsModalService.getExcludedFields(stepNames);
  };

  private getAttendeeFullName = (firstName, middleName, lastName, suffixId) => {
    const suffix = suffixId
      ? this._suffixesLookup.find(lookup => lookup?.dbId === suffixId)?.[LookupKeys.description]
      : null;
    return [firstName, middleName, lastName, suffix].filter(Boolean).join(' ');
  };
}
