import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavInnerToolbarComponent } from './side-nav-inner-toolbar/side-nav-inner-toolbar.component';
import { SideNavOuterToolbarComponent } from './side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { SingleCardComponent } from './single-card/single-card.component';
import { HeaderComponent } from './components/header/header.component';
import { SideNavigationMenuComponent } from './components/side-navigation-menu/side-navigation-menu.component';
import { UserPanelComponent } from './components/user-panel/user-panel.component';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxDrawerModule,
  DxFilterBuilderModule,
  DxFormModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxMenuModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule,
} from 'devextreme-angular';
import { NotAuthorizedContainerComponent } from './containers/not-authorized-container';
import { UnauthenticatedContentComponent } from './containers/unauthenticated-content';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ButtonWithIndicatorModule } from 'ag-common-svc/public-api';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { IdleModule } from 'ag-common-svc/lib/components/idle/idle.module';

@NgModule({
  declarations: [
    SideNavInnerToolbarComponent,
    SideNavOuterToolbarComponent,
    SingleCardComponent,
    HeaderComponent,
    FooterComponent,
    SideNavigationMenuComponent,
    UserPanelComponent,
    NotAuthorizedContainerComponent,
    UnauthenticatedContentComponent,
    LoginFormComponent,
    AccessDeniedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DxDrawerModule,
    DxToolbarModule,
    DxScrollViewModule,
    DxButtonModule,
    DxContextMenuModule,
    DxListModule,
    DxTreeViewModule,
    DxFilterBuilderModule,
    DxPopupModule,
    DxTextBoxModule,
    DxDataGridModule,
    DxLoadIndicatorModule,
    DxFormModule,
    ButtonWithIndicatorModule,
    IdleModule,
    DxMenuModule,
  ],
  exports: [
    SingleCardComponent,
    NotAuthorizedContainerComponent,
    UnauthenticatedContentComponent,
    SideNavInnerToolbarComponent,
    SideNavOuterToolbarComponent,
    HeaderComponent,
    FooterComponent,
    LoginFormComponent,
    AccessDeniedComponent,
  ],
})
export class LayoutsModule {}
