import { format } from 'date-fns';
import {
  DATE_NOW,
  MIN_DATE,
  MMDDYYYY_DATE_FORMAT,
  MMDDYYYY_DATE_TIME_FORMAT,
  MILITARY_TIME_FORMAT,
  TIME_FORMAT,
  SHORT_TIME_AM_PM_FORMAT,
} from './date.constants';

export const Constants = {
  DISPLAY_DATE_FORMAT: MMDDYYYY_DATE_FORMAT,
  DISPLAY_SHORT_TIME_FORMAT: MILITARY_TIME_FORMAT,
  DISPLAY_TIME_FORMAT: TIME_FORMAT,
  DISPLAY_TIME_WITH_AM_PM_FORMAT: SHORT_TIME_AM_PM_FORMAT,
  DISPLAY_DATE_TIME_FORMAT: MMDDYYYY_DATE_TIME_FORMAT,
  TOTAL_PHONE_DIGITS: 10,
  PHONE_MASK: '(000) 000-0000',
  ONE_TIME_CODE_DIGITS: 6,
  EVENT_NAME_TITLE: ': "{{EVENT_NAME}}"',
  NAME_TITLE_MASK: ': "{{NAME}}"',
} as const;

export const Messages: Record<string, string> = {
  REQUIRED_TEXT: 'Required field',
  REQUIRED_ONE_FIELD_TEXT: `At least one of fields are required`,
  // Date, time
  INVALID_DATE_FORMAT_TEXT: `Please enter a valid date in the format ${Constants.DISPLAY_DATE_FORMAT}`,
  DATE_GREATER_THAN_TEXT: `Date must be greater than ${format(MIN_DATE, Constants.DISPLAY_DATE_FORMAT)}`,
  DATE_GREATER_THAN_AND_SMALLER_TODAY_TEXT: `Please enter a date between
  ${format(MIN_DATE, Constants.DISPLAY_DATE_FORMAT)} and today`,
  INVALID_DAY_FORMAT_TEXT: `Please enter a valid day of the month`,
  INVALID_YEAR_FORMAT_TEXT: `Please enter a valid year`,
  INVALID_FUTURE_DATE_FORMAT_TEXT: `Future dates are not allowed. Please enter a date before today`,
  // URL
  INVALID_URL_FORMAT_TEXT: 'Enter a Valid Website URL',
  REQUIRED_URL_TEXT: 'URL Address is Required',
  INVALID_SUBDOMAIN: 'Invalid subdomain format. Use lowercase letters, numbers, and hyphens only.',
  // Email
  REQUIRED_EMAIL_TEXT: 'Email Address is Required',
  INVALID_EMAIL_FORMAT_TEXT: 'Email Address is incorrectly formatted',
  ALREADY_REGISTERED_EMAIL_TEXT: 'This email is already registered',
  ALREADY_USED_EMAIL_TEXT: 'Email Address is already used',
  NOT_REGISTERED_EMAIL_TEXT: 'Email Address is not registered',
  // Phone Number
  REQUIRED_PHONE_TEXT: 'Phone Number is Required',
  INVALID_PHONE_DIGITS_TEXT: `Please enter ${Constants.TOTAL_PHONE_DIGITS} digits for Phone Number`,
  EXISTS_PHONE_TEXT: `This Phone Number already exists in this profile`,
  // ZIP
  INVALID_ZIP_FORMAT_TEXT: 'Zip is incorrect',
  // Password
  REQUIRED_PASSWORD_TEXT: 'Password is required',
  IDENTICAL_PASSWORDS_TEXT: 'The passwords specified must be identical',
  // Images
  BROKEN_IMAGE_TEXT: 'Broken Image File Assigned',
  // One Time Code
  REQUIRED_ONE_TIME_CODE_TEXT: 'One Time Code is required',
  INVALID_ONE_TIME_CODE_DIGITS_TEXT: `One Time Code must equal ${Constants.ONE_TIME_CODE_DIGITS} digits`,
  INVALID_ONE_TIME_CODE_TEXT: `One Time Code is Invalid`,
  // coupon admin
  INVALID_COUPON_TEXT: `Coupon must contain either a 'Discount Off' or an 'Amount Off'!`,
  // Other
  ALREADY_REGISTERED_CAMPAIGN_WEBSITE_TEXT: 'This Website Url is already in use',
  INVALID_ADDITIONAL_FIELD_TEXT: 'Some additional fields of Physical Location are invalid or missed',
  INVALID_EXISTING_PERMISSION_TEXT:
    'A Permission for this application has already been created. Please edit the existing Permission',
  REQUIRED_ADDRESS_ONE_TEXT: 'Address 1 is Required',
} as const;

export const Patterns: Record<string, string | RegExp> = {
  EMAIL_ADDRESS:
    '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/',
  DATE_PATTERN_MMDDYYYY: '^(0?[1-9]|1[012])[\\/\\-](0?[1-9]|[12][0-9]|3[01])[\\/\\-]\\d{4}$',
  URL_PATTERN_WITH_OR_WITHOUT_PROTOCOL: '^(https?:\\/\\/)?(www\\.)?([\\da-z.-]+)\\.[a-z.]{2,6}(\\/[\\w .-]*)*\\/?$',
  US_ZIP: '(^\\d{5}$)|(^\\d{5}-\\d{4}$)',
  PHONE_NUMBER: '^\\d{10}$',
  SUBDOMAIN: /^[a-z0-9]+(-[a-z0-9]+)*$/i,
  SAFE_FIREBASE_FIELD_NAME_REG_EXP: /^[a-zA-Z0-9]([-_a-zA-Z0-9]*[a-zA-Z0-9])?$/,
  SAFE_FIREBASE_FIELD_NAME_WITH_SPACE_REG_EXP: /^[ a-zA-Z0-9]([ -_a-zA-Z0-9]*[ a-zA-Z0-9])?$/,
} as const;
// any because devextreme has any type for the editorOptions
export const EditorOptions: Record<string, any> = {
  DATE: {
    type: 'date',
    useMaskBehavior: true,
    showClearButton: true,
    displayFormat: Constants.DISPLAY_DATE_FORMAT,
    invalidDateMessage: Messages.INVALID_DATE_FORMAT_TEXT,
  },
  TIME: {
    type: 'time',
    useMaskBehavior: true,
    displayFormat: Constants.DISPLAY_TIME_FORMAT,
  },

  MIN_DATE: {
    dateOutOfRangeMessage: Messages.DATE_GREATER_THAN_TEXT,
    min: MIN_DATE,
  },
  RANGE_DATE: {
    dateOutOfRangeMessage: Messages.DATE_GREATER_THAN_AND_SMALLER_TODAY_TEXT,
    min: MIN_DATE,
    max: DATE_NOW,
  },
  DATE_TIME: {
    displayFormat: Constants.DISPLAY_DATE_TIME_FORMAT,
    useMaskBehavior: true,
    type: 'datetime',
  },
};
