<dx-toolbar [elementAttr]="{ class: 'conference-registration-grid__toolbar' }">
  <dxi-item location="before" width="100px">
    <div *dxTemplate>
      <h5 class="ag-grid__toolbar-title">Event Registrations</h5>
    </div>
  </dxi-item>

  <dxi-item location="after" cssClass="ag-grid__toolbar-control">
    <ng-container>
      <div *dxTemplate>
        <dx-select-box
          #conferenceSelectBox
          [width]="450"
          labelMode="floating"
          label="Event"
          [value]="conferenceDbId$ | async"
          (valueChange)="setConferenceDbId($event)"
          [valueExpr]="'conferenceDbId'"
          [displayExpr]="'description'"
          [dataSource]="conferences$ | async"
          [dropDownOptions]="{ minWidth: '450px' }"
          [readOnly]="!showConferenceSelectBox"
        ></dx-select-box>
      </div>
    </ng-container>
  </dxi-item>

  <dxi-item
    location="after"
    cssClass="ag-grid__toolbar-control"
    widget="dxButton"
    [options]="{ icon: 'refresh', text: lastUpdateDate$ | async, onClick: forceRefresh }"
  >
  </dxi-item>
</dx-toolbar>

<div class="conference-registration-grid__accordion-container">
  <dx-accordion
    #accordionRef
    [collapsible]="false"
    [multiple]="false"
    [animationDuration]="300"
    [elementAttr]="{ class: 'conference-registration-grid__accordion' }"
    [deferRendering]="false"
    height="100%"
  >
    <dxi-item icon="agi-participants" title="Invited Participants">
      <div *dxTemplate>
        <app-invited-participants-grid *ngIf="accordionRef.visible"></app-invited-participants-grid>
      </div>
    </dxi-item>

    <dxi-item icon="agi-participant-minus" title="Denied Participants">
      <div *dxTemplate>
        <app-rejected-participants-grid *ngIf="accordionRef.visible"></app-rejected-participants-grid>
      </div>
    </dxi-item>
  </dx-accordion>
</div>

<ag-crm-attendee-details-modal #attendeeDetailsModalRef (onHidden)="forceRefresh()"></ag-crm-attendee-details-modal>

<ng-container *ngIf="viewAgentVisible">
  <dx-popup
    title="Agent Details"
    [showTitle]="true"
    [fullScreen]="true"
    [showCloseButton]="true"
    [dragEnabled]="false"
    [wrapperAttr]="{ class: 'ag-overlay-popup' }"
    [(visible)]="viewAgentVisible"
  >
    <app-agent-editor [selectedAgent]="selectedAgent" (fullScreenClosed)="viewAgentVisible = false"></app-agent-editor>
  </dx-popup>
</ng-container>
