export enum PROSPECT_STATUS {
  NEW_PROSPECT = 'New Prospect',
  INITIAL_INQUIRY = 'Initial Inquiry',
  INVESTIGATION = 'Investigation',
  ROUTING = 'Routing',
  FOLLOW_UP = 'Follow Up',
  COMPLETE = 'Complete',
}

export const PROSPECT_STATUS_LOOKUP = [
  { value: PROSPECT_STATUS.NEW_PROSPECT, description: 'New Prospect' },
  { value: PROSPECT_STATUS.INITIAL_INQUIRY, description: 'Initial Inquiry' },
  { value: PROSPECT_STATUS.INVESTIGATION, description: 'Investigation' },
  { value: PROSPECT_STATUS.ROUTING, description: 'Routing' },
  { value: PROSPECT_STATUS.FOLLOW_UP, description: 'Follow Up' },
  { value: PROSPECT_STATUS.COMPLETE, description: 'Complete' },
];
