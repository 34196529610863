import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { distinctUntilKeyChanged, filter, Observable } from 'rxjs';

import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { AgMediaUploaderModalService, FileData } from '../ag-media-uploader-modal.service';
import { MediaUploaderTabs } from '../ag-media-uploader-modal.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ag-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
  providers: [AgMediaUploaderModalService],
})
export class AGFileUploaderComponent {
  @Input() accept: string[] = ['*/*'];
  @Input() validEmailExtensions = ['.eml', '.msg'];
  @Input() placeholder: string = 'Click or Drag Existing File';
  @Input() allowClearing: boolean = false;
  @Input() set mediaSources(data: MediaUploaderTabs[]) {
    this.getMediaSources(data ?? this.defaultTabs);
  }

  @Output() fileParsed = new EventEmitter<FileData>();
  @Output() onClearFile = new EventEmitter<void>();

  @HostBinding('class') className = 'file-uploader';

  @ViewChild('fileNameValidatorRef', { static: false }) fileNameValidatorComponent;

  fileData$: Observable<FileData>;
  protected selectedTabIndex$ = this.agMediaUploaderModalService.selectedTabIndex$;
  protected selectedTab$ = this.agMediaUploaderModalService.selectedTab$;
  protected tabConfig;
  protected MediaUploaderTabs = MediaUploaderTabs;

  private defaultTabs = [MediaUploaderTabs.FromFile, MediaUploaderTabs.ByURL, MediaUploaderTabs.Gallery];

  clearButton: DxButtonTypes.Properties = {
    icon: 'remove',
    stylingMode: 'text',
    disabled: false,
    onClick: () => {
      this.clearFile();
    },
  };

  constructor(private agMediaUploaderModalService: AgMediaUploaderModalService) {
    this.fileData$ = this.agMediaUploaderModalService.file$;
    agMediaUploaderModalService.file$
      .pipe(filter(Boolean), distinctUntilKeyChanged('base64'), untilDestroyed(this))
      .subscribe(fileData => {
        this.fileParsed.emit(fileData);
      });
  }

  clearFile() {
    this.agMediaUploaderModalService.setFile();
    this.onClearFile.emit();
  }

  protected onSelectedIndexChange = this.agMediaUploaderModalService.onSelectedIndexChange;

  private getMediaSources(data: MediaUploaderTabs[]) {
    this.tabConfig = data.map(mediaSource => ({ id: mediaSource, text: mediaSource }));
    this.agMediaUploaderModalService.setSelectedTab({ itemIndex: 0, itemData: this.tabConfig[0] });
  }
}
