import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ARMAuthService } from '../shared/services/util/arm-auth.service';
import {
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  RoleEntityPermissionMap,
} from 'ag-common-lib/public-api';

@Injectable({ providedIn: 'root' })
export class AgNotificationsHubGuard implements CanActivate {
  constructor(private authService: ARMAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.armPermissions$.pipe(
      map((roleEntityPermissionMap: RoleEntityPermissionMap) => {
        return this.checkIsVisible(
          [Entity.notificationsChannels, Entity.notificationsScheduler, Entity.notificationsTemplates, Entity.dynamicSegmentsGroups],
          roleEntityPermissionMap,
        );
      }),

      tap(hasPermission => {
        if (!hasPermission) {
          this.router.navigate(['/access-denied']);
        }
      }),
    );
  }

  checkIsVisible = (entities: Entity[], permissions: RoleEntityPermissionMap) => {
    if (!entities || !entities?.length) {
      return false;
    }

    return entities.some(entity => {
      return [
        EntityPermissionActivityKeys.read,
        EntityPermissionActivityKeys.create,
        EntityPermissionActivityKeys.delete,
        EntityPermissionActivityKeys.write,
      ].some(activity => permissions?.[entity]?.[activity]);
    });
  };
}
