import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  Agency,
  AGENCY_TYPE,
  AgencyKeys,
  AgencyMedia,
  Agent,
  AgentKeys,
  AGMedia,
  AGMediaKeys,
  BaseModelKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  IAspectRatio,
  LookupKeys,
  Lookups,
  MediaSize,
} from 'ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { BehaviorSubject, firstValueFrom, lastValueFrom, map, Observable, shareReplay, take } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AGENCY_STATUS, AGENCY_TYPES_LOOKUP } from '@ag-common-lib/lib';
import { AgMediaUploaderModalComponent } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.component';
import { DropZoneComponent } from 'ag-common-svc/lib/components/drop-zone/drop-zone.component';
import { ImageCropperModalComponent } from 'ag-common-svc/lib/components/image-cropper-modal/image-cropper-modal.component';
import { AgencyMediaService } from 'ag-common-svc/lib/services/agency-media.service';
import { AuthService, LookupsService } from 'ag-common-svc/public-api';
import { FullAddressPipe } from 'ag-common-svc/shared/pipes/full-address.pipe';
import { PhoneNumberMaskPipe } from 'ag-common-svc/shared/pipes/phone-number-mask.pipe';
import { WasabiImgPipe } from 'ag-common-svc/shared/pipes/wasabi-img.pipe';
import { AgencyHeaderKeys } from './agency-header.model';
import { AgencyHeaderService } from './agency-header.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agency-header',
  templateUrl: './agency-header.component.html',
  styleUrls: ['./agency-header.component.scss'],
  providers: [PhoneNumberMaskPipe, FullAddressPipe, WasabiImgPipe],
})
export class AgencyHeaderComponent implements AfterViewInit {
  @Input()
  set agencyId(id: string) {
    this._agencyId = id;
    this.mediaPathPrefix = `agencies/${id}`;
    this.agency$ = this.agencyHeaderService
      .getAgencyById(id)
      .pipe(map((agency: Agency) => (this.agencyHeaderFormDetails = this.agencyHeaderService.getFormData(agency))));
    this.selectedAgencyLeads$ = this.agencyHeaderService.getSelectedAgencyLeads(id);
    this.agencyHeaderService.successfulCreatedForm();
    this.agencyMediaDataSource$ = this.agencyMediaService.getList(id).pipe(shareReplay(1));
  }
  @Output() fieldsUpdated = new EventEmitter<{ agencyId: string; updates: Partial<Agency> }>();
  @ViewChild('agencyHeaderModalRef', { static: true }) agencyHeaderModalComponent: ModalWindowComponent;
  @ViewChild('agencyHeaderFormRef', { static: false }) agencyHeaderFormComponent: DxFormComponent;
  @ViewChild('agenyCompanyLogoModalRef', { static: true }) agencyLogoModalComponent: ModalWindowComponent;
  @ViewChild('agencyLogoDropZoneRef', { static: true }) agencyLogoDropZoneComponent: DropZoneComponent;
  @ViewChild(ImageCropperModalComponent, { static: false }) imageCropperModalComponent: ImageCropperModalComponent; // ViewChild for DxTooltipComponent
  @ViewChild(AgMediaUploaderModalComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent; // ViewChild for DxTooltipComponent

  private _agencyId: string;

  agency$: Observable<Agency>;
  headerLink$ = new BehaviorSubject<string>('');
  inProgress$ = this.agencyHeaderService.inProgress$;
  mgas$ = this.agencyHeaderService.mgas$;
  rmds$ = this.agencyHeaderService.rmds$;
  selectedAgencyLeads$: Observable<Agent[]>;
  agencyStatusesLookup$ = this.lookupsService.agencyStatusesLookup$;
  agencyMediaDataSource$: Observable<AgencyMedia[]>;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  agencyHeaderFormDetails;
  aspectRatio: IAspectRatio = { w: 4, h: 3 };
  mediaPathPrefix: string;

  protected readonly AgencyKeys = AgencyKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly Lookups = Lookups;
  protected readonly agencyStatus = AGENCY_STATUS;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly agencyType = AGENCY_TYPE;
  protected readonly AGENCY_TYPES_LOOKUP = AGENCY_TYPES_LOOKUP;
  protected readonly AgencyHeaderKeys = AgencyHeaderKeys;
  protected readonly MediaSize = MediaSize;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;

  constructor(
    private agencyHeaderService: AgencyHeaderService,
    private phoneNumberMaskPipe: PhoneNumberMaskPipe,
    private fullAddressPipe: FullAddressPipe,
    private authService: AuthService,
    private lookupsService: LookupsService,
    private wasabiImgPipe: WasabiImgPipe,
    private agencyMediaService: AgencyMediaService,
  ) {}

  ngAfterViewInit(): void {
    this.headerLink$.next(this.agencyHeaderFormDetails?.companyLogoLink ?? '');
  }

  saveAgencyLogoImagesUpdates = async (): Promise<void> => {
    const isImageValid = await lastValueFrom(this.agencyLogoDropZoneComponent?.isImageValid$.pipe(take(1)));
    if (isImageValid) {
      this.agencyHeaderService.handleSave(this._agencyId).then(data => {
        this.fieldsUpdated.emit(data);
        this.agencyLogoModalComponent.hideModal();
      });
    }
  };

  saveAgencyHeaderInfoUpdates = (): void => {
    const validationResults = this.agencyHeaderFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.agencyHeaderService.handleSave(this._agencyId).then(data => {
        this.fieldsUpdated.emit(data);
        this.agencyHeaderModalComponent.hideModal();
      });
    }
  };

  handleClosePopup = () => this.agencyHeaderService.onCancelEdit.call(this, this.onAfterRevertChanges);

  showEditorModal = (): void => {
    this.agencyHeaderModalComponent.showModal();
  };

  onPictureUrlChange(link: string): void {
    setTimeout(() => {
      this.headerLink$.next(link);
      this.agencyHeaderFormDetails.companyLogoLink = link;
      this.agencyHeaderService.updateFieldsData(this.agencyHeaderFormDetails.companyLogoLink);
    }, 100);
  }

  phoneNumberDisplayExpr = item => this.phoneNumberMaskPipe.transform(item, true);

  fullAddressDisplayExpr = item => this.fullAddressPipe.transform(item);

  onAfterRevertChanges = (): void => {
    this.agencyHeaderFormComponent.instance.repaint();
  };

  onSelectionChanged = ({ selectedItem }): void => {
    this.agencyHeaderService.setSelectedAgencyStatus(selectedItem);
  };

  onUploadClicked = (): void => {
    this.mediaUploaderComponent.showModal();
  };

  handleCropImage = async (): Promise<void> => {
    const headshot = await (await firstValueFrom(this.agency$)).head_shot;
    this.imageCropperModalComponent.showModal(
      this.wasabiImgPipe.transform(headshot?.wasabiPath),
      headshot?.imageCropperPayload,
    );
  };

  onImageCropped = payload => this.agencyHeaderService.onImageCropped(this._agencyId, payload);

  onMediaChanged = async (mediaUrl: AGMedia): Promise<void> => {
    const response = await this.agencyHeaderService.onProfileImageChanged(this._agencyId, mediaUrl);

    this.imageCropperModalComponent.showModal(this.wasabiImgPipe.transform(mediaUrl[AGMediaKeys.wasabiPath]));
  };
}
