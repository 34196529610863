<div class="file-uploader__container" *var="(selectedTab$ | async)?.itemData as selectedTabData">
  <!-- Tabs for selecting upload method (From File, By URL) -->
  <ag-shr-tabs
    *ngIf="tabConfig?.length > 1"
    class="file-uploader__header"
    [dataSource]="tabConfig"
    [selectedIndex]="selectedTabIndex$ | async"
    (onItemClick)="onSelectedIndexChange($event)"
  >
  </ag-shr-tabs>

  <!-- Upload from Disk Section -->
  <ag-shr-upload-from-disk-tab
    class="file-uploader__content"
    [size]="'small'"
    [accept]="accept"
    [placeholder]="placeholder"
    *ngIf="selectedTabData?.id === MediaUploaderTabs.FromFile"
  >
    <ng-container *ngTemplateOutlet="fileNameInputTmp; context: { fileData: fileData$ | async }"></ng-container>
  </ag-shr-upload-from-disk-tab>

  <!-- Upload from URL Section -->
  <ag-shr-upload-from-url-tab class="file-uploader__content" *ngIf="selectedTabData?.id === MediaUploaderTabs.ByURL">
    <ng-container *ngTemplateOutlet="fileNameInputTmp; context: { fileData: fileData$ | async }"></ng-container>
  </ag-shr-upload-from-url-tab>
</div>

<ng-template #fileNameInputTmp let-fileData="fileData">
  <dx-text-box
    [value]="fileData && fileData.fileName"
    [label]="'File name'"
    [labelMode]="'floating'"
    [showClearButton]="false"
    class="upload-from-disk-tab__text-input"
    [disabled]="!fileData?.content && !allowClearing"
  >
    <dxi-button
      *ngIf="allowClearing"
      name="today"
      location="after"
      [disabled]="false"
      [options]="clearButton"
    ></dxi-button>
    <dx-validator #fileNameValidatorRef>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dx-validator>
  </dx-text-box>
</ng-template>
