<ng-content></ng-content>

<div
  class="image-preview__container"
  [class.image-preview__container--invalid]="isValid === false"
>
  <img
    *ngIf="!!src; else imagePlaceholder"
    class="image-preview__image"
    [ngClass]="sizeClass"
    [src]="src"
    (load)="onImgLoaded()"
    (error)="onImgError()"
  >

  <ng-template #imagePlaceholder>
    <p class="image-preview__placeholder">{{placeholder}}</p>
  </ng-template>
</div>
