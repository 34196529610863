import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { AgentKeys, BaseModelKeys, LookupKeys } from '@ag-common-lib/public-api';
import { PushNotificationCampaign, PushNotificationCampaignKeys } from '@ag-common-lib/lib';
import { firstValueFrom } from 'rxjs';
import { DynamicListsGroupService } from 'ag-common-svc/public-api';
import { NotificationsSchedulerCheckedRecipientsGridService } from './notifications-scheduler-checked-recipients-grid.service';

@Component({
  selector: 'ag-shr-checked-recipients-grid',
  templateUrl: './notifications-scheduler-checked-recipients-grid.component.html',
  styleUrls: ['./notifications-scheduler-checked-recipients-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DynamicListsGroupService, NotificationsSchedulerCheckedRecipientsGridService],
})
export class NotificationsSchedulerCheckedRecipientsGridComponent {
  @HostBinding('class') className = 'notifications-scheduler-checked-recipients-grid';

  @ViewChild('checkedRecipientsGridRef', { static: false })
  protected checkedRecipientsGrid: DxDataGridComponent;

  @Input()
  set scheduledNotification(data: PushNotificationCampaign) {
    // this.agentDbIds = [];
    const channels = data?.[PushNotificationCampaignKeys.channels];
    const listIds = data?.[PushNotificationCampaignKeys.dynamicListsIds] ?? [];
    const segmentIds = data?.[PushNotificationCampaignKeys.dynamicSegmentsIds] ?? [];
    const recipientIds = data?.[PushNotificationCampaignKeys.recipients] ?? [];
    this.createDataSource(recipientIds, listIds, channels, segmentIds);
  }

  dataSource: DataSource;
  totalCount: Number;
  agentDbIds: string[];

  protected readonly AgentKeys = AgentKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;

  constructor(private checkedRecipientsGridService: NotificationsSchedulerCheckedRecipientsGridService) {}

  protected getTotalCount = e => {
    this.totalCount = e.component.totalCount();
  };

  private async createDataSource(
    recipientIds: string[],
    listDbIds: string[],
    channels: string[],
    segmentIds: string[],
  ): Promise<void> {
    const recipientDbIdsByChannel = await this.checkedRecipientsGridService.getRecipientDbIdsByChannel(channels);
    const recipientDbIdsByList = await firstValueFrom(
      this.checkedRecipientsGridService.getRecipientDbIdsByLists(listDbIds),
    );
    const recipientDbIdsBySegments = await this.checkedRecipientsGridService.getRecipientDbIdsBySegments(segmentIds);
    this.agentDbIds = [
      ...new Set([...recipientDbIdsByList, ...recipientIds, ...recipientDbIdsByChannel, ...recipientDbIdsBySegments]),
    ];
    this.checkedRecipientsGrid.instance.refresh();
  }
}
