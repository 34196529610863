import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ProfilePictureComponent } from './profile-picture.component';
import { AgMediaUploaderModalModule } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.module';
import { ImageCropperModalModule } from 'ag-common-svc/lib/components/image-cropper-modal/image-cropper-modal.module';
import { ImageWithLoaderModule } from 'ag-common-svc/lib/components/image-with-loader/image-with-loader.module';
import { DxDropDownButtonModule, DxPopoverModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DxDropDownButtonModule,
    AgMediaUploaderModalModule,
    ImageCropperModalModule,
    ImageWithLoaderModule,
    DxPopoverModule,
  ],
  declarations: [ProfilePictureComponent],
  exports: [ProfilePictureComponent],
})
export class ProfilePictureModule {}
