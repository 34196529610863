<ng-container
  *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
>
  <dx-data-grid
    class="ag-crm-attendee-flight__grid"
    [dataSource]="flightDataSource"
    [showColumnLines]="true"
    [showRowLines]="true"
    [rowAlternationEnabled]="true"
    [repaintChangesOnly]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    (onRowRemoving)="handleRowRemoving($event, flightGroupsMap)"
    height="100%"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxo-editing
      mode="row"
      [allowUpdating]="false"
      [allowAdding]="false"
      [allowDeleting]="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.delete | async"
      [useIcons]="true"
      [confirmDelete]="true"
    >
    </dxo-editing>

    <dxi-column [dataField]="AttendeeKeys.dbId" [groupIndex]="0" groupCellTemplate="attendeeFlyGroupCellTemplate">
    </dxi-column>

    <!-- To Order By group  -->
    <dxi-column [dataField]="AttendeeFlightDataKeys.order" [visible]="false"></dxi-column>

    <dxi-column
      [dataField]="[AttendeeFlightDataKeys.flightItinerary, FlightBookingKeys.sequenceNumber].join('.')"
      [visible]="false"
      [sortOrder]="'asc'"
      [sortIndex]="0"
    ></dxi-column>

    <dxo-summary>
      <dxi-group-item [column]="AttendeeFlightDataKeys.order" summaryType="max" [showInGroupFooter]="false">
      </dxi-group-item>
    </dxo-summary>

    <dxi-sort-by-group-summary-info summaryItem="max"></dxi-sort-by-group-summary-info>

    <div *dxTemplate="let cell of 'attendeeFlyGroupCellTemplate'" class="ag-crm-attendee-flight__group-container">
      <ng-container *var="flightGroupsMap.get(cell?.data?.key) as groupData">
        <span class="ag-crm-attendee-flight__group-title">
          {{ groupData?.[AttendeeFlightDataKeys.groupTitle] }}
        </span>

        <ng-container *var="groupData?.[AttendeeFlightDataKeys.flightInformation] as flightInformation">
          <i
            #travelDatesPopoverTarget
            *ngIf="
              flightInformation?.[FlightInfoKeys.travelDatesDiffer] &&
              !flightInformation?.[FlightInfoKeys.travelDatesDifferRequestOutcome]
            "
            class="dx-icon dx-icon-warning attendee-details-header__warning-icon"
          ></i>
        </ng-container>

        <div class="ag-crm-attendee-flight__controls-container">
          <dx-button
            [icon]="canWrite ? 'edit' : 'agi-eye'"
            stylingMode="text"
            [hint]="canWrite ? 'Edit Flight Info' : 'View Flight Info'"
            (onClick)="editAttendeeFlightInfo(groupData)"
          ></dx-button>

          <dx-button
            class="ag-crm-attendee-flight__control"
            [icon]="'plus'"
            stylingMode="text"
            [hint]="'Add Itinerary'"
            [visible]="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.create | async"
            (onClick)="editAttendeeItinerary(groupData)"
          ></dx-button>

          <dx-button
            class="ag-crm-attendee-flight__control"
            [visible]="groupData?.[FlightInfoKeys.status] === FlightBookingStatus.pending && canWrite"
            icon="check"
            stylingMode="text"
            (onClick)="confirmCompleting(groupData)"
          ></dx-button>
        </div>
      </ng-container>
    </div>

    <dxi-column
      caption="Conf #"
      [dataField]="[AttendeeFlightDataKeys.flightItinerary, FlightBookingKeys.confirmationNumber].join('.')"
      minWidth="120"
      cssClass="ag-crm-attendee-flight__cell-vertical-aligned"
    ></dxi-column>

    <dxi-column
      caption="Flight #"
      [dataField]="AttendeeFlightDataKeys.flightItinerary"
      cssClass="ag-crm-attendee-flight__cell-vertical-aligned"
      [calculateDisplayValue]="calculateFlightNumberDisplayValue"
    >
    </dxi-column>

    <dxi-column cellTemplate="buttonsCellTemplate" [width]="136"></dxi-column>

    <div *dxTemplate="let cellTemplateData of 'timeCellTemplate'">
      <div>{{ cellTemplateData.value | militaryTimeFormat }}</div>
    </div>

    <div *dxTemplate="let cellTemplateData of 'buttonsCellTemplate'">
      <div
        *ngIf="!!cellTemplateData?.data?.[AttendeeFlightDataKeys.flightItinerary]"
        class="ag-crm-attendee-flight__buttons-container"
      >
        <dx-button
          [icon]="canWrite ? 'edit' : 'agi-eye'"
          stylingMode="text"
          [hint]="canWrite ? 'Edit Itinerary' : 'View Itinerary'"
          (onClick)="handleEditItinerary(flightGroupsMap, cellTemplateData?.data)"
        ></dx-button>

        <ng-container *ngIf="canWrite">
          <ng-container
            *var="flightGroupsMap | attendeeFlightCopyTargets: cellTemplateData?.data as copyTargets"
          >
            <dx-drop-down-button
              *ngIf="copyTargets?.length > 1; else simpleCopy"
              [showArrowIcon]="false"
              icon="copy"
              stylingMode="text"
              [dropDownOptions]="{ width: 230 }"
              [displayExpr]="selectCopyTargetDisplayExpr"
              (onItemClick)="handleCopyEditItinerary(cellTemplateData?.data, $event?.itemData)"
              [items]="copyTargets"
            >
            </dx-drop-down-button>

            <ng-template #simpleCopy>
              <dx-button
                [visible]="copyTargets?.length === 1"
                icon="copy"
                stylingMode="text"
                (onClick)="handleCopyEditItinerary(cellTemplateData?.data, copyTargets[0])"
              ></dx-button>
            </ng-template>
          </ng-container>
        </ng-container>

        <dx-button
          icon="trash"
          stylingMode="text"
          [visible]="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.delete | async"
          (onClick)="deleteRow(cellTemplateData)"
        ></dx-button>
      </div>
    </div>

    <dxi-column type="buttons" [visible]="false"></dxi-column>
  </dx-data-grid>
</ng-container>

<ag-crm-attendee-flight-data-modal #attendeeFlightDataModalRef></ag-crm-attendee-flight-data-modal>
<ag-crm-attendee-itinerary-modal #attendeeItineraryModalRef></ag-crm-attendee-itinerary-modal>
