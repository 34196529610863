export enum AppRoutes {
  LoginForm = 'login-form',
  Agents = 'agents',
  Agencies = 'agencies',
  Prospects = 'prospects',
  Carriers = 'carriers',
  Campaign = 'campaign',
  Registrants = 'registrants',
  Administration = 'administration',
  NotificationsHub = 'notifications-hub',
  Home = 'home',
  Profile = 'profile',
  Logout = 'logout',
  Webinars = 'webinars',
  Contests = 'contests',
  ChristmasCardList = 'christmas-card-list',
  DistributionLists = 'distribution-lists',
  EmailTemplates = 'email-templates',
  ConferenceRegistrations = 'conference-registrations',
  RegistrationReports = 'registration-reports',
  ConferencePostersList = 'conference-posters-list',
  Events = 'events',
}
