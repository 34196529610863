import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { DataService } from './data.service';
import { FIREBASE_APP } from 'ag-common-svc/public-api';
import { DynamicSegmentsGroup, DynamicSegmentsGroupKeys } from '@ag-common-lib/lib';
import { Observable, shareReplay } from 'rxjs';

@Injectable()
export class DynamicSegmentsGroupService extends DataService<DynamicSegmentsGroup> {
  readonly segments$: Observable<DynamicSegmentsGroup[]>;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    this.collection = 'dynamic-segments-group';
    this.segments$ = this.getList([], { sortField: DynamicSegmentsGroupKeys.title }).pipe(shareReplay(1));
  }
}
