export enum PROSPECT_PRIORITY {
  NONE = 'NONE',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const PROSPECT_PRIORITY_LOOKUP = Object.entries(PROSPECT_PRIORITY).map(([key, item]) => ({
  value: key,
  description: item
}));