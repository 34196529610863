export enum PROSPECT_DISPOSITION {
  REFER_TO_MGA = 'Refer To MGA',
  CONTRACTED_MGA_W_AG = 'Contracted MGA w/ AG',
  CONTRACTED_W_LBS = 'Contracted w/ LBS',
  CONTRACTED_W_GOODWILL = 'Contracted w/ Goodwill',
  DECLINED = 'Declined',
}

export const PROSPECT_DISPOSITION_LOOKUP = Object.entries(PROSPECT_DISPOSITION).map(([key, item]: [string, string]) => ({
  value: key,
  description: item
}));
