import { Component, Input, ViewChild } from '@angular/core';
import {
  AttendeeFlightData,
  AttendeeFlightDataKeys,
  AttendeeFlightGroup,
  AttendeeFlightService,
  GROUP_TITLE_DIVIDER,
} from '../attendee-flight.service';
import {
  AttendeeKeys,
  CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP,
  ConferenceRegistrationCommonTask,
  Entity,
  EntityPermissionActivityKeys,
  FlightBookingKeys,
  FlightBookingStatus,
  FlightInfoKeys,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
} from 'ag-common-lib/public-api';
import { ColumnCellTemplateData, RowRemovingEvent } from 'devextreme/ui/data_grid';
import { pick } from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';
import { AttendeeFlightDataModalComponent } from '../attendee-flight-data-modal/attendee-flight-data-modal.component';
import { AttendeeItineraryModalComponent } from '../attendee-itinerary-modal/attendee-itinerary-modal.component';
import { confirm } from 'devextreme/ui/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ag-crm-attendee-flying-grid',
  templateUrl: './attendee-flying-grid.component.html',
  styleUrls: ['./attendee-flying-grid.component.scss'],
  providers: [AttendeeFlightService],
})
export class AttendeeFlyingGridComponent {
  @ViewChild('flyInfoGrid', { static: false }) dataGrid!: DxDataGridComponent;
  @ViewChild('attendeeFlightDataModalRef', { static: true })
  attendeeFlightDataModalComponent!: AttendeeFlightDataModalComponent;
  @ViewChild('attendeeItineraryModalRef', { static: true })
  attendeeItineraryModalComponent!: AttendeeItineraryModalComponent;

  @Input() flightDataSource!: AttendeeFlightData[];
  @Input() flightGroupsMap!: Map<string, AttendeeFlightGroup>;
  @Input() flyTaskStatus!: ConferenceRegistrationCommonTask;

  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly AttendeeFlightDataKeys = AttendeeFlightDataKeys;
  protected readonly FlightBookingKeys = FlightBookingKeys;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly FlightBookingStatus = FlightBookingStatus;
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;
  protected readonly CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP = CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP;

  constructor(private attendeeFlightService: AttendeeFlightService) {}

  calculateFlightNumberDisplayValue = data => {
    const flightItinerary = data?.[AttendeeFlightDataKeys.flightItinerary];
    if (!flightItinerary) {
      return '';
    }
    const airline = flightItinerary?.[FlightBookingKeys.airline] ?? '';
    const flightNumber = flightItinerary?.[FlightBookingKeys.flightNumber] ?? '';
    if (airline && flightNumber) {
      return `${airline} - ${flightNumber}`;
    }

    return `${airline} ${flightNumber}`;
  };

  protected handleEditItinerary = (flightGroupsMap: Map<string, AttendeeFlightGroup>, data) => {
    const flightGroup = flightGroupsMap.get(data?.[AttendeeKeys.dbId]);
    const flightItinerary = data?.[AttendeeFlightDataKeys.flightItinerary];
    this.attendeeItineraryModalComponent.showModal(flightGroup, flightItinerary);
  };

  protected handleRowRemoving = (
    e: RowRemovingEvent<AttendeeFlightData>,
    flightGroupsMap: Map<string, AttendeeFlightGroup>,
  ) => {
    const flightItinerary = e.data?.[AttendeeFlightDataKeys.flightItinerary];
    const flightItineraryId = flightItinerary?.[FlightBookingKeys.id];
    const flightGroup = flightGroupsMap.get(e.data?.[AttendeeKeys.dbId]);
    const bookingInfo = flightGroup?.[AttendeeFlightDataKeys.flightInformation]?.[FlightInfoKeys.bookingInfo];
    const remainItinerary = bookingInfo?.filter(itinerary => itinerary?.[FlightBookingKeys.id] !== flightItineraryId);

    // e.cancel = this.attendeeFlightService.saveFlightUpdates(flightGroup, {
    //   [FlightInfoKeys.bookingInfo]: remainItinerary,
    // });
  };

  protected deleteRow = (cellTemplateData: ColumnCellTemplateData) => {
    cellTemplateData.component.deleteRow(cellTemplateData.rowIndex);
  };

  protected handleCopyEditItinerary = (flightData: AttendeeFlightData, targetFlightGroup: AttendeeFlightGroup) => {
    const sourceDbId = flightData?.dbId;
    const targetDbId = targetFlightGroup?.dbId;
    const samePerson = sourceDbId === targetDbId;
    const sourceFlightItinerary = flightData?.[AttendeeFlightDataKeys.flightItinerary];

    if (!samePerson) {
      this.attendeeItineraryModalComponent.showModal(
        targetFlightGroup,
        pick(sourceFlightItinerary, [
          FlightBookingKeys.airline,
          FlightBookingKeys.flightNumber,
          FlightBookingKeys.departureAirport,
          FlightBookingKeys.flightDepartureDate,
          FlightBookingKeys.flightDepartureTime,
          FlightBookingKeys.arrivalAirport,
          FlightBookingKeys.flightArrivalDate,
          FlightBookingKeys.flightArrivalTime,
        ]),
      );
      return;
    }

    const flightItinerary = pick(sourceFlightItinerary, [
      FlightBookingKeys.confirmationNumber,
      FlightBookingKeys.airline,
      FlightBookingKeys.flightNumber,
      FlightBookingKeys.frequentFlyerNumber,
      FlightBookingKeys.knownTravelerNumber,
    ]);

    Object.assign(flightItinerary, {
      [FlightBookingKeys.departureAirport]: sourceFlightItinerary?.[FlightBookingKeys.arrivalAirport],
      [FlightBookingKeys.arrivalAirport]: sourceFlightItinerary?.[FlightBookingKeys.departureAirport],
    });

    this.attendeeItineraryModalComponent.showModal(targetFlightGroup, flightItinerary);
  };

  protected selectCopyTargetDisplayExpr = (flightGroup: AttendeeFlightGroup) => {
    const flightInformation = flightGroup?.[AttendeeFlightDataKeys.flightInformation];
    const groupTitle = flightGroup?.[AttendeeFlightDataKeys.groupTitle];
    const targetName =
      groupTitle?.split(GROUP_TITLE_DIVIDER)[0] ??
      [flightInformation?.[FlightInfoKeys.firstName], flightInformation?.[FlightInfoKeys.lastName]]
        .filter(Boolean)
        .join(' ');

    return 'Copy to: ' + targetName;
  };

  protected editAttendeeFlightInfo = (group: AttendeeFlightGroup) => {
    this.attendeeFlightDataModalComponent.showModal(group);
  };

  protected editAttendeeItinerary = (group: AttendeeFlightGroup) => {
    this.attendeeItineraryModalComponent.showModal(group);
  };

  protected confirmCompleting = async (data: AttendeeFlightGroup) => {
    const isConfirmed = await confirm('<i>Mark Itinerary as Confirmed?</i>', 'Continue');

    if (!isConfirmed) {
      return;
    }

    await this.attendeeFlightService.saveFlightUpdates(data, {
      [FlightInfoKeys.status]: FlightBookingStatus.confirmed,
    });
  };
}
