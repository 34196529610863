import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Agency, AgencyKeys } from 'ag-common-lib/lib/models/domain/agency.model';
import { ProspectService } from 'ag-common-svc/lib/services/prospect.service';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'ag-common-svc/lib/services/agency.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { Tab } from 'ag-common-lib/lib/models/ui/tab.model';
import { Msgs } from 'ag-common-lib/lib/constants/messages.constants';
import { Social } from 'ag-common-lib/lib/models/utils/social.model';
import { BehaviorSubject, of, tap } from 'rxjs';
import { Website } from 'ag-common-lib/lib/models/utils/website.model';
import {
  Address,
  AgencyRelationship,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/public-api';
import { LookupsService } from 'ag-common-svc/lib/services';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';

export enum AgencyTab {
  ContactInfo = 'Contact Info',
  AgencyInfo = 'Agency Info',
  AgencyRelationship = 'Agency Relationship with Alliance Group',
  Media = 'Media',
}

@Component({
  selector: 'ag-crm-agency-editor',
  templateUrl: './agency-editor.component.html',
  providers: [HasPermissionPipe],
  styleUrls: ['./agency-editor.component.scss'],
})
export class AgencyEditorComponent {
  @Output() fullScreenClosed = new EventEmitter<boolean>();

  private _selectedAgency;
  @Input()
  set selectedAgency(v: Agency) {
    this._selectedAgency = v;
  }
  get selectedAgency(): Agency {
    return this._selectedAgency;
  }

  viewFullScreen = false;
  detailsView = 'Agency';
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;

  agencyTabs: Tab[] = [
    { id: 0, text: AgencyTab.ContactInfo, template: AgencyTab.ContactInfo, isPermitted: of(true) },
    {
      id: 1,
      text: AgencyTab.AgencyInfo,
      template: AgencyTab.AgencyInfo,
      isPermitted: this.hasPermissionPipe.transform(Entity.agencyInformation),
    },
    {
      id: 2,
      text: AgencyTab.AgencyRelationship,
      template: AgencyTab.AgencyRelationship,
      isPermitted: this.hasPermissionPipe.transform(Entity.agencyAgencyRelationshipWithAllianceGroup),
    },
    {
      id: 3,
      text: AgencyTab.Media,
      template: AgencyTab.Media,
      isPermitted: this.hasPermissionPipe.transform(Entity.agencyMedia),
    },
  ];
  selectedAgencyTab: AgencyTab = AgencyTab.ContactInfo;
  inProgress$ = new BehaviorSubject<boolean>(false);
  socialTypesLookup$ = this.lookupsService.agencySocialTypesLookup$;
  socialMediaLookup$ = this.lookupsService.agencySocialMediaLookup$;
  websiteTypesLookup$ = this.lookupsService.agencyWebsiteTypesLookup$;

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AgencyTab = AgencyTab;
  protected readonly Messages = Msgs;
  protected readonly agencyAddressTypesLookup$ = this.lookupsService.agencyAddressTypesLookup$;

  constructor(
    private agencyService: AgencyService,
    private hasPermissionPipe: HasPermissionPipe,
    private readonly lookupsService: LookupsService,
  ) {}

  handleAgentFieldsChanges = ({ agencyId, updates }): void => {
    Object.assign(this.selectedAgency, updates);
  };

  selectAgencyTab(e): void {
    this.selectedAgencyTab = e.itemData.template;
  }

  onSaveSocial = (socialsList: Social[] | null): Promise<Social[]> => {
    return this.agencyService.updateFields(this.selectedAgency?.dbId, { socials: socialsList }).then(({ socials }) => {
      this.selectedAgency.socials = socials;
      return socials;
    });
  };

  onSaveWebsites = (websitesList: Website[] | null): Promise<Website[]> => {
    return this.agencyService
      .updateFields(this.selectedAgency?.dbId, { websites: websitesList })
      .then(({ websites }) => {
        this.selectedAgency.websites = websites;
        return websites;
      });
  };

  agencyRelationshipChange(relationshipWithAG: AgencyRelationship): Promise<void> {
    this.inProgress$.next(true);
    return this.agencyService
      .updateFields(this.selectedAgency?.dbId, { relationshipWithAG })
      .then(() => {
        this.selectedAgency.relationshipWithAG = relationshipWithAG;
      })
      .finally(() => {
        this.inProgress$.next(false);
      });
  }

  protected onSaveAddresses = async (addresses: Address[]) => {
    debugger;
    // await this.agencyService.updateFields(this.selectedAgency?.dbId, { [AgencyKeys.addresses]: addresses });
    // this.selectedAgency[AgencyKeys.addresses] = addresses;
  };
}
