import { Component, HostBinding, Input } from '@angular/core';
import {
  AgentMedia,
  AGMedia,
  AttendeeKeys,
  ConferenceRegistrationStepName,
  EmailAddressKeys, Entity, EntityPermissionActivityKeys,
  HotelReservationKeys,
  RegistrantData,
  RegistrantKeys,
  RegistrantWizardState,
  RegistrantWizardStateKeys,
} from 'ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { AttendeeDetailsHeaderService } from './attendee-details-header.service';
import { FlightInfoKeys } from 'ag-common-lib/lib/models/registration/flight-information.model';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { confirm } from 'devextreme/ui/dialog';
import { AttendeeDetailsModalRequestsService } from '../attendee-details-modal-requests.service';

@Component({
  selector: 'ag-crm-attendee-details-header',
  templateUrl: './attendee-details-header.component.html',
  styleUrls: ['./attendee-details-header.component.scss'],
})
export class AttendeeDetailsHeaderComponent {
  @HostBinding('class') className = 'attendee-details-header';

  @Input() formData: RegistrantData;
  @Input() showPicture = true;

  protected mediaPathPrefix: string;
  protected travelDatesSummary$: Observable<any[]> = this.attendeeDetailsHeaderService.travelDatesSummary$;
  protected agentMediaDataSource$: Observable<AgentMedia[]> = this.attendeeDetailsHeaderService.agentMediaDataSource$;
  protected wizardState$: Observable<RegistrantWizardState> = this.attendeeDetailsModalService.wizardState$;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly RegistrantWizardStateKeys = RegistrantWizardStateKeys;
  protected readonly ConferenceRegistrationStepName = ConferenceRegistrationStepName;
  protected readonly HotelReservationKeys = HotelReservationKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly EmailAddressKeys = EmailAddressKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected hasNotResolvedHotelReservationSectionRequests$ =
    this.attendeeDetailsModalRequestsService.hasNotResolvedHotelReservationSectionRequests$;
  protected hasNotResolvedFlightInfoSectionRequests$ =
    this.attendeeDetailsModalRequestsService.hasNotResolvedFlightInfoSectionRequests$;
  protected mayBeRefreshFromAgent$ = this.attendeeDetailsModalService.mayBeRefreshFromAgent$;
  protected currentStepInProgress$ = this.attendeeDetailsModalService.currentStepInProgress$;

  constructor(
    private attendeeDetailsHeaderService: AttendeeDetailsHeaderService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private attendeeDetailsModalRequestsService: AttendeeDetailsModalRequestsService,
  ) {}

  protected onImageCropped = async imageCropperPayload => {
    Object.assign(this.formData?.[RegistrantKeys.headshot], { imageCropperPayload });
  };

  protected onMediaChanged = async (mediaUrl: AGMedia) => {
    await this.attendeeDetailsHeaderService.onProfileImageChanged(mediaUrl);

    Object.assign(this.formData?.[RegistrantKeys.headshot], {
      wasabiPath: mediaUrl?.wasabiPath,
      imageCropperPayload: null,
    });
  };

  protected unlockWizard = async () => {
    const shouldContinue = await confirm(
      '<p>Wizard will become editable for Agent.</p><b>Do You wanna continue?</b>',
      'Confirm',
    );

    if (!shouldContinue) {
      return;
    }

    return this.attendeeDetailsModalService.unlockWizard();
  };

  protected lockWizard = async () => {
    const shouldContinue = await confirm(
      '<p>Wizard will become locked for Agent.</p><b>Do You wanna continue?</b>',
      'Confirm',
    );

    if (!shouldContinue) {
      return;
    }

    return this.attendeeDetailsModalService.lockWizard();
  };

  protected updateAttendee = async () => {
    const shouldContinue = await confirm(
      '<p>Attendee information will become updated.</p><b>Do You wanna continue?</b>',
      'Confirm',
    );

    if (!shouldContinue) {
      return;
    }

    return this.attendeeDetailsModalService.updateAttendee();
  };
}
