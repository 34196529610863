<ag-shr-modal-window
  #mediaUploaderModalRef
  [width]="'680px'"
  [height]="'700px'"
  [title]="'Upload Media'"
  [useScrollView]="false"
  [inProgress]="inProgress$ | async"
  [saveButtonDisabled]="(isFileSelected$ | async) === false"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="saveImage()"
>
  <ng-container *ngIf="mediaUploaderModalRef?.popupComponent?.visible ?? false">
    <ag-shr-media-sources
      [accept]="accept"
      [mediaPathPrefix]="mediaPathPrefix"
      [mediaSources]="mediaSources"
      [mediaItems]="mediaItems"
    ></ag-shr-media-sources>
  </ng-container>
</ag-shr-modal-window>
