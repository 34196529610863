import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import {
  DxoEditingModule,
  DxoFilterRowModule,
  DxoLoadPanelModule,
  DxoLookupModule,
  DxoScrollingModule,
  DxoSearchPanelModule,
} from 'devextreme-angular/ui/nested';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { PipesModule } from 'ag-common-svc/shared/pipes/pipes.module';
import { NotificationsSchedulerCheckedRecipientsGridComponent } from './notifications-scheduler-checked-recipients-grid.component';
import { DynamicListsAndSegmentsPipesModule } from 'ag-common-svc/shared/pipes/dynamic-lists-and-segments/dynamic-lists-and-segments-pipes.module';

@NgModule({
  declarations: [NotificationsSchedulerCheckedRecipientsGridComponent],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,

    DxoEditingModule,
    DxTemplateModule,
    DxoLookupModule,
    DxoFilterRowModule,
    DxoLoadPanelModule,
    DxoSearchPanelModule,
    DxoScrollingModule,
    DxDataGridModule,
    DynamicListsAndSegmentsPipesModule,
  ],
  exports: [NotificationsSchedulerCheckedRecipientsGridComponent],
})
export class NotificationsSchedulerCheckedRecipientsGridModule {}
