import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameTitleByMask',
})
export class NameTitleByMaskPipe implements PipeTransform {
  transform(name: string, mask: string, defaultValue: string = ''): string {
    if (!name) {
      return defaultValue;
    }

    return mask.replace('{{NAME}}', name);
  }
}
