import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StateSelectBoxModule } from 'ag-common-svc/lib/components/state-select-box/state-select-box.module';

import {
  AddressFormModule,
  AgEditorToolbarModule,
  ButtonWithIndicatorModule,
  ModalWindowModule,
} from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';
import { AddressesComponent } from './addresses.component';
import { AddressesGridComponent } from './addresses-grid/addresses-grid.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    ButtonWithIndicatorModule,
    StateSelectBoxModule,
    AgEditorToolbarModule,
    AddressFormModule,
    DxValidatorModule,
    DxValidationSummaryModule,
  ],
  declarations: [AddressesComponent, AddressesGridComponent],
  exports: [AddressesComponent],
})
export class AddressesModule {}
