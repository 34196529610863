<div class="mt-2">
  <div class="row my-2 px-3">
    <div class="col-12 text-start editToolbar">
      <dx-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="never"
        >
          <div *dxTemplate>
            <div class="toolbar-label">
              <b>States Licensed In</b>
            </div>
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          locateInMenu="never"
        >
          <div *dxTemplate>
            <dx-button
              [visible]="canEdit"
              [icon]="'edit'"
              [stylingMode]="'text'"
              (onClick)="showLicensesEditorModal()"
            ></dx-button>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
  </div>
  <div
    class="row my-2 px-3"
    *ngIf="licenses?.length > 0"
  >
    <div class="col-9"></div>
    <div class="col-3">
      <span style="font-weight: bold">Expiration Date</span>
    </div>
  </div>
  <div
    class="row my-2 px-3 text-center"
    *ngIf="!licenses || licenses?.length == 0"
  >
    <div
      class="col-12"
      style="text-align: center"
    >
      <span>No State Licenses Currently Exist</span>
    </div>
  </div>
  <ng-container *ngFor="let license of licenses">
    <div class="row my-2 px-3">
      <div class="col-3">{{ license.state }}</div>
      <div class="col-3">{{ license.license_number }}</div>
      <div class="col-3">{{ license.residency }}</div>
      <div class="col-3">
        <ng-container *ngIf="license?.expiration as expiration">{{ expiration| date: dateFormat }}</ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ag-shr-modal-window
  #licensesEditorModalRef
  [title]="'Add / Edit State Licenses'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="false"
  (onPopupClose)="dataGridRef.instance.cancelEditData()"
>
  <dx-data-grid
    #dataGridRef
    [dataSource]="licenses"
    [showRowLines]="true"
    [showBorders]="true"
    [showColumnLines]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >

    <dxo-toolbar>
      <dxi-item name="addRowButton"></dxi-item>
    </dxo-toolbar>
    <dxo-sorting mode="none"></dxo-sorting>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowUpdating]="canEdit"
      [allowDeleting]="canDelete"
      [allowAdding]="canCreate"
    >
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling
      [mode]="'virtual'"
      [showScrollbar]="'always'"
    ></dxo-scrolling>

    <dxi-column
      dataField="state"
      caption="State"
      editCellTemplate="stateEditorTmp"
    >
      <dxo-lookup
        [dataSource]="statesLookup$ | async"
        [displayExpr]="'description'"
        [valueExpr]="'value'"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="license_number"
      caption="License Number"
    ></dxi-column>
    <dxi-column
      dataField="residency"
      caption="Residency"
    >
      <dxo-lookup [dataSource]="residencyTypes"></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="expiration"
      caption="Expiration"
      [dataType]="'date'"
      [format]="dateFormat"
    ></dxi-column>
    <dxi-column type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'stateEditorTmp'">
      <ag-shr-state-select-box
        [value]="cell.value"
        [labelMode]="'hidden'"
        (valueChange)="cell.setValue($event)"
      >
      </ag-shr-state-select-box>
    </div>
  </dx-data-grid>
</ag-shr-modal-window>

<!-- <ng-container *ngIf="editLicensedIn">
  <dx-popup
    [width]="'75%'"
    [height]="'75%'"
    [showTitle]="true"
    [showCloseButton]="true"
    title="State Licenses"
    [dragEnabled]="false"
    [(visible)]="editLicensedIn"
  >
    <dx-scroll-view height="80%">
      <dx-tree-list
        [dataSource]="state_licenses"
        id="gridContainer"
        keyExpr="id"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [wordWrapEnabled]="true"

      >
        <dxo-toolbar>
          <dxi-item location="before">
            <div *dxTemplate>
              <b>&nbsp;&nbsp;&nbsp;State Licenses</b>
            </div>
          </dxi-item>
          <dxi-item name="addRowButton"></dxi-item>
        </dxo-toolbar>
        <dxo-editing
          mode="popup"
          [useIcons]="true"
          [allowUpdating]="true"
          [allowDeleting]="true"
          [allowAdding]="true"
        >
          <dxo-popup
            title="State Licenses"
            [showTitle]="true"
            [width]="'75%'"
            [height]="'80%'"
          ></dxo-popup>

          <dxo-form
            [colCount]="12"
            [labelLocation]="'top'"
          >
            <dxi-item
              dataField="state"
              [colSpan]="6"
            ></dxi-item>
            <dxi-item
              dataField="license_number"
              [colSpan]="6"
            ></dxi-item>
            <dxi-item
              dataField="residency"
              [colSpan]="6"
            ></dxi-item>
            <dxi-item
              dataField="expiration"
              [colSpan]="6"
            ></dxi-item>
          </dxo-form>
        </dxo-editing>

        <dxi-column
          dataField="state"
          caption="State"
          editCellTemplate="stateEditorTmp"
        >
          <dxo-lookup
            [dataSource]="statesLookup$ | async"
            [displayExpr]="'description'"
            [valueExpr]="'value'"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="license_number"
          caption="License Number"
        ></dxi-column>
        <dxi-column
          dataField="residency"
          caption="Residency"
        >
          <dxo-lookup [dataSource]="residencyTypes"></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="expiration"
          caption="Expiration"
          [dataType]="'date'"
        ></dxi-column>
        <dxi-column type="buttons">
          <dxi-button name="edit"></dxi-button>
          <dxi-button name="delete"></dxi-button>
        </dxi-column>


        <div *dxTemplate="let cell of 'stateEditorTmp'">
          <ag-shr-state-select-box
            [value]="cell.value"
            [labelMode]="'hidden'"
            (valueChange)="cell.setValue($event)"
          >
          </ag-shr-state-select-box>
        </div>
      </dx-tree-list>
    </dx-scroll-view>
    <div
      class="row d-flex my-3 justify-content-around"
      style="margin: 0 auto"
    >
      <div
        class="col-lg-12 text-end"
        style="text-align: right"
      >
        <dx-button
          width="200"
          stylingMode="contained"
          text="Close"
          type="default"
          (onClick)="editLicensedIn = false"
        ></dx-button>
      </div>
    </div>
  </dx-popup>
</ng-container> -->
