<section class="ag-grid">
  <dx-data-grid
    class="ag-grid__component"
    [dataSource]="conferences$ | async"
    [showBorders]="false"
    [focusedRowEnabled]="false"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [noDataText]="emptyMessage"
    height="100%"
    (onRowRemoving)="onRowRemoving($event)"
    (onRowDblClick)="onRowDblClick($event)"
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-scrolling mode="infinite" [showScrollbar]="'always'"></dxo-scrolling>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate></div>
      </dxi-item>

      <dxi-item location="before" width="100px">
        <div *dxTemplate>
          <h5 class="ag-grid__toolbar-title">Events</h5>
        </div>
      </dxi-item>

      <dxi-item
        *ngIf="(Entity.conference | hasPermission: EntityPermissionActivityKeys.create | async) || (Entity.conferenceListAdmin | hasPermission: EntityPermissionActivityKeys.create | async) || (Entity.conferenceList | hasPermission: EntityPermissionActivityKeys.create | async)"
        location="after"
        widget="dxButton"
        cssClass="ag-grid__toolbar-control"
        [options]="{ icon: 'add', onClick: addConference }"
      >
      </dxi-item>

      <dxi-item name="searchPanel" cssClass="ag-grid__toolbar-control"></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [allowUpdating]="false"
      [allowDeleting]="(Entity.conference | hasPermission: EntityPermissionActivityKeys.delete | async) || (Entity.conferenceListAdmin | hasPermission: EntityPermissionActivityKeys.delete | async)"
      [allowAdding]="false"
      [useIcons]="true"
    >
    </dxo-editing>

    <dxi-column [dataField]="ConferenceKeys.eventId" [caption]="titleList[ConferenceKeys.eventId]"></dxi-column>

    <dxi-column [dataField]="ConferenceKeys.eventName" [caption]="titleList[ConferenceKeys.eventName]"></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.startDate"
      dataType="date"
      [caption]="titleList[ConferenceKeys.startDate]"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.endDate"
      dataType="date"
      [caption]="titleList[ConferenceKeys.endDate]"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.facilityName"
      [caption]="titleList[ConferenceKeys.facilityName]"
    ></dxi-column>

    <dxi-column dataField="event_address.address1" [visible]="false" [caption]="titleList.eventAddress1"></dxi-column>

    <dxi-column dataField="event_address.address2" [visible]="false" [caption]="titleList.eventAddress2"></dxi-column>

    <dxi-column dataField="event_address.city" [visible]="false" [caption]="titleList.eventCity"></dxi-column>

    <dxi-column dataField="event_address.state" [visible]="false" [caption]="titleList.eventState"></dxi-column>

    <dxi-column dataField="event_address.zip" [visible]="false" [caption]="titleList.eventZip"></dxi-column>

    <dxi-column dataField="event_address.country" [visible]="false" [caption]="titleList.eventCountry"></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.eventCoordinatorFirstName"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.eventCoordinatorFirstName]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.eventCoordinatorLastName"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.eventCoordinatorLastName]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.eventCoordinatorPhone"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.eventCoordinatorPhone]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.hotelName"
      [caption]="titleList[ConferenceKeys.hotelName]"
      [visible]="false"
    ></dxi-column>

    <dxi-column dataField="hotel_address.address1" [visible]="false" [caption]="titleList.hotelAddress1"></dxi-column>

    <dxi-column dataField="hotel_address.address2" [visible]="false" [caption]="titleList.hotelAddress2"></dxi-column>

    <dxi-column dataField="hotel_address.city" [visible]="false" [caption]="titleList.hotelCity"></dxi-column>

    <dxi-column dataField="hotel_address.state" [visible]="false" [caption]="titleList.hotelState"></dxi-column>

    <dxi-column dataField="hotel_address.zip" [visible]="false" [caption]="titleList.hotelZip"></dxi-column>

    <dxi-column dataField="hotel_address.country" [visible]="false" [caption]="titleList.hotelCountry"></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.hotelCoordinatorFirstName"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.hotelCoordinatorFirstName]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.hotelCoordinatorLastName"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.hotelCoordinatorLastName]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.hotelPrimaryPhone"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.hotelPrimaryPhone]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.hotelRoomRate"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.hotelRoomRate]"
      dataType="number"
      alignment="left"
      [format]="DX_USD_CURRENCY_FORMAT"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.hotelNumRoomsBlocked"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.hotelNumRoomsBlocked]"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.printMarketingBudget"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.printMarketingBudget]"
      dataType="number"
      alignment="left"
      [format]="DX_USD_CURRENCY_FORMAT"
    ></dxi-column>

    <dxi-column
      [dataField]="ConferenceKeys.foodBudget"
      [visible]="false"
      [caption]="titleList[ConferenceKeys.foodBudget]"
      dataType="number"
      alignment="left"
      [format]="DX_USD_CURRENCY_FORMAT"
    ></dxi-column>

    <dxi-column type="buttons" [width]="'auto'">
      <dxi-button
        *var="Entity.conference | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
        [icon]="canWrite ? 'edit' : 'agi-eye'"
        [hint]="canWrite ? 'Edit' : 'View'"
        [onClick]="editConference"
      ></dxi-button>

      <dxi-button
        icon="agi-people-group"
        [visible]="Entity.conferenceAttendeeDetails | hasPermission | async"
        [onClick]="showRegistrantsConfiguration.bind(this)"
      ></dxi-button>

      <dxi-button
        name="delete"
        [visible]="(Entity.conference | hasPermission: EntityPermissionActivityKeys.delete | async) || (Entity.conferenceListAdmin | hasPermission: EntityPermissionActivityKeys.delete | async) || (Entity.conferenceList | hasPermission: EntityPermissionActivityKeys.delete | async)"
      ></dxi-button>

      <dxi-button
        icon="mediumiconslayout"
        [visible]="Entity.conferenceRegistrants | hasPermission | async"
        [onClick]="showConferenceTasks"
        hint="View Event Registrant Task"
      ></dxi-button>

      <dxi-button
        icon="money"
        [visible]="Entity.conferenceTransactions | hasPermission | async"
        [onClick]="showTransactionsModal"
        hint="View Transactions"
      ></dxi-button>

      <dxi-button
        icon="agi-rock-and-roll"
        [onClick]="showSeatRemainingList"
        hint="View Seats Remaining"
        [visible]="
          isSeatsRemainingVisible && (Entity.conferenceExcursionStatistic | hasPermission | async)"
      ></dxi-button>
    </dxi-column>
  </dx-data-grid>
</section>

<ag-crm-conference-modal #conferenceModalRef></ag-crm-conference-modal>

<ag-crm-attendees-list-modal #attendeesModalRef></ag-crm-attendees-list-modal>

<ag-crm-conference-excursion-statistic-modal
  #conferenceExcursionStatisticModalRef
></ag-crm-conference-excursion-statistic-modal>

<ag-crm-conference-transactions-modal #conferenceTransactionsModalRef></ag-crm-conference-transactions-modal>
