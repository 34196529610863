<div class="agency-header agency-header__container">
  <ng-container *var="agency$ | async as agency">
    <div class="agency-header__image-container">
      <ag-shr-image-with-loader
        class="agency-header__image"
        [wasabiPath]="agency?.head_shot?.wasabiPath"
        [cropperPayload]="agency?.head_shot?.imageCropperPayload"
        [mediaSize]="MediaSize.origin"
      ></ag-shr-image-with-loader>

      <dx-drop-down-button
        *ngIf="Entity.agencyEditPicture | hasPermission : EntityPermissionActivityKeys.write | async"
        class="agency-campaign-picture__button"
        [showArrowIcon]="false"
        [dropDownOptions]="{ width: 230 }"
        icon="edit"
        stylingMode="text"
        [items]="[
          {
            icon: 'mdi mdi-upload',
            text: 'Add Picture',
            onClick: onUploadClicked,
            visible: Entity.agencyEditPicture | hasPermission : EntityPermissionActivityKeys.create | async
          },
          {
            icon: 'mdi mdi-pencil',
            text: 'Edit Thumbnail',
            onClick: handleCropImage,
            disabled: !(Entity.agencyEditPicture | hasPermission : EntityPermissionActivityKeys.write | async)
          }
        ]"
      ></dx-drop-down-button>

      <ag-shr-media-uploader-modal
        [mediaPathPrefix]="mediaPathPrefix"
        (mediaChanged)="onMediaChanged($event)"
        [mediaItems]="agencyMediaDataSource$ | async"
      ></ag-shr-media-uploader-modal>

      <ag-shr-image-cropper-modal
        [onImageCropped]="onImageCropped"
        [title]="'Edit Thumbnail'"
        [roundCropper]="false"
        [maintainAspectRatio]="false"
      ></ag-shr-image-cropper-modal>
    </div>

    <div class="col">
      <div class="row align-items-center">
        <div class="col-8 agency-header__title">
          <span>
            {{ agency?.name }}
            <i
              class="dx-icon-favorites superscript-icon"
              *ngIf="agency?.agency_type === agencyType.MGA"
              title="MGA"
            ></i>
          </span>

          <dx-button
            *ngIf="Entity.agencyInformation | hasPermission : EntityPermissionActivityKeys.write | async"
            class="mr-2"
            icon="edit"
            stylingMode="text"
            (onClick)="showEditorModal()"
          ></dx-button>

          <i
            class="dx-icon-agi-trophy trophy-icon"
            *ngIf="agency?.relationshipWithAG?.isFoundingMember"
            title="Founding Member"
          ></i>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col">
          <p class="mb-0" *ngIf="agency?.agency_type !== agencyType.MGA">
            MGA: {{ agency?.mga | mgaName : (mgas$ | async) }}
          </p>

          <p class="mb-0">Agency ID: {{ agency?.agency_id }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="pb-1">
            <div class="agency-header__key">Office Email Address</div>
            <div class="agency-header__value" [class.ag-empty-text]="!agency?.officeEmailAddress?.length">
              {{ agency?.officeEmailAddress | AgencyPrimaryEmailAddress | empty : "n/a" }}
            </div>
          </div>

          <div class="pb-1">
            <div class="agency-header__key">Office Phone Number</div>
            <div class="agency-header__value" [class.ag-empty-text]="!agency?.officePhoneNumbers?.length">
              {{ agency?.officePhoneNumbers | primaryPhoneNumber | phoneNumberMask | empty : "n/a" }}
            </div>
          </div>
        </div>

        <div class="col-auto">
          <div class="agency-header__key">Office Mailing Address</div>
          <div class="agency-header__value" *var="agency?.addresses as addresses">
            <p class="mb-0">{{ addresses | primaryShippingAddress | fullAddress : null : "street" }}</p>
            <p class="mb-0">{{ addresses | primaryShippingAddress | fullAddress : null : "other" }}</p>
            <p class="mb-0 ag-empty-text" *ngIf="!addresses?.length">n/a</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ag-shr-modal-window
  [width]="'auto'"
  [height]="'auto'"
  #agenyCompanyLogoModalRef
  [title]="'Agency Logo'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveAgencyLogoImagesUpdates()"
  [onCloseModal]="handleClosePopup"
>
  <ag-shr-drop-zone
    #agencyLogoDropZoneRef
    [pictureUrl]="headerLink$ | async"
    (pictureUrlChange)="onPictureUrlChange($event)"
    [textBoxLabel]="'Company Logo URL'"
    [imageCroperTitle]="'Crop Company Logo Picture'"
    [roundCropper]="false"
    [ratio]="aspectRatio"
    [showToolbar]="true"
  ></ag-shr-drop-zone>
</ag-shr-modal-window>

<ag-shr-modal-window
  #agencyHeaderModalRef
  [width]="'860px'"
  [height]="'auto'"
  [title]="'Agency Information'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="saveAgencyHeaderInfoUpdates()"
  [onCloseModal]="handleClosePopup"
>
  <dx-form
    #agencyHeaderFormRef
    class="agency-header__form"
    [colCount]="4"
    [formData]="agencyHeaderFormDetails"
    [labelMode]="'floating'"
    [minColWidth]="100"
  >
    <dxi-item [dataField]="AgencyKeys.name" [colSpan]="2" [label]="{ text: 'Agency Name' }"></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.agencyId"
      [label]="{ text: 'Agency ID' }"
      [colSpan]="2"
      [editorOptions]="{
        readOnly: !(Entity.agencyInformation | hasPermission : EntityPermissionActivityKeys.write | async)
      }"
    ></dxi-item>

    <dxi-item [dataField]="AgencyKeys.agencyNumber" [label]="{ text: 'Agency Number' }" [colSpan]="2"></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.agencyStatus"
      [label]="{ text: 'Agency Status' }"
      [colSpan]="2"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: agencyStatusesLookup$ | async,
        valueExpr: BaseModelKeys.dbId,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: '',
        onSelectionChanged: onSelectionChanged
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.agencyType"
      [label]="{ text: 'Agency Type' }"
      [colSpan]="2"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: AGENCY_TYPES_LOOKUP,
        valueExpr: 'value',
        displayExpr: 'description',
        searchEnabled: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.mga"
      [label]="{ text: 'MGA' }"
      [colSpan]="2"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: mgas$ | async,
        valueExpr: 'agency_id',
        displayExpr: 'name',
        placeholder: '',
        readOnly: agencyHeaderFormDetails?.agency_type === agencyType.MGA
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.startingMultiplier"
      [colSpan]="2"
      editorType="dxNumberBox"
      [label]="{ text: 'Starting Multiplier' }"
      [editorOptions]="{
        min: 1,
        format: '#'
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.levelDivisor"
      [colSpan]="2"
      editorType="dxNumberBox"
      [label]="{ text: 'Level Divisor' }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.agencyLead"
      [label]="{ text: 'Agency Lead' }"
      [colSpan]="2"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: selectedAgencyLeads$ | async,
        valueExpr: AgentKeys.p_agent_id,
        displayExpr: AgentKeys.p_agent_name,
        searchEnabled: true,
        showClearButton: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item
      [colSpan]="2"
      editorType="dxSelectBox"
      [dataField]="AgencyHeaderKeys.primaryEmailAddress"
      [label]="{ text: 'Primary Email Address' }"
      [editorOptions]="{
        items: agencyHeaderFormDetails?.officeEmailAddress,
        displayExpr: 'address',
        searchEnabled: true,
        placeholder: ''
      }"
    >
    </dxi-item>

    <dxi-item
      [colSpan]="2"
      editorType="dxSelectBox"
      [dataField]="AgencyHeaderKeys.primaryPhoneNumber"
      [label]="{ text: 'Primary Phone Number' }"
      [editorOptions]="{
        items: agencyHeaderFormDetails?.officePhoneNumbers,
        displayExpr: phoneNumberDisplayExpr,
        searchEnabled: true,
        placeholder: ''
      }"
    >
    </dxi-item>

    <dxi-item
      [colSpan]="2"
      editorType="dxSelectBox"
      [dataField]="AgencyHeaderKeys.primaryShippingAddress"
      [label]="{ text: 'Primary Shipping Address' }"
      [editorOptions]="{
        items: agencyHeaderFormDetails?.addresses,
        displayExpr: fullAddressDisplayExpr,
        searchEnabled: true,
        placeholder: ''
      }"
    >
    </dxi-item>

    <dxi-item
      [dataField]="AgencyKeys.rmds"
      [colSpan]="4"
      editorType="dxTagBox"
      [label]="{ text: 'RMDs' }"
      [editorOptions]="{
        items: rmds$ | async,
        displayExpr: AgentKeys.p_agent_name,
        valueExpr: AgentKeys.p_agent_id,
        searchEnabled: true,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: ''
      }"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
