<ag-shr-modal-window
  #conferencePermissionsFormModalRef
  [title]="isCreateMode ? 'Add Permissions' : 'Update Permissions'"
  [width]="'700px'"
  [height]="'auto'"
  [showSaveButton]="true"
  [inProgress]="inProgress$ | async"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handleSaveClick($event)"
>
  <dx-form
    #conferencePermissionsFormRef
    *ngIf="conferencePermissionsFormModalRef?.popupComponent?.visible ?? false"
    [(formData)]="agentPermissionsFormData"
    labelMode="floating"
  >
    <dxi-item [visible]="!isCreateMode">
      <div *dxTemplate>
        {{
          [
            agentPermissionsFormData?.[AgentKeys.p_agent_first_name],
            agentPermissionsFormData?.[AgentKeys.p_agent_last_name],
          ] | fullName
        }}
        <ng-container *ngIf="agentPermissionsFormData?.[AgentKeys.email_addresses] | AgentPrimaryEmailAddress as email">
          (<i>{{ email | empty }}</i
          >)
        </ng-container>
      </div>
    </dxi-item>

    <dxi-item itemType="group" [visible]="isCreateMode" [colCount]="12">
      <dxi-item [dataField]="BaseModelKeys.dbId" [colSpan]="12" [isRequired]="true">
        <div *dxTemplate>
          <dx-select-box
            [label]="'Agent Name'"
            [labelMode]="'floating'"
            (onSelectionChanged)="valueChange($event)"
            [dataSource]="agentListWithConferencePermission$ | async"
            [valueExpr]="'dbId'"
            [displayExpr]="AgentKeys.p_agent_name"
            [searchEnabled]="true"
            [searchExpr]="[AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name, AgentKeys.p_email]"
            [dropDownOptions]="{ wrapperAttr: { class: 'ag-select-box-drop-down' } }"
          >
            <div *dxTemplate="let data of 'item'">
              {{ [data?.[AgentKeys.p_agent_first_name], data?.[AgentKeys.p_agent_last_name]] | fullName }}
              (<i>{{ data?.[AgentKeys.p_email] | empty }}</i
              >)
            </div>
            <dx-validator>
              <dxi-validation-rule type="required" [message]="Messages.REQUIRED_TEXT"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </dxi-item>

      <dxi-item
        [colSpan]="12"
        *var="agentPermissionsFormData?.[AgentKeys.email_addresses] | AgentPrimaryEmailAddress as email"
      >
        <div *dxTemplate><i>Primary Email Address</i>: {{ email | empty }}</div>
      </dxi-item>
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>
