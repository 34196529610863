import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ActiveLookup,
  Address,
  AddressModelKeys,
  BaseModelKeys,
  BUSINESS_PERSONAL_TYPE,
  BUSINESS_PERSONAL_TYPE_LOOKUP,
  COUNTRIES,
  Messages,
} from '@ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { LookupsService } from '../../../../services';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { AddressFormComponent } from 'ag-common-svc/lib/components/address-form/address-form.component';
import { compareAddressesIsDifferent } from 'ag-common-svc/shared/utils/full-address';
import { normalizeAddresses } from 'ag-common-svc/lib/utils/address.util';

@Component({
  selector: 'ag-shr-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnChanges {
  @ViewChild('addressesModalRef') addressesModalComponent: ModalWindowComponent;
  @ViewChild('manageAddressModalRef') manageAddressModalComponent: ModalWindowComponent;
  @ViewChild('addressesGridREf', { static: false }) addressGridComponent: DxDataGridComponent;
  @ViewChild('addressFormRef', { static: false }) addressFormComponent: AddressFormComponent;

  @Input() title: string = 'Addresses';
  @Input() addresses: Address[] = [];
  @Input() canEdit = true;
  @Input() canCreate = true;
  @Input() canDelete = true;
  @Input() showPhysicalLocation = true;
  @Input() showBillingAddress = true;
  @Input() showShippingAddress = true;
  @Input() typesLookup: Partial<ActiveLookup>[] = BUSINESS_PERSONAL_TYPE_LOOKUP;
  @Input() updateAddress: (addresses: Address[]) => Promise<void>;
  @Output() addressesChange = new EventEmitter();

  protected readonly inProgress$ = new BehaviorSubject<boolean>(false);
  protected readonly countries = COUNTRIES;
  protected readonly statesLookup$: Observable<ActiveLookup[]>;
  protected readonly Messages = Messages;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly addressValidationGroup = 'addressValidationGroup';
  protected readonly addressUniqValidationGroup = 'addressUniqValidationGroup';

  protected addressToEdit: Address;

  private defaultAddressType = BUSINESS_PERSONAL_TYPE.BUSINESS;

  protected readonly addressUniqAdapter = {
    getValue: e => {
      return this.addressToEdit;
    },
    bypass: () => {
      return !this.addressToEdit?.[AddressModelKeys.address1];
    },
    reset: e => {},
  };

  constructor(private readonly lookupsService: LookupsService) {
    this.statesLookup$ = this.lookupsService.statesLookup$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.typesLookup) {
      const typesLookup = changes.typesLookup.currentValue;
      const defaultAddressType =
        typesLookup?.find(lookup => lookup?.isDefault)?.[BaseModelKeys.dbId] ??
        typesLookup?.[0]?.[BaseModelKeys.dbId] ??
        BUSINESS_PERSONAL_TYPE.BUSINESS;
      this.defaultAddressType = defaultAddressType;
    }
  }

  protected addAddress = () => {
    const hasPrimaryBillingAddresses =
      !!this.addresses?.length && this.addresses?.some(address => address?.[AddressModelKeys.isPrimaryBilling]);
    const hasPrimaryShippingAddresses =
      !!this.addresses?.length && this.addresses?.some(address => address?.[AddressModelKeys.isPrimaryShipping]);

    const address = Object.assign({}, new Address(), {
      [AddressModelKeys.addressType]: this.defaultAddressType,
      [AddressModelKeys.isPrimaryBilling]: this.showBillingAddress && !hasPrimaryBillingAddresses,
      [AddressModelKeys.isPrimaryShipping]: this.showShippingAddress && !hasPrimaryShippingAddresses,
    });
    this.addressToEdit = address;

    this.manageAddressModalComponent.showModal();
  };

  protected editAddress = (address: Address) => {
    this.addressToEdit = address;
    this.manageAddressModalComponent.showModal();
  };

  protected checkIsAddressUniq = async () => {
    return this.addresses.every(address => {
      if (address === this.addressToEdit) {
        return true;
      }

      return compareAddressesIsDifferent(address, this.addressToEdit);
    });
  };

  protected handleAddressSave = async e => {
    this.inProgress$.next(true);
    const isValid = await validateDxGroups([this.addressValidationGroup, this.addressUniqValidationGroup]);

    if (!isValid) {
      this.inProgress$.next(false);
      return;
    }

    const addresses = normalizeAddresses(this.addressToEdit, this.addresses);

    try {
      await this.handleAddressesUpdate(addresses);

      this.manageAddressModalComponent.forceCloseModal();
    } catch (error) {
      this.inProgress$.next(false);
    }
  };

  protected handleClosePopup = () => {
    return this.addressFormComponent.onCancelEdit();
  };

  protected onRowRemoving = e => {
    const addresses = this.addresses.filter(address => {
      return address !== e.key;
    });

    e.cancel = this.updateAddress(addresses);
  };

  protected canDeleteRow = e => {
    return !e.row.data.is_primary_billing && !e.row.data.is_primary_shipping;
  };

  private handleAddressesUpdate = async addresses => {
    this.inProgress$.next(true);
    if (this.updateAddress) {
      await this.updateAddress(addresses);
    }
    this.inProgress$.next(false);
  };
}
