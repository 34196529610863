import { Pipe, PipeTransform } from '@angular/core';
import { convertFilterGroup } from 'ag-common-svc/lib/utils/filter-builder-converter.utils';
import { FilterGroup } from '@ag-common-lib/lib';

@Pipe({
  name: 'convertFilterGroupToFilterValue',
})
export class ConvertFilterGroupToValuePipe implements PipeTransform {
  transform(filter: FilterGroup): any[] {
    if (!filter) {
      return null;
    }
    return convertFilterGroup(filter);
  }
}
