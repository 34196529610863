import { Component, Input, ViewChild } from '@angular/core';
import {
  Agent,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/public-api';
import { MgaEditorModalComponent } from './modal/mga-editor-modal.component';
import { MgaFormService } from './modal/mga-form.service';

@Component({
  selector: 'ag-crm-mga',
  templateUrl: './mga.component.html',
  styleUrls: ['./mga.component.scss'],
  providers: [MgaFormService],
})
export class MgaComponent {
  @ViewChild('mgaEditorModal', { static: true }) mgaEditorModalComponent: MgaEditorModalComponent;
  @Input()
  set agent(agent: Agent) {
    this._agent = agent;
    this.mgaFormService.buildManagersList(agent);
  }

  get agent(): Agent {
    return this._agent;
  }

  private _agent: Agent;
  filteredManagers$ = this.mgaFormService.filteredManagers$;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;

  constructor(private mgaFormService: MgaFormService) {}

  getManager(managerId): string {
    const managers = this.filteredManagers$.value;
    if (managers && managerId) {
      let agents: Agent[] = managers.filter(agent => agent.p_agent_id == managerId);
      return agents.length == 1 ? agents[0].p_agent_name : '';
    } else {
      return '';
    }
  }

  showEditorModal(): void {
    this.mgaEditorModalComponent.showModal(this.agent);
  }
}
