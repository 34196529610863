<ag-shr-modal-window
  #notificationSchedulerEditModalRef
  *var="
    (isDeliveredNotification$ | async) ||
    (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) === false as isReadOnly
  "
  [width]="'80vw'"
  [height]="'80vh'"
  title="Schedule Notification"
  [inProgress]="inProgress$ | async"
  [actionTitle]="'SAVE'"
  [showSaveButton]="!isReadOnly"
  (onSaveClick)="handleScheduleNotification()"
  [useScrollView]="false"
  [extraToolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'bottom',
      visible:
        !!scheduledNotification?.[BaseModelKeys.dbId] &&
        (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.delete | async),
      options: {
        text: 'REMOVE',
        onClick: sendNotification,
      },
    },
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'bottom',
      visible:
        !!scheduledNotification?.[BaseModelKeys.dbId] &&
        (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.create | async) &&
        (!scheduledNotification?.[PushNotificationCampaignKeys.isBroadcast] ||
          (Entity.notificationsBroadcastDelivery | hasPermission: EntityPermissionActivityKeys.create | async)),
      options: {
        text: 'CLONE',
        onClick: cloneNotification,
      },
    },
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'bottom',
      visible:
        isCheckedRecipientsListVisible &&
        (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) &&
        (!scheduledNotification?.[PushNotificationCampaignKeys.isBroadcast] ||
          (Entity.notificationsBroadcastDelivery | hasPermission: EntityPermissionActivityKeys.create | async)),
      options: {
        text: 'BACK',
        onClick: toggleCheckNotification,
      },
    },
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'bottom',
      visible:
        !isCheckedRecipientsListVisible &&
        (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) &&
        (!scheduledNotification?.[PushNotificationCampaignKeys.isBroadcast] ||
          (Entity.notificationsBroadcastDelivery | hasPermission: EntityPermissionActivityKeys.create | async)),
      options: {
        text: 'CHECK RECIPIENTS',
        onClick: toggleCheckNotification,
      },
    },
  ]"
>
  <ng-container *ngIf="notificationSchedulerEditModalRef?.popupComponent?.visible ?? false">
    <div class="notification-scheduler-edit-modal__container">
      <div class="notification-scheduler-edit-modal__form-container">
        <dx-form
          class="ag-assign-owner__form"
          [(formData)]="scheduledNotification"
          labelMode="floating"
          [validationGroup]="validationGroup"
          [readOnly]="isReadOnly"
        >
          <dxi-item [itemType]="'group'">
            <dxi-item [itemType]="'group'" [colCount]="2">
              <dxi-item [dataField]="PushNotificationCampaignKeys.title" [label]="{ text: 'Notification' }">
                <div *dxTemplate>
                  <dx-select-box
                    #notificationSelectBoxRef
                    [items]="notificationsDataSource$ | async"
                    [value]="scheduledNotification && scheduledNotification[PushNotificationCampaignKeys.title]"
                    [valueExpr]="PushNotificationCampaignKeys.title"
                    [displayExpr]="PushNotificationCampaignKeys.title"
                    [searchExpr]="[
                      PushNotificationCampaignKeys.title,
                      [PushNotificationCampaignKeys.notification, PushNotificationKeys.title].join('.'),
                      [PushNotificationCampaignKeys.notification, PushNotificationKeys.body].join('.'),
                    ]"
                    label="Notification"
                    labelMode="floating"
                    [placeholder]="''"
                    [searchEnabled]="true"
                    [acceptCustomValue]="true"
                    (onCustomItemCreating)="onCustomItemCreating($event, notificationSelectBoxRef)"
                    (onValueChanged)="onValueChanged(notificationSelectBoxRef.selectedItem)"
                    [readOnly]="isReadOnly"
                  >
                  </dx-select-box>
                </div>
              </dxi-item>

              <dxi-item
                [dataField]="PushNotificationCampaignKeys.deliverAt"
                [label]="{ text: 'Deliver At' }"
                editorType="dxDateBox"
                [isRequired]="true"
                [editorOptions]="{
                  displayFormat: Constants.DISPLAY_DATE_TIME_FORMAT,
                  useMaskBehavior: true,
                  min: dateNow,
                  type: 'datetime',
                }"
              ></dxi-item>
            </dxi-item>
            <dxi-item
              [dataField]="[PushNotificationCampaignKeys.notification, PushNotificationKeys.title] | path"
              [label]="{ text: 'Title' }"
              [isRequired]="true"
            >
              <dxi-validation-rule type="required"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="65"></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [dataField]="[PushNotificationCampaignKeys.notification, PushNotificationKeys.body] | path"
              [label]="{ text: 'Body' }"
              [editorType]="'dxTextArea'"
              [editorOptions]="{ height: 100 }"
            >
              <dxi-validation-rule type="stringLength" [max]="240"></dxi-validation-rule>
            </dxi-item>

            <dxi-item>
              <div *dxTemplate></div>
            </dxi-item>
          </dxi-item>

          <dxi-item>
            <div *dxTemplate></div>
          </dxi-item>
        </dx-form>

        <shr-html-editor
          class="mt-2 notification-scheduler-edit-modal__html-editor"
          [name]="PushNotificationCampaignKeys.description"
          [label]="'Details'"
          [isRequired]="true"
          [isReadOnly]="isReadOnly"
          [fileDirectory]="'push-notification-campaign'"
          [value]="scheduledNotification[PushNotificationCampaignKeys.description]"
          (valueChange)="scheduledNotification[PushNotificationCampaignKeys.description] = $event"
        ></shr-html-editor>
      </div>

      <div class="notification-scheduler-edit-modal__recipients-container">
        <ng-container *ngIf="!isCheckedRecipientsListVisible; else checkedRecipientsListTemp">
          <dx-toolbar
            class="notification-scheduler-edit-modal__recipients-toolbar"
            [visible]="
              scheduledNotification[PushNotificationCampaignKeys.isBroadcast] ||
              (Entity.notificationsBroadcastDelivery
                | hasPermission
                  : [
                      EntityPermissionActivityKeys.read,
                      EntityPermissionActivityKeys.create,
                      EntityPermissionActivityKeys.write,
                    ]
                | async)
            "
          >
            <dxi-item location="before" widget="dxButton" locateInMenu="never">
              <div>
                <div class="toolbar-label">
                  <b>Broadcast Delivery</b>
                </div>
              </div>
            </dxi-item>

            <dxi-item location="after" locateInMenu="never">
              <div>
                <dx-switch
                  [(value)]="scheduledNotification[PushNotificationCampaignKeys.isBroadcast]"
                  [switchedOnText]="'Yes'"
                  [switchedOffText]="'No'"
                  [readOnly]="
                    isReadOnly ||
                    (Entity.notificationsBroadcastDelivery
                      | hasPermission: EntityPermissionActivityKeys.write
                      | async) === false
                  "
                ></dx-switch>
              </div>
            </dxi-item>
          </dx-toolbar>

          <dx-toolbar class="notification-scheduler-edit-modal__recipients-toolbar">
            <dxi-item location="before" widget="dxButton" locateInMenu="never">
              <div>
                <div class="toolbar-label">
                  <b>Channels</b>
                </div>
              </div>
            </dxi-item>

            <dxi-item location="after" locateInMenu="never">
              <div>
                <dx-button
                  [icon]="'plus'"
                  (onClick)="showAddChannelModal()"
                  [disabled]="
                    isReadOnly ||
                    scheduledNotification[PushNotificationCampaignKeys.isBroadcast] ||
                    (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) ===
                      false
                  "
                ></dx-button>
              </div>
            </dxi-item>
          </dx-toolbar>

          <dx-list
            class="notification-scheduler-edit-modal__list"
            [dataSource]="scheduledNotification?.[PushNotificationCampaignKeys.channels]"
            [allowItemDeleting]="!isReadOnly"
            [noDataText]="'No Channels Added'"
            [height]="'auto'"
          >
            <div *dxTemplate="let item of 'item'">
              {{ item | notificationsChannelName | async }}
            </div>
          </dx-list>

          <dx-toolbar class="notification-scheduler-edit-modal__recipients-toolbar">
            <dxi-item location="before" widget="dxButton" locateInMenu="never">
              <div>
                <div class="toolbar-label">
                  <b>Lists</b>
                </div>
              </div>
            </dxi-item>

            <dxi-item location="after" locateInMenu="never">
              <div>
                <dx-button
                  [icon]="'plus'"
                  (onClick)="showAddDynamicListsGroupModal()"
                  [disabled]="
                    isReadOnly ||
                    scheduledNotification[PushNotificationCampaignKeys.isBroadcast] ||
                    (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) ===
                      false
                  "
                ></dx-button>
              </div>
            </dxi-item>
          </dx-toolbar>

          <dx-list
            class="notification-scheduler-edit-modal__list"
            [dataSource]="
              scheduledNotification?.[PushNotificationCampaignKeys.dynamicListsIds]
                | listsAndSegmentsDataSourceByDbIds
                | async
            "
            [allowItemDeleting]="!isReadOnly"
            [noDataText]="'No Lists Added'"
            [height]="'auto'"
            [displayExpr]="'title'"
            [searchExpr]="['title']"
            [searchEditorOptions]="{ placeholder: 'Search Lists' }"
            [searchEnabled]="
              !scheduledNotification[PushNotificationCampaignKeys.isBroadcast] &&
              !!scheduledNotification?.[PushNotificationCampaignKeys.dynamicListsIds]?.length
            "
            (onItemDeleted)="deleteList($event)"
          >
          </dx-list>

          <dx-toolbar class="notification-scheduler-edit-modal__recipients-toolbar">
            <dxi-item location="before" widget="dxButton" locateInMenu="never">
              <div>
                <div class="toolbar-label">
                  <b>Segments</b>
                </div>
              </div>
            </dxi-item>

            <dxi-item location="after" locateInMenu="never">
              <div>
                <dx-button
                  [icon]="'plus'"
                  (onClick)="showAddDynamicSegmentModal()"
                  [disabled]="
                    isReadOnly ||
                    scheduledNotification[PushNotificationCampaignKeys.isBroadcast] ||
                    (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) ===
                      false
                  "
                ></dx-button>
              </div>
            </dxi-item>
          </dx-toolbar>

          <dx-list
            class="notification-scheduler-edit-modal__list"
            [dataSource]="
              scheduledNotification?.[PushNotificationCampaignKeys.dynamicSegmentsIds]
                | listsAndSegmentsDataSourceByDbIds: 'segments'
                | async
            "
            [allowItemDeleting]="!isReadOnly"
            [noDataText]="'No Segments Added'"
            [height]="'auto'"
            [displayExpr]="'title'"
            [searchExpr]="['title']"
            [searchEditorOptions]="{ placeholder: 'Search Segments' }"
            [searchEnabled]="
              !scheduledNotification[PushNotificationCampaignKeys.isBroadcast] &&
              !!scheduledNotification?.[PushNotificationCampaignKeys.dynamicSegmentsIds]?.length
            "
            (onItemDeleted)="deleteSegment($event)"
          >
          </dx-list>

          <dx-toolbar class="notification-scheduler-edit-modal__recipients-toolbar">
            <dxi-item location="before" widget="dxButton" locateInMenu="never">
              <div>
                <div class="toolbar-label">
                  <b>Recipients</b>
                </div>
              </div>
            </dxi-item>

            <dxi-item location="after" locateInMenu="never">
              <div>
                <dx-button
                  [icon]="'plus'"
                  (onClick)="showAddRecipientsModal()"
                  [disabled]="
                    isReadOnly ||
                    scheduledNotification[PushNotificationCampaignKeys.isBroadcast] ||
                    (Entity.notificationsScheduler | hasPermission: EntityPermissionActivityKeys.write | async) ===
                      false
                  "
                ></dx-button>
              </div>
            </dxi-item>
          </dx-toolbar>

          <div class="notification-scheduler-edit-modal__list-container">
            <dx-list
              class="notification-scheduler-edit-modal__list"
              [dataSource]="
                scheduledNotification?.[PushNotificationCampaignKeys.recipients] | recipientsDataSource | async
              "
              [accessKey]="'dbId'"
              [displayExpr]="'p_email'"
              [searchEnabled]="
                !scheduledNotification[PushNotificationCampaignKeys.isBroadcast] &&
                !!scheduledNotification?.[PushNotificationCampaignKeys.recipients]?.length
              "
              [allowItemDeleting]="!isReadOnly"
              [noDataText]="'No Recipients Added'"
              [searchEditorOptions]="{ placeholder: 'Search Recipient' }"
              [searchExpr]="['p_email']"
              [height]="'auto'"
              (onItemDeleted)="deleteRecipient($event)"
            ></dx-list>
          </div>
        </ng-container>

        <ng-template #checkedRecipientsListTemp>
          <ag-shr-checked-recipients-grid
            #checkedRecipientsGridRef
            [scheduledNotification]="scheduledNotification"
          ></ag-shr-checked-recipients-grid>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ag-shr-modal-window>

<ag-shr-notification-add-recipients-modal
  #addRecipientsModalRef
  (handleAddRecipients)="handleAddRecipients($event)"
></ag-shr-notification-add-recipients-modal>

<ag-shr-notification-notification-channel-modal
  #notificationNotificationChannelModalRef
  (handleChannel)="handleAddChannel($event)"
></ag-shr-notification-notification-channel-modal>

<ag-shr-notification-add-segment-group-modal
  #notificationAddSegmentGroupModalRef
  (handleSegments)="handleSegments($event)"
></ag-shr-notification-add-segment-group-modal>

<ag-shr-notification-add-lists-modal
  #notificationAddListsModalRef
  (handleLists)="handleLists($event)"
></ag-shr-notification-add-lists-modal>
