<div class="agent-editor ag-editor">
  <ag-shr-agent-header
    [agentId]="selectedAgent[BaseModelKeys.dbId]"
    (onFieldsUpdated)="handleAgentFieldsChanges($event)"
  ></ag-shr-agent-header>

  <ng-template [ngIf]="detailsView === 'Agent'">
    <dx-tabs
      #editorTabs
      style="width: 70%"
      [items]="agentTabs | editorPermittedTabs | async"
      [selectedIndex]="selectedAgentIndex"
      (onItemClick)="selectAgentTab($event)"
      (onContentReady)="onContentReady($event)"
    >
      <div
        *dxTemplate="let item of 'templateCampaignsTab'"
        [class.campaigns-disabled]="!selectedAgent[AgentKeys.isActiveCampaigns]"
        (click)="onAllianceCampaignsClick($event)"
      >
        <i *ngIf="!selectedAgent[AgentKeys.isActiveCampaigns]" class="dx-icon-agi-lock"></i>
        Alliance Campaigns
      </div>
    </dx-tabs>

    <ng-template [ngIf]="selectedAgentTab === 'Contact Info'">
      <dx-scroll-view height="60%">
        <div class="row mt-2">
          <div class="col-6" *ngIf="Entity.agentContactInfoAddresses | hasPermission | async">
            <ag-shr-addresses
              [showPhysicalLocation]="false"
              [addresses]="selectedAgent?.addresses"
              [canEdit]="Entity.agentContactInfoAddresses | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="
                Entity.agentContactInfoAddresses | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentContactInfoAddresses | hasPermission: EntityPermissionActivityKeys.create | async
              "
              [updateAddress]="onSaveAddresses"
            ></ag-shr-addresses>

            <ag-shr-socials
              *ngIf="Entity.agentContactInfoSocials | hasPermission | async"
              [socials]="selectedAgent?.socials"
              [saveSocials]="onSaveSocial"
              [socialTypesLookup]="socialTypesLookup$ | async"
              [socialMediaLookup]="socialMediaLookup$ | async"
              [canEdit]="Entity.agentContactInfoSocials | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agentContactInfoSocials | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agentContactInfoSocials | hasPermission: EntityPermissionActivityKeys.create | async"
            ></ag-shr-socials>
          </div>
          <div class="col-6">
            <ag-shr-email-addresses
              *ngIf="Entity.agentContactInfoEmailAddresses | hasPermission | async"
              [agentId]="selectedAgent?.dbId"
              [agentUID]="selectedAgent?.[AgentKeys.uid]"
              [canEdit]="
                Entity.agentContactInfoEmailAddresses | hasPermission: EntityPermissionActivityKeys.write | async
              "
              [canDelete]="
                Entity.agentContactInfoEmailAddresses | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentContactInfoEmailAddresses | hasPermission: EntityPermissionActivityKeys.create | async
              "
            ></ag-shr-email-addresses>

            <ag-shr-phone-numbers
              *ngIf="Entity.agentContactInfoPhoneNumbers | hasPermission | async"
              [agentId]="selectedAgent?.dbId"
              [phoneNumbers]="selectedAgent?.phone_numbers"
              (phoneNumbersChange)="selectedAgent.phone_numbers = $event"
              [canEdit]="
                Entity.agentContactInfoPhoneNumbers | hasPermission: EntityPermissionActivityKeys.write | async
              "
              [canDelete]="
                Entity.agentContactInfoPhoneNumbers | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentContactInfoPhoneNumbers | hasPermission: EntityPermissionActivityKeys.create | async
              "
            ></ag-shr-phone-numbers>

            <ag-shr-websites
              *ngIf="Entity.agentContactInfoWebsites | hasPermission | async"
              [parentDbId]="selectedAgent?.dbId"
              [websites]="selectedAgent?.websites"
              [websiteTypesLookup]="websiteTypesLookup$ | async"
              [saveWebsites]="onSaveWebsites"
              [canEdit]="Entity.agentContactInfoWebsites | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agentContactInfoWebsites | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agentContactInfoWebsites | hasPermission: EntityPermissionActivityKeys.create | async"
            ></ag-shr-websites>
          </div>
        </div>
      </dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgentTab === 'Personal Info'">
      <dx-scroll-view height="60%">
        <div class="row mt-2">
          <div class="col-6" *ngIf="Entity.agentPersonalInformation | hasPermission | async">
            <ag-shr-personal-information
              [agent]="selectedAgent"
              (personalInformationChange)="handleAgentFieldsChanges($event)"
              [canEdit]="Entity.agentPersonalInformation | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agentPersonalInformation | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agentPersonalInformation | hasPermission: EntityPermissionActivityKeys.create | async"
            ></ag-shr-personal-information>
          </div>

          <div class="col-6" *ngIf="Entity.agentPersonalInfoSizesForFunStuff | hasPermission | async">
            <ag-shr-sizes
              [agent]="selectedAgent"
              [canEdit]="
                Entity.agentPersonalInfoSizesForFunStuff | hasPermission: EntityPermissionActivityKeys.write | async
              "
              [canDelete]="
                Entity.agentPersonalInfoSizesForFunStuff | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentPersonalInfoSizesForFunStuff | hasPermission: EntityPermissionActivityKeys.create | async
              "
            ></ag-shr-sizes>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12" *ngIf="Entity.agentPersonalInfoAssociations | hasPermission | async">
            <ag-shr-associations
              [agentId]="selectedAgent?.dbId"
              [canEdit]="
                Entity.agentPersonalInfoAssociations | hasPermission: EntityPermissionActivityKeys.write | async
              "
              [canDelete]="
                Entity.agentPersonalInfoAssociations | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentPersonalInfoAssociations | hasPermission: EntityPermissionActivityKeys.create | async
              "
            ></ag-shr-associations>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12" *ngIf="Entity.agentPersonalInfoDietaryConsiderations | hasPermission | async">
            <ag-shr-dietary-considerations
              [agent]="selectedAgent"
              (onFieldsUpdated)="handleAgentFieldsChanges($event)"
              [canEdit]="
                Entity.agentPersonalInfoDietaryConsiderations
                  | hasPermission: EntityPermissionActivityKeys.write
                  | async
              "
              [canDelete]="
                Entity.agentPersonalInfoDietaryConsiderations
                  | hasPermission: EntityPermissionActivityKeys.delete
                  | async
              "
              [canCreate]="
                Entity.agentPersonalInfoDietaryConsiderations
                  | hasPermission: EntityPermissionActivityKeys.create
                  | async
              "
            ></ag-shr-dietary-considerations>
          </div>
        </div>
      </dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgentTab === 'Agent Info'">
      <dx-scroll-view height="60%">
        <div class="row mt-2">
          <div class="col-6" *ngIf="Entity.agentAgentInfoAgentPortal | hasPermission | async">
            <ag-shr-portal
              [canEdit]="Entity.agentAgentInfoAgentPortal | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="
                Entity.agentAgentInfoAgentPortal | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentAgentInfoAgentPortal | hasPermission: EntityPermissionActivityKeys.create | async
              "
              [agent]="selectedAgent"
              (onFieldsUpdated)="handleAgentFieldsChanges($event)"
            ></ag-shr-portal>
          </div>

          <div class="col-6" *ngIf="Entity.agentAgentInfoMga | hasPermission | async">
            <ag-crm-mga [agent]="selectedAgent"></ag-crm-mga>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12" *ngIf="Entity.agentAgentInfoStatusActivity | hasPermission | async">
            <ag-shr-approve-deny-reasons
              [canEdit]="
                Entity.agentAgentInfoStatusActivity | hasPermission: EntityPermissionActivityKeys.write | async
              "
              [canDelete]="
                Entity.agentAgentInfoStatusActivity | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentAgentInfoStatusActivity | hasPermission: EntityPermissionActivityKeys.create | async
              "
              [agent]="selectedAgent"
            ></ag-shr-approve-deny-reasons>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12" *ngIf="Entity.agentAgentInfoNeedToKnow | hasPermission | async">
            <ag-shr-need-to-know
              [agent]="selectedAgent"
              [canEdit]="Entity.agentAgentInfoNeedToKnow | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agentAgentInfoNeedToKnow | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agentAgentInfoNeedToKnow | hasPermission: EntityPermissionActivityKeys.create | async"
            ></ag-shr-need-to-know>
          </div>
        </div>

        <div class="row mt-2" *ngIf="Entity.agentAgentInfoCarriers | hasPermission | async">
          <div class="col-12">
            <ag-shr-carriers
              [canEdit]="Entity.agentAgentInfoCarriers | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agentAgentInfoCarriers | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agentAgentInfoCarriers | hasPermission: EntityPermissionActivityKeys.create | async"
              [agent]="selectedAgent"
            ></ag-shr-carriers>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6" *ngIf="Entity.agentAgentInfoDepartmentOfInsuranceInfo | hasPermission | async">
            <ag-crm-doi [agent]="selectedAgent"></ag-crm-doi>
          </div>

          <div class="col-6" *ngIf="Entity.agentAgentInfoStatesLicensedIn | hasPermission | async">
            <ag-shr-licenses
              [agentId]="selectedAgent?.dbId"
              [licenses]="selectedAgent?.state_licenses"
              [canEdit]="
                Entity.agentAgentInfoStatesLicensedIn | hasPermission: EntityPermissionActivityKeys.write | async
              "
              [canDelete]="
                Entity.agentAgentInfoStatesLicensedIn | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agentAgentInfoStatesLicensedIn | hasPermission: EntityPermissionActivityKeys.create | async
              "
              (licensesChange)="selectedAgent.state_licenses = $event"
            >
            </ag-shr-licenses>
          </div>
        </div>

        <div
          class="row mt-2"
          *ngIf="selectedAgent[AgentKeys.isActiveCampaigns] && Entity.agentCampaignWizard | hasPermission | async"
        >
          <div class="col-6">
            <ag-shr-campaigns
              [agent]="selectedAgent"
              [canEdit]="Entity.agentCampaignWizard | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agentCampaignWizard | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agentCampaignWizard | hasPermission: EntityPermissionActivityKeys.create | async"
              (campaignsChange)="handleAgentFieldsChanges($event)"
            ></ag-shr-campaigns>
          </div>
        </div>
      </dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgentTab === 'Event Info'">
      <dx-scroll-view height="55%">Tab 4</dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgentTab === 'Permissions'">
      <ag-crm-permissions
        [agent]="selectedAgent"
        [canEdit]="Entity.agentPermissions | hasPermission: EntityPermissionActivityKeys.write | async"
        [canDelete]="Entity.agentPermissions | hasPermission: EntityPermissionActivityKeys.delete | async"
        [canCreate]="Entity.agentPermissions | hasPermission: EntityPermissionActivityKeys.create | async"
      ></ag-crm-permissions>
    </ng-template>

    <ng-template [ngIf]="selectedAgentTab === 'Alliance Campaigns'">
      <!-- TODO refactor markup to avoid fixed heights -->
      <div [style.height]="'70%'">
        <ag-shr-agent-campaign-wizard
          [withSupportButton]="false"
          [canEdit]="Entity.agentCampaignWizard | hasPermission: EntityPermissionActivityKeys.write | async"
          [canDelete]="Entity.agentCampaignWizard | hasPermission: EntityPermissionActivityKeys.delete | async"
          [canCreate]="Entity.agentCampaignWizard | hasPermission: EntityPermissionActivityKeys.create | async"
        ></ag-shr-agent-campaign-wizard>
      </div>
    </ng-template>

    <ng-template [ngIf]="selectedAgentTab === 'Media'">
      <div class="row">
        <div class="col">
          <ag-shr-agent-media
            [agentId]="selectedAgent?.dbId"
            [canEdit]="Entity.agentMedia | hasPermission: EntityPermissionActivityKeys.write | async"
            [canDelete]="Entity.agentMedia | hasPermission: EntityPermissionActivityKeys.delete | async"
            [canCreate]="Entity.agentMedia | hasPermission: EntityPermissionActivityKeys.create | async"
          ></ag-shr-agent-media>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template [ngIf]="detailsView === 'Prospect'">
    <dx-tabs
      style="width: 100%"
      [dataSource]="prospectTabs"
      [selectedIndex]="0"
      (onItemClick)="selectProspectTab($event)"
    >
    </dx-tabs>
    <ng-template [ngIf]="selectedProspectTab === 'Prospect'">
      <dx-scroll-view height="55%">
        <ag-crm-prospect [agent]="selectedAgent" [prospect]="selectedProspect"></ag-crm-prospect>
      </dx-scroll-view>
    </ng-template>
  </ng-template>

  <div class="row d-flex my-3" style="margin: 0 auto">
    <div class="col-6 text-start">
      <dx-button
        *ngIf="selectedProspect && detailsView === 'Agent'"
        width="200"
        stylingMode="contained"
        text="View Prospect"
        type="default"
        (onClick)="detailsView = 'Prospect'"
      ></dx-button>

      <dx-button
        *ngIf="detailsView === 'Prospect'"
        width="200"
        stylingMode="contained"
        text="View Agent"
        type="default"
        (onClick)="detailsView = 'Agent'"
      ></dx-button>
    </div>
  </div>
</div>
