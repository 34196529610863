<dx-data-grid
  #checkedRecipientsGridRef
  [dataSource]="agentDbIds | recipientsDataSourceByAgentDbIds | async"
  [showBorders]="true"
  [showRowLines]="true"
  [columnAutoWidth]="true"
  [scrollLeft]="true"
  [allowColumnReordering]="false"
  [allowColumnResizing]="true"
  [noDataText]="'No Recipients Exists'"
  width="100%"
  height="100%"
  [remoteOperations]="true"
  [syncLookupFilterValues]="false"
  (onContentReady)="getTotalCount($event)"
>
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <h5 class="toolbar-label fw-bold">Notification Receivers ({{ totalCount }})</h5>
      </div>
    </dxi-item>

    <dxi-item name="searchPanel" cssClass="ag-grid__toolbar-control"></dxi-item>
  </dxo-toolbar>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling mode="virtual" [showScrollbar]="'always'"></dxo-scrolling>

  <dxi-column
    *var="AgentKeys.p_agent_first_name as fieldName"
    [dataField]="fieldName"
    [caption]="fieldName | caption: 'agentsList'"
    dataType="string"
  ></dxi-column>

  <dxi-column
    *var="AgentKeys.p_agent_last_name as fieldName"
    [dataField]="fieldName"
    [caption]="fieldName | caption: 'agentsList'"
    dataType="string"
  ></dxi-column>

  <dxi-column
    *var="AgentKeys.p_email as fieldName"
    [dataField]="fieldName"
    [caption]="fieldName | caption: 'agentsList'"
    dataType="string"
  ></dxi-column>
</dx-data-grid>
