<p class="editor__data-grid--empty" *ngIf="!isEditable && !addresses?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    class="addresses-grid__grid"
    [class.addresses-grid__grid--view]="!isEditable"
    [dataSource]="addresses"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="isEditable"
    [noDataText]="emptyMessage"
    (onRowRemoving)="removeAddress.emit($event)"
    height="100%"
  >
    <dxo-toolbar [visible]="isEditable && hasPermissionsToAdd">
      <dxi-item location="after" widget="dxButton" [options]="{ icon: 'plus', onClick: handleAddAddress }"></dxi-item>
    </dxo-toolbar>

    <dxo-editing mode="popup" [useIcons]="true" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    </dxo-editing>

    <dxi-column
      [name]="'fullAddress'"
      [calculateDisplayValue]="fullAddressPipe.transform"
      [caption]="isEditable ? 'Address' : ''"
    >
    </dxi-column>
    <dxi-column [dataField]="AddressModelKeys.address1" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.address2" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.city" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.state" [visible]="false" editCellTemplate="stateEditorTmp">
      <dxo-lookup [dataSource]="statesLookup$ | async" [displayExpr]="'description'" [valueExpr]="'value'"></dxo-lookup>
    </dxi-column>
    <dxi-column [dataField]="AddressModelKeys.zip" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.country" [visible]="false">
      <dxo-lookup [dataSource]="countries" [displayExpr]="'name'" [valueExpr]="'code'"></dxo-lookup>
    </dxi-column>
    <dxi-column [dataField]="AddressModelKeys.county" [visible]="false"></dxi-column>

    <dxi-column [dataField]="AddressModelKeys.addressType" [caption]="isEditable ? 'Type' : ''">
      <dxo-lookup
        [dataSource]="typesLookup"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="'description'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [visible]="showPhysicalLocation"
      [dataField]="AddressModelKeys.isPhysicalLocation"
      caption="Physical Location"
      width="160"
      cellTemplate="checkboxCellTemplate"
    ></dxi-column>
    <dxi-column
      [visible]="showShippingAddress"
      [dataField]="AddressModelKeys.isPrimaryShipping"
      caption="Shipping"
      width="80"
      cellTemplate="checkboxCellTemplate"
    ></dxi-column>
    <dxi-column
      [visible]="showBillingAddress"
      [dataField]="AddressModelKeys.isPrimaryBilling"
      caption="Billing"
      width="80"
      cellTemplate="checkboxCellTemplate"
    ></dxi-column>

    <div *dxTemplate="let cell of 'checkboxCellTemplate'">
      <i *ngIf="!!cell.value" class="dx-icon-check"></i>
    </div>

    <dxi-column
      [dataField]="AddressModelKeys.validationResponse"
      [caption]="isEditable ? 'Validation Status' : 'Validated'"
      [alignment]="'center'"
      [width]="isEditable ? 160 : 80"
      cellTemplate="validationStatusCellTemplate"
    >
      <div *dxTemplate="let cell of 'validationStatusCellTemplate'">
        <ng-container *ngIf="cell.value?.result?.verdict as verdict; else notValidated">
          <i
            *ngIf="!!verdict?.addressComplete && !verdict?.hasUnconfirmedComponents"
            class="dx-icon-check addresses-grid__validation-status addresses-grid__validation-status--success"
            title="Valid"
          ></i>

          <i
            *ngIf="!verdict?.addressComplete || verdict?.hasUnconfirmedComponents"
            class="dx-icon-warning addresses-grid__validation-status addresses-grid__validation-status--warning"
            [title]="verdict?.addressComplete ? 'Validated, Not Completed' : 'Validated, Has Unconfirmed Data'"
          ></i>
        </ng-container>

        <ng-template #notValidated>
          <i
            class="dx-icon dx-icon-agi-ban addresses-grid__validation-status addresses-grid__validation-status--failed"
            title="Not Validated"
          ></i>
        </ng-template>
      </div>
    </dxi-column>

    <dxi-column type="buttons" [visible]="isEditable">
      <dxi-button
        name="customEdit"
        icon="edit"
        [visible]="hasPermissionsToEdit"
        [onClick]="handleEditAddress"
      ></dxi-button>
      <dxi-button name="delete" [visible]="hasPermissionsToDelete && canDeleteRow"></dxi-button>
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'stateEditorTmp'">
      <ag-shr-state-select-box [value]="cell.value" (valueChange)="cell.setValue($event)"> </ag-shr-state-select-box>
    </div>
  </dx-data-grid>
</ng-template>
