import { LookupKeys } from 'ag-common-lib/public-api';
import { BaseModel, BaseModelKeys } from '../base.model';
import { FilterGroup } from './filter-builder.model';

export const DELIVERED_PUSH_NOTIFICATIONS_COLLECTION = 'delivered-push-notifications';
export const PUSH_NOTIFICATIONS_CAMPAIGN_COLLECTION = 'push-notifications-campaign';

export enum NotificationOptInGroupType {
  agent = 'agent',
  registrant = 'registrant',
}

export const notificationOptInGroupTypeMap = new Map([
  [NotificationOptInGroupType.agent, 'Agents'],
  [NotificationOptInGroupType.registrant, 'Registrants'],
]);

export const notificationOptInGroupTypeLookup = [
  NotificationOptInGroupType.agent,
  NotificationOptInGroupType.registrant,
].map(type => ({
  [LookupKeys.value]: type,
  [LookupKeys.description]: notificationOptInGroupTypeMap.get(type),
}));

export enum NotificationOptInGroupKeys {
  type = 'type',
  payload = 'payload',
  filterExpression = 'filterExpression',
  conferenceDbId = 'conferenceDbId',
}

export class NotificationOptInGroupBasePayload {}

export class NotificationOptInGroupAgentPayload extends NotificationOptInGroupBasePayload {}

export class NotificationOptInGroupRegistrantPayload extends NotificationOptInGroupBasePayload {
  [NotificationOptInGroupKeys.conferenceDbId]: string;
}

type NotificationOptInGroupPayloadMap = {
  [NotificationOptInGroupType.agent]: NotificationOptInGroupAgentPayload;
  [NotificationOptInGroupType.registrant]: NotificationOptInGroupRegistrantPayload;
};

type NotificationOptInGroupPayload<T extends NotificationOptInGroupType> = NotificationOptInGroupPayloadMap[T];

export class NotificationOptInGroup<T extends NotificationOptInGroupType = NotificationOptInGroupType> {
  [NotificationOptInGroupKeys.type]: T;
  [NotificationOptInGroupKeys.filterExpression]?: FilterGroup;
  [NotificationOptInGroupKeys.payload]: NotificationOptInGroupPayload<T> = {} as NotificationOptInGroupPayload<T>;

  constructor(type: T) {
    this[NotificationOptInGroupKeys.type] = type;
  }
}

export enum PushNotificationCampaignKeys {
  description = 'description',
  notification = 'notification',
  title = 'title',
  data = 'data',
  isBroadcast = 'isBroadcast',
  channels = 'channels',
  recipients = 'recipients',
  optInGroups = 'optInGroups',
  deliverAt = 'deliverAt',
  dynamicListsIds = 'dynamicListsIds',
  dynamicSegmentsIds = 'dynamicSegmentsIds',
}

export enum PushNotificationKeys {
  type = 'type',
  title = 'title',
  body = 'body',
  pushNotificationDbId = 'pushNotificationDbId',
}

export enum PushNotificationType {
  message = 'message',
  system = 'system',
}

export class PushNotification {
  [PushNotificationKeys.type]: PushNotificationType;
  [PushNotificationKeys.title]: string;
  [PushNotificationKeys.body]: string;
  [PushNotificationKeys.pushNotificationDbId]: string;
}

export class PushNotificationCampaign extends BaseModel {
  [PushNotificationCampaignKeys.title]?: string;
  [PushNotificationCampaignKeys.description]?: string;
  [PushNotificationCampaignKeys.deliverAt]?: Date;
  [PushNotificationCampaignKeys.notification]?: PushNotification;
  [PushNotificationCampaignKeys.isBroadcast]?: boolean = false;
  [PushNotificationCampaignKeys.recipients]?: string[] = [];
  [PushNotificationCampaignKeys.channels]?: string[] = [];
  [PushNotificationCampaignKeys.optInGroups]?: NotificationOptInGroup<NotificationOptInGroupType>[] = [];
  [PushNotificationCampaignKeys.dynamicListsIds]?: string[] = [];
  [PushNotificationCampaignKeys.dynamicSegmentsIds]?: string[] = [];
}

export enum DeliveredPushNotificationKeys {
  agentDbId = 'agentDbId',
  isArchived = 'isArchived',
  isRead = 'isRead',
  readDateTime = 'readDateTime',
}

export class DeliveredPushNotification extends BaseModel {
  [DeliveredPushNotificationKeys.agentDbId]: string;
  [DeliveredPushNotificationKeys.isArchived]: boolean = false;
  [DeliveredPushNotificationKeys.isRead]: boolean = false;
  [DeliveredPushNotificationKeys.readDateTime]: Date = null;
  [PushNotificationCampaignKeys.description]: string;
  [PushNotificationCampaignKeys.notification]: PushNotification;

  constructor() {
    super();

    this[BaseModelKeys.createdDate] = new Date();
  }
}
