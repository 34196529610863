import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import {
  AgencyService,
  AgentGridConfigurationKeys,
  AgentGridsConfigurationsService,
  AgentService,
  AuthService,
  GridIds,
  LookupsService,
} from 'ag-common-svc/public-api';
import {
  AddressModelKeys,
  AG_APPLICATIONS,
  AGENCY_TYPE,
  AgencyKeys,
  Agent,
  AGENT_TYPE_LOOKUP,
  AgentKeys,
  ApproveDenyReasonVisibilityLevel,
  BaseModelKeys,
  Constants,
  ElasticSearchAgent,
  EmailAddressKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Lookup,
  LookupKeys,
  Messages,
  PhoneNumberKeys,
  PROSPECT_STATUS_LOOKUP,
  StorageKeys,
  TRUE_FALSE_LOOKUP,
  UserPermission,
  UserPermissionKeys,
} from 'ag-common-lib/public-api';
import { SessionControllerService } from 'src/app/shared/services/util/session-controller.service';
import { AgentsRoutes } from 'src/app/ag-crm/agents/agents.model';
import { PortalDataService } from 'ag-common-svc/lib/services/portal.service';
import { ToastrService } from 'ngx-toastr';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DeleteAgentModalComponent } from 'ag-common-svc/lib/components/delete-agent-modal/delete-agent-modal.component';
import { CreateAgentModalComponent } from 'ag-common-svc/lib/components/create-agent-modal/create-agent-modal.component';
import { AgentSalesGoalsModalComponent } from 'ag-common-svc/lib/components/agent-sales-goals-modal/agent-sales-goals-modal.component';
import { AddAgentToDynamicListModalComponent } from 'ag-common-svc/lib/components/add-agent-to-dynamic-list-modal/add-agent-to-dynamic-list-modal.component';
import { BehaviorSubject, filter, firstValueFrom, map, mergeMap, Observable, of, shareReplay, take } from 'rxjs';
import { CRMRole } from 'ag-common-lib/lib/lists/crm-roles.enum';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { AGENTS_GRID_TITLE_LIST } from './config/agent-grid.config';
import { AGENT_STATUSES_LOOKUP } from 'ag-common-lib/lib/lists/agent-status.list';
import { Cell, Workbook } from 'exceljs';
import { DataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { PhoneNumberMaskPipe } from 'ag-common-svc/shared/pipes/phone-number-mask.pipe';
import { FullAddressPipe } from 'ag-common-svc/shared/pipes/full-address.pipe';
import { PrimaryPhoneNumberPipe } from 'ag-common-svc/shared/pipes/primary-phone-number.pipe';
import { RowClickEvent, RowInsertingEvent, RowRemovingEvent } from 'devextreme/ui/data_grid';
import { ButtonClickEvent } from 'devextreme/ui/drop_down_button';

export enum AgentGridExportType {
  default = 'default',
  split = 'split',
  christmasCard = 'christmasCard',
  conferencePoster = 'conferencePoster',
}

export enum DataSourceTypeType {
  default = 'default',
  export = 'export',
}

@Component({
  selector: 'ag-crm-agent-grid',
  templateUrl: './agent-grid.component.html',
  styleUrls: ['./agent-grid.component.scss'],
  providers: [PhoneNumberMaskPipe, PrimaryPhoneNumberPipe, FullAddressPipe, AgentGridsConfigurationsService],
})
export class AgentGridComponent implements OnInit {
  @HostBinding('class') className = 'agent-grid';
  @ViewChild('agentgrid') agentGrid: DxDataGridComponent;

  @ViewChild('approveDenyReasonModalRef') approveDenyReasonsModalComponent: ModalWindowComponent;
  @ViewChild('deleteAgentModelRef') deleteAgentModelComponent: DeleteAgentModalComponent;
  @ViewChild('addAgentToDynamicListModalRef', { static: true })
  addAgentToDynamicListModalComponent: AddAgentToDynamicListModalComponent;
  @ViewChild('createAgentModelRef') createAgentModelComponent: CreateAgentModalComponent;
  @ViewChild('salesGoalsModalRef', { static: true }) salesGoalsModalComponent: AgentSalesGoalsModalComponent;
  @ViewChild('configurationSelectBoxRef', { static: false }) configurationSelectBoxComponent: DxSelectBoxComponent;

  ApproveDenyReasonVisibilityLevel = ApproveDenyReasonVisibilityLevel;
  selectedAgentId: string;

  dataSource: CustomStore;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly agencies$ = this.agencyService.getList().pipe(shareReplay(1));
  protected readonly mgaList$ = this.agencies$.pipe(
    map(agencies => agencies?.filter(agency => agency?.[AgencyKeys.agencyType] === AGENCY_TYPE.MGA)),
  );

  protected readonly agentTypes: Partial<Lookup>[] = AGENT_TYPE_LOOKUP;
  protected readonly prospectStatuses: Partial<Lookup>[] = PROSPECT_STATUS_LOOKUP;
  protected readonly agentStatuses: Partial<Lookup>[] = AGENT_STATUSES_LOOKUP;
  protected readonly StorageKeys = StorageKeys;
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;

  prefixesLookup$ = this.lookupsService.prefixesLookup$;
  suffixesLookup$ = this.lookupsService.suffixesLookup$;
  dietaryConsiderationTypesLookup$ = this.lookupsService.dietaryConsiderationTypesLookup$;
  tShortSizesLookup$ = this.lookupsService.tShortSizesLookup$;
  gendersLookup$ = this.lookupsService.gendersLookup$;

  readonly isAdminUser$: Observable<boolean>;

  readonly Messages = Messages;
  readonly phoneMask: string = Constants.PHONE_MASK;
  readonly BaseModelKeys = BaseModelKeys;
  readonly AddressModelKeys = AddressModelKeys;
  readonly AgentGridConfigurationKeys = AgentGridConfigurationKeys;
  readonly AgentKeys = AgentKeys;
  readonly LookupKeys = LookupKeys;
  readonly TRUE_FALSE_LOOKUP = TRUE_FALSE_LOOKUP;
  readonly filterPanelText$ = new BehaviorSubject('');
  protected readonly titleList = AGENTS_GRID_TITLE_LIST;
  protected exportOptions = [
    {
      value: AgentGridExportType.default,
      description: 'Default',
      onClick: () => this.handleExcelExport.call(this, AgentGridExportType.default),
    },
    {
      value: AgentGridExportType.split,
      description: 'Split',
      onClick: () => this.handleExcelExport.call(this, AgentGridExportType.split),
    },
    {
      value: AgentGridExportType.christmasCard,
      description: 'Christmas Card',
      onClick: () => this.christmasCardReportExport.call(this),
    },
    {
      value: AgentGridExportType.conferencePoster,
      description: 'Conference Poster',
      onClick: () => this.conferencePostersReportExport.call(this),
    },
  ];

  protected gridConfigurations$ = this.authService.loggedInAgent$.pipe(
    map(agent => agent?.[BaseModelKeys.dbId]),
    filter(Boolean),
    mergeMap(agentDbId => this.agentGridsConfigurationsService.getList(agentDbId, GridIds.agents)),
    shareReplay(1),
  );

  protected selectedConfiguration;

  private _cache$ = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private agencyService: AgencyService,
    private session: SessionControllerService,
    private portalDataService: PortalDataService,
    private toster: ToastrService,
    private lookupsService: LookupsService,
    private phoneNumberMaskPipe: PhoneNumberMaskPipe,
    private primaryPhoneNumberPipe: PrimaryPhoneNumberPipe,
    private fullAddressPipe: FullAddressPipe,
    private agentService: AgentService,
    private agentElasticSearchService: AgentElasticSearchService,
    private agentGridsConfigurationsService: AgentGridsConfigurationsService,
  ) {
    this.dataSource = new CustomStore({
      key: BaseModelKeys.dbId,
      load: loadOptions => {
        return firstValueFrom(
          this._cache$.pipe(
            take(1),
            mergeMap(cache => {
              if (cache) {
                this._cache$.next(null);
                return of(cache);
              }
              const isLoadingAll = (loadOptions as any)?.isLoadingAll;

              return agentElasticSearchService.getFromElastic(loadOptions, isLoadingAll);
            }),
          ),
        );
      },
    });

    // TODO: temporary solutions, need to delete and use permissions service to get permission for delete and key actions
    this.isAdminUser$ = this.authService.userPermissions$.pipe(
      map((userPermissions: UserPermission[]) => {
        const armPermission = userPermissions?.find(
          permission => permission[UserPermissionKeys.application] === AG_APPLICATIONS.ARM,
        );
        return armPermission
          ? armPermission[UserPermissionKeys.isEnabled] &&
              !!armPermission[UserPermissionKeys.role]?.find(role => role === CRMRole.ADMIN)
          : false;
      }),
    );
  }

  ngOnInit(): void {
    this.session.agentFilter = [];
  }

  protected reloadGrid = async () => {
    setTimeout(() => this.agentGrid.instance.refresh(), 500);
  };

  protected onAgentDeleted = async (agent: Agent) => {
    await this.dataSource.push([{ type: 'remove', key: agent?.[BaseModelKeys.dbId] }]);
  };

  protected onOptionChanged = e => {
    if (e.name === 'searchPanel') {
      this.filterPanelText$.next(e.value);
    }
  };

  protected showSalesGoal = ({ row: { data: agent } }): void => {
    this.salesGoalsModalComponent.showModal(agent[BaseModelKeys.dbId]);
  };

  protected showAgentStatusActivities = ({ row: { data: agent } }): void => {
    this.selectedAgentId = agent?.dbId;
    this.approveDenyReasonsModalComponent.showModal();
  };

  protected addAgent = (): void => {
    this.createAgentModelComponent.showPopup();
  };

  protected handleDeleteAgent = e => {
    const agent = e.row.data;
    this.deleteAgentModelComponent.showPopup(agent);
  };

  protected showContactInfo(e): void {
    this.session.selectAgent(e.data);
    this.router.navigate(['..', AgentsRoutes.AgentManagement], { relativeTo: this.route });
  }

  protected addAgentToDynamicList = e => {
    const agent = e.row.data;
    this.addAgentToDynamicListModalComponent.showPopup(agent);
  };

  protected generateAgentId = (e): void => {
    if (!e.row.data.p_agent_id) {
      this.portalDataService
        .getNextAgentId()
        .then(id => {
          e.row.data.p_agent_id = id;
          this.dataSource
            .update(e.row.data.dbId, e.row.data)
            .then(() => {
              this.toster.success('Agent ID successfully created!');
            })
            .catch(error => {
              console.error('Error in Agent Service.', error);
            });
        })
        .catch(error => {
          console.error('Error in Agent Admin.', error);
        });
    } else {
      this.toster.error('Can not create an ID for an Agent with a current ID!');
    }
  };

  protected calculatePhoneNumbersDisplayValue = (agent: ElasticSearchAgent) => {
    return agent[AgentKeys.phone_numbers]
      ?.map(phoneNumber => {
        let formattedNumber = this.phoneNumberMaskPipe.transform(phoneNumber, true);

        if (phoneNumber[PhoneNumberKeys.is_primary]) {
          formattedNumber += ' (prim)';
        }
        return formattedNumber;
      })
      .filter(Boolean)
      .join('; ');
  };

  protected calculateAddressDisplayValue = (agent: ElasticSearchAgent) => {
    return agent[AgentKeys.addresses]
      ?.map(address => {
        let formattedNumber = this.fullAddressPipe.transform(address);

        if (address?.[AddressModelKeys.isPrimaryBilling]) {
          formattedNumber += ' $';
        }
        if (address?.[AddressModelKeys.isPrimaryShipping]) {
          formattedNumber += ' (shipping)';
        }
        return formattedNumber;
      })
      .filter(Boolean)
      .join('; ');
  };

  protected applyConfiguration = (e: RowClickEvent) => {
    this.selectedConfiguration = [e.data?.[BaseModelKeys.dbId]];

    if (!e.data) {
      return;
    }
    this.agentGrid.instance.state(JSON.parse(e.data?.configuration));
  };

  protected saveConfigurationUpdates = async (e: ButtonClickEvent) => {
    const agentDbId = await firstValueFrom(
      this.authService.loggedInAgent$.pipe(map(agent => agent?.[BaseModelKeys.dbId])),
    );
    const configurationDbId = this.selectedConfiguration[0];
    const state = this.agentGrid.instance.state();

    await this.agentGridsConfigurationsService.update(agentDbId, configurationDbId, {
      [AgentGridConfigurationKeys.configuration]: JSON.stringify(state),
    });
  };

  protected addConfiguration = async (e: RowInsertingEvent) => {
    const agentDbId = await firstValueFrom(
      this.authService.loggedInAgent$.pipe(map(agent => agent?.[BaseModelKeys.dbId])),
    );
    const state = this.agentGrid.instance.state();
    const name = e.data?.[AgentGridConfigurationKeys.name];

    e.cancel = true;

    e.component.beginCustomLoading('Loading');

    await this.agentGridsConfigurationsService.create(agentDbId, {
      [AgentGridConfigurationKeys.agentDbId]: agentDbId,
      [AgentGridConfigurationKeys.name]: name,
      [AgentGridConfigurationKeys.gridId]: GridIds.agents,
      [AgentGridConfigurationKeys.configuration]: JSON.stringify(state),
    });

    e.component.endCustomLoading();
  };

  protected removeConfiguration = (e: RowRemovingEvent) => {
    const data = e.data;
    const agentDbId = data?.[AgentGridConfigurationKeys.agentDbId];
    const configurationDbId = data?.[BaseModelKeys.dbId];

    const selectedConfigurationDbId = this.selectedConfiguration?.[0];
    if (configurationDbId === selectedConfigurationDbId) {
      this.selectedConfiguration = [];
    }

    e.cancel = this.agentGridsConfigurationsService.delete(agentDbId, configurationDbId);
  };

  protected async handleExcelExport(exportType: AgentGridExportType) {
    this.agentGrid.instance.beginCustomLoading('Exporting...');
    this.agentGrid.instance.beginUpdate();

    const changes = [];

    try {
      const sourceState = this.agentGrid.instance.state();
      const response = await this.getDataForExport(exportType, sourceState?.filterValue);
      const { data, stats } = this.parseResponse(response?.data, exportType);
      this._cache$.next({ data, totalCount: data?.length });

      const emailsCleanUp = this.normalizeEmailAddressesForExport(exportType, stats.emailsMax);
      const addressCleanUp = this.normalizeAddressesForExport(exportType, stats.addressesMax);
      const phoneNumbersCleanUp = this.normalizePhoneNumberForExport(exportType, stats.phoneNumbersMax);

      changes.push(emailsCleanUp, addressCleanUp, phoneNumbersCleanUp);

      await this.exportExcel(exportType);
    } catch (error) {
      // this.agentGrid.instance.option('dataSource', this.dataSource);
    }

    changes.forEach(callback => callback && callback());

    this.agentGrid.instance.endUpdate();
    this.agentGrid.instance.endCustomLoading();
  }

  protected async christmasCardReportExport() {
    const gridInstance = this.agentGrid.instance;
    gridInstance.beginCustomLoading('Exporting...');
    gridInstance.beginUpdate();
    const sourceState = gridInstance.state();
    const changes = [];
    const columnsStateSnapshot = structuredClone(sourceState.columns);

    try {
      const data = await this.agentService.getAgentForChristmasCardList();
      this._cache$.next({ data, totalCount: data?.length });

      columnsStateSnapshot?.forEach(column => {
        if (`${column.name}`.startsWith('shippingAddress')) {
          gridInstance.columnOption(column.name, { visible: true });
          return;
        }

        gridInstance.columnOption(column.name, 'visible', false);
      });

      [
        AgentKeys.email_addresses,
        AgentKeys.phone_numbers,
        AgentKeys.p_mga_id,
        AgentKeys.p_agency_id,
        AgentKeys.p_agent_first_name,
        AgentKeys.p_agent_last_name,
        'shippingAddress',
      ].forEach((name, index) => {
        gridInstance.columnOption(name, { visible: true, visibleIndex: index + 1 });
      });

      const emailsCleanUp = this.normalizeEmailAddressesForExport(AgentGridExportType.christmasCard);
      const phoneNumbersCleanUp = this.normalizePhoneNumberForExport(AgentGridExportType.christmasCard);

      changes.push(emailsCleanUp, phoneNumbersCleanUp);

      await this.exportExcel(AgentGridExportType.christmasCard);
    } catch (error) {}

    changes.forEach(callback => callback && callback());
    gridInstance.state({ columns: columnsStateSnapshot });

    this.agentGrid.instance.endUpdate();
    this.agentGrid.instance.refresh();
    this.agentGrid.instance.endCustomLoading();
  }

  protected async conferencePostersReportExport() {
    const gridInstance = this.agentGrid.instance;
    gridInstance.beginCustomLoading('Exporting...');
    gridInstance.beginUpdate();
    const sourceState = gridInstance.state();
    const changes = [];
    const columnsStateSnapshot = structuredClone(sourceState.columns);

    try {
      const data = await this.agentService.getAgentForConferencePosterList();
      this._cache$.next({ data, totalCount: data?.length });

      columnsStateSnapshot?.forEach(column => {
        if (`${column.name}`.startsWith('shippingAddress')) {
          gridInstance.columnOption(column.name, { visible: true });
          return;
        }

        gridInstance.columnOption(column.name, 'visible', false);
      });

      [
        AgentKeys.email_addresses,
        AgentKeys.phone_numbers,
        AgentKeys.p_mga_id,
        AgentKeys.p_agency_id,
        AgentKeys.p_agent_first_name,
        AgentKeys.p_agent_last_name,
        'shippingAddress',
      ].forEach((name, index) => {
        gridInstance.columnOption(name, { visible: true, visibleIndex: index + 1 });
      });

      const emailsCleanUp = this.normalizeEmailAddressesForExport(AgentGridExportType.conferencePoster);
      const phoneNumbersCleanUp = this.normalizePhoneNumberForExport(AgentGridExportType.conferencePoster);

      changes.push(emailsCleanUp, phoneNumbersCleanUp);

      await this.exportExcel(AgentGridExportType.conferencePoster);
    } catch (error) {}

    changes.forEach(callback => callback && callback());
    gridInstance.state({ columns: columnsStateSnapshot });

    this.agentGrid.instance.endUpdate();
    this.agentGrid.instance.refresh();
    this.agentGrid.instance.endCustomLoading();
  }

  private getDataForExport = (exportType: AgentGridExportType, filter) => {
    switch (exportType) {
      case AgentGridExportType.split:
      default:
        return this.agentElasticSearchService.getFromElastic({ filter }, true);
    }
  };

  private normalizeEmailAddressesForExport = (exportType: AgentGridExportType, emailsMax = 1): void | Function => {
    const gridInstance = this.agentGrid.instance;
    const emailAddressesColumn = gridInstance.columnOption(AgentKeys.email_addresses);
    const isVisible = emailAddressesColumn.visible;

    if (!isVisible) {
      return;
    }

    const name = `${AgentKeys.email_addresses}${Date.now()}`;
    let updatedEmailAddressesColumn;

    switch (exportType) {
      case AgentGridExportType.default:
        updatedEmailAddressesColumn = Object.assign({}, emailAddressesColumn, {
          name,
          columns: new Array(emailsMax).fill(null).map((_, index) => {
            return {
              caption: `Email ${index + 1}`,
              columns: [
                {
                  caption: 'Address',
                  calculateCellValue: row => {
                    const emailAddress = row?.[AgentKeys.email_addresses]?.[index];

                    return emailAddress?.[EmailAddressKeys.address];
                  },
                },
                {
                  caption: 'Is Login',
                  calculateCellValue: row => {
                    const emailAddress = row?.[AgentKeys.email_addresses]?.[index];

                    return emailAddress?.[EmailAddressKeys.isLogin] ? 'Yes' : 'No';
                  },
                },
              ],
            };
          }),
        });
        break;

      case AgentGridExportType.split:
        updatedEmailAddressesColumn = Object.assign({}, emailAddressesColumn, {
          name,
          columns: [
            {
              name: 'primaryEmail',
              caption: `Primary Email`,
              calculateCellValue: row => {
                return row?.primaryEmail;
              },
            },
            {
              name: 'emailAddress',
              caption: `Email Address`,
              calculateCellValue: row => {
                return row?.emailAddress;
              },
            },
          ],
        });
        break;

      case AgentGridExportType.conferencePoster:
      case AgentGridExportType.christmasCard:
        updatedEmailAddressesColumn = Object.assign({}, emailAddressesColumn, {
          name,
          caption: `Email Address`,

          calculateCellValue: row => {
            return row?.[AgentKeys.p_email];
          },
        });

        break;

      default:
        return null;
    }

    gridInstance.addColumn(updatedEmailAddressesColumn);

    gridInstance.columnOption(AgentKeys.email_addresses, 'visible', false);

    return () => {
      gridInstance.columnOption(AgentKeys.email_addresses, 'visible', true);
      gridInstance.deleteColumn(name);
    };
  };

  private normalizeAddressesForExport = (exportType: AgentGridExportType, addressesMax = 1): void | Function => {
    const gridInstance = this.agentGrid.instance;
    const addressesColumn = gridInstance.columnOption(AgentKeys.addresses);
    const isVisible = addressesColumn.visible;

    if (!isVisible) {
      return;
    }
    const name = `${AgentKeys.addresses}${Date.now()}`;
    const updatedAddressesColumn = Object.assign({}, addressesColumn, {
      name,
      columns: new Array(addressesMax).fill(null).map((_, index) => {
        return {
          caption: `Address ${index + 1}`,
          calculateCellValue: row => {
            const address = row?.[AgentKeys.addresses]?.[index];

            return this.fullAddressPipe.transform(address);
          },
        };
      }),
    });
    gridInstance.addColumn(updatedAddressesColumn);
    gridInstance.columnOption(AgentKeys.addresses, 'visible', false);

    return () => {
      gridInstance.columnOption(AgentKeys.addresses, 'visible', true);
      gridInstance.deleteColumn(name);
    };
  };

  private normalizePhoneNumberForExport = (exportType: AgentGridExportType, phoneNumbersMax = 1): void | Function => {
    const gridInstance = this.agentGrid.instance;
    const phoneNumbersColumn = gridInstance.columnOption(AgentKeys.phone_numbers);
    const isVisible = phoneNumbersColumn.visible;

    if (!isVisible) {
      return;
    }
    const name = `${AgentKeys.phone_numbers}${Date.now()}`;
    let updatedPhoneNumbersColumn;

    switch (exportType) {
      case AgentGridExportType.conferencePoster:
      case AgentGridExportType.christmasCard:
        updatedPhoneNumbersColumn = Object.assign({}, phoneNumbersColumn, {
          name,
          caption: `Primary Phone`,

          calculateCellValue: row => {
            const primaryPhoneNumber = this.primaryPhoneNumberPipe.transform(row[AgentKeys.phone_numbers]) ?? null;
            return this.phoneNumberMaskPipe.transform(primaryPhoneNumber, true);
          },
        });

        break;

      default:
        updatedPhoneNumbersColumn = Object.assign({}, phoneNumbersColumn, {
          name,
          columns: new Array(phoneNumbersMax).fill(null).map((_, index) => {
            return {
              caption: `Number ${index + 1}`,
              columns: [
                {
                  caption: 'Number',
                  calculateCellValue: row => {
                    const phoneNumber = row?.[AgentKeys.phone_numbers]?.[index];

                    return this.phoneNumberMaskPipe.transform(phoneNumber, true);
                  },
                },
                {
                  caption: 'Is Primary',
                  calculateCellValue: row => {
                    const phoneNumber = row?.[AgentKeys.phone_numbers]?.[index];

                    return phoneNumber?.[PhoneNumberKeys.is_primary] ? 'Yes' : 'No';
                  },
                },
                {
                  caption: 'Is WhatsApp',
                  calculateCellValue: row => {
                    const phoneNumber = row?.[AgentKeys.phone_numbers]?.[index];

                    return phoneNumber?.[PhoneNumberKeys.is_whatsapp] ? 'Yes' : 'No';
                  },
                },
              ],
            };
          }),
        });
    }

    gridInstance.addColumn(updatedPhoneNumbersColumn);
    gridInstance.columnOption(AgentKeys.phone_numbers, 'visible', false);

    return () => {
      gridInstance.columnOption(AgentKeys.phone_numbers, 'visible', true);
      gridInstance.deleteColumn(name);
    };
  };

  private parseResponse = (agents: ElasticSearchAgent[], exportType: AgentGridExportType) => {
    const stats = {
      emailsMax: 0,
      phoneNumbersMax: 0,
      addressesMax: 0,
    };

    const data = [];

    agents?.forEach((agent: ElasticSearchAgent) => {
      const emailAddresses = agent?.[AgentKeys.email_addresses];
      const addresses = agent?.[AgentKeys.addresses];
      const phoneNumbers = agent?.[AgentKeys.phone_numbers];

      stats.emailsMax = Math.max(stats.emailsMax, emailAddresses?.length ?? 0);
      stats.phoneNumbersMax = Math.max(stats.phoneNumbersMax, phoneNumbers?.length ?? 0);
      stats.addressesMax = Math.max(stats.addressesMax, addresses?.length ?? 0);

      if (exportType === AgentGridExportType.default || !emailAddresses?.length) {
        data.push(agent);
        return;
      }

      const loginAddressesSet = new Set<string>();
      const addressesSet = new Set<string>();

      emailAddresses?.forEach(emailAddress => {
        const isLogin = emailAddress?.[EmailAddressKeys.isLogin];
        const address = emailAddress?.[EmailAddressKeys.address]?.trim()?.toLocaleLowerCase();
        if (isLogin) {
          loginAddressesSet.add(address);
          return;
        }
        addressesSet.add(address);
      });

      const loginEmailAddress = Array.from(loginAddressesSet)[0];

      data.push(
        Object.assign({}, agent, {
          primaryEmail: loginEmailAddress,
          emailAddress: loginEmailAddress,
        }),
      );

      for (const addresses of addressesSet.values()) {
        data.push(
          Object.assign({}, agent, {
            primaryEmail: loginEmailAddress,
            emailAddress: addresses,
          }),
        );
      }
    });

    return { data, stats };
  };

  private getWorkbook = async (exportType: AgentGridExportType) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Agents');

    await exportDataGrid({
      component: this.agentGrid.instance,
      worksheet,
      autoFilterEnabled: true,

      customizeCell: ({ gridCell, excelCell }: { gridCell: DataGridCell; excelCell: Cell }) => {
        if (gridCell.rowType === 'header') {
          const column = excelCell.worksheet.getColumn(excelCell.col);
          const columnName = gridCell.column.name;

          column.key = columnName;
        }
      },
    });

    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const contentLength = cell.value ? cell.value.toString().length : 0;
        if (contentLength > maxLength) {
          maxLength = contentLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength; // Set a minimum width
    });

    switch (exportType) {
      case AgentGridExportType.split:
        worksheet.eachRow((row, index) => {
          const primaryEmailCell = row.getCell('primaryEmail');
          const emailAddressCell = row.getCell('emailAddress');
          if (primaryEmailCell.value !== emailAddressCell?.value) {
            row.outlineLevel = 1;
          }
        });
        break;
    }

    return workbook;
  };

  private exportExcel = async (exportType: AgentGridExportType) => {
    const workbook = await this.getWorkbook(exportType);
    const buffer = await workbook.xlsx.writeBuffer();
    const fileName = new Map([
      [AgentGridExportType.default, `Agents ${new Date().toLocaleDateString()}.xlsx`],
      [AgentGridExportType.split, `Agents ${new Date().toLocaleDateString()}.xlsx`],
      [AgentGridExportType.christmasCard, `Christmas Cards ${new Date().toLocaleDateString()}.xlsx`],
      [AgentGridExportType.conferencePoster, `Conference Posters ${new Date().toLocaleDateString()}.xlsx`],
    ]).get(exportType);

    this.safeFile(buffer, fileName);
  };

  private safeFile = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    saveAs(blob, fileName);
  };

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
