import { Pipe, PipeTransform } from '@angular/core';
import { map, of } from 'rxjs';
import { DynamicListsGroupService, DynamicSegmentsGroupService } from 'ag-common-svc/public-api';
import { BaseModelKeys } from '@ag-common-lib/public-api';

@Pipe({
  name: 'listsAndSegmentsDataSourceByDbIds',
})
export class ListsAndSegmentsDataSourceByDbIdPipe implements PipeTransform {
  constructor(
    private dynamicSegmentsGroupService: DynamicSegmentsGroupService,
    private dynamicListsGroupService: DynamicListsGroupService,
  ) {}

  transform(dbIds: string[], type: 'segments' | 'lists' = 'lists') {
    if (!dbIds?.length) {
      return of([]);
    }
    const set = new Set(dbIds);

    if (type === 'lists') {
      return this.dynamicListsGroupService.lists$.pipe(
        map(lists => lists.filter(list => set.has(list?.[BaseModelKeys.dbId]))),
        map(data => data ?? []),
      );
    }

    return this.dynamicSegmentsGroupService.segments$.pipe(
      map(segments => segments.filter(segment => set.has(segment?.[BaseModelKeys.dbId]))),
      map(data => data ?? []),
    );
  }
}
