export const environment = {
  production: true,
  defaultauth: 'firebase',
  common: {
    apiKey: 'AIzaSyAgjzI_OV-gJIR487dmGqAGlpbK3Rr77mo',
    authDomain: 'alliance-group-portal-prod.firebaseapp.com',
    projectId: 'alliance-group-portal-prod',
    storageBucket: 'alliance-group-portal-prod.appspot.com',
    messagingSenderId: '1088177355571',
    appId: '1:1088177355571:web:1e135d294885409b8d5c46',
    measurementId: 'G-D96H4JP0B1',
  },
  google: {
    mapsAPIKey: 'AIzaSyCrFVl8PrFGe10YSFCBr9WDBtdEKb1A7LE',
    mapId: '56461284e4d20403',
  },
  domain: 'arm.alliancegrouplife.com',
  agentPortalUrl: 'https://my.alliancegrouplife.com/auth/login',
  session_expires: 120,
  stripe_payments_public_key:
    'pk_live_51MON3hBZ6K5GAvsGHsgv4YoNngOOEyIFuoZ1AsXVqTNf2bUkPiThlQ7pV0DF29PnuiBstEr8btccmZnjyQAk4npA00M4BcJ3Xh',
  user_admin_url: 'https://us-central1-alliance-group-portal-prod.cloudfunctions.net/users',
};
