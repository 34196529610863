<div class="mt-2">
  <div class="row my-2 px-3">
    <div class="col-12 text-start editToolbar">
      <dx-toolbar>
        <dxi-item location="before" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">
              <b>MGA</b>
            </div>
          </div>
        </dxi-item>

        <dxi-item location="after" locateInMenu="never">
          <div *dxTemplate>
            <dx-button
              [visible]="Entity.agentAgentInfoMga | hasPermission : EntityPermissionActivityKeys.write | async"
              [icon]="'edit'"
              [stylingMode]="'text'"
              (onClick)="showEditorModal()"
            ></dx-button>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-2 fw-bold">MGA:</div>

    <div class="col-4">{{ agent?.p_mga_id | agency | async | empty }}</div>

    <div class="col-2 fw-bold">Agency:</div>

    <div class="col-4">{{ agent?.p_agency_id | agency | async | empty }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-2 fw-bold">Is Team Lead?</div>

    <div class="col-4">{{ agent?.is_manager | yesNo }}</div>

    <div class="col-2 fw-bold">Credited:</div>

    <div class="col-4">{{ agent?.is_credited | yesNo }}</div>
  </div>

  <div class="row my-2 px-3">
    <div class="col-2 fw-bold">Upline:</div>

    <div class="col-4">{{ agent?.upline | empty }}</div>

    <div class="col-2 fw-bold">Team Lead</div>

    <div class="col-4">
      {{ getManager(agent.manager_id) | empty }}
    </div>
  </div>
</div>

<ag-crm-mga-editor-modal #mgaEditorModal></ag-crm-mga-editor-modal>
