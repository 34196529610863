<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form
    [(formData)]="conference"
    labelMode="floating"
    [validationGroup]="validationGroup"
    [readOnly]="isReadonlyMode"
  >
    <dxi-item [dataField]="ConferenceKeys.welcomeTitle" [label]="{ text: 'Welcome Title' }"></dxi-item>

    <dxi-item itemType="group" [colCount]="12">
      <dxi-item [colSpan]="6">
        <div *dxTemplate>
          <shr-html-editor
            class="mt-2"
            [name]="ConferenceKeys.welcomeDescription"
            [label]="'Event Description'"
            [isRequired]="true"
            [validationGroup]="welcomeDescriptionValidationGroup"
            [(value)]="conference && conference[ConferenceKeys.welcomeDescription]"
            [inProgress]="inProgress"
            [isReadOnly]="isReadonlyMode"
            [toolbarItemType]="[
              HtmlEditorTypeKeys.font,
              HtmlEditorTypeKeys.fontStyle,
              HtmlEditorTypeKeys.paragraph,
              HtmlEditorTypeKeys.header,
              HtmlEditorTypeKeys.link,
              HtmlEditorTypeKeys.preview,
            ]"
            (onShowPreview)="onShowPreview($event)"
          ></shr-html-editor>
        </div>
      </dxi-item>

      <dxi-item [colSpan]="6">
        <div *dxTemplate>
          <ag-crm-image-uploader
            [caption]="'Welcome Image'"
            [imageData]="conference?.[ConferenceKeys.welcomeImagePath]"
            [isReadonlyMode]="isReadonlyMode"
            (mediaChanged)="onMediaChanged($event)"
            (mediaCropped)="onMediaCropped($event)"
          ></ag-crm-image-uploader>
        </div>
      </dxi-item>
    </dxi-item>
  </dx-form>
</dx-scroll-view>

<dx-popup
  [showTitle]="true"
  title="Preview"
  width="90%"
  height="auto"
  maxHeight="100%"
  [(visible)]="popupVisible"
  [showCloseButton]="true"
>
  <div class="conference-steps-info-modal__template-container mat-typography">
    <h4>
      <b>{{ conference?.[ConferenceKeys.welcomeTitle] }}</b>
    </h4>

    <!--    <dx-scroll-view class="conference-registration-wizard__scroll-view" [useNative]="false" [showScrollbar]="'always'">-->
    <div
      class="conference-registration-wizard__template"
      *ngIf="conference?.[ConferenceKeys.welcomeDescription] as htmlContent"
    >
      <div
        [innerHTML]="
          htmlContent | conferenceTemplateWithArguments: (conferenceStepsTemplateArguments$ | async) | safeHTMLUrl
        "
      ></div>
    </div>

    <div
      class="col conference-registration-wizard__welcome-img-container"
      *ngIf="conference?.welcomeImagePath?.wasabiPath | wasabiImg as imageSrc"
    >
      <img [src]="imageSrc" alt="Cap Cana Conference" class="conference-registration-wizard__welcome-img" />
    </div>
    <!--    </dx-scroll-view>-->
  </div>
</dx-popup>
