<dx-form [(formData)]="conference" labelMode="floating" [colCount]="12" [readOnly]="isReadonlyMode">
  <dxi-item [dataField]="ConferenceKeys.bccEmail" [label]="{ text: 'BCC' }" [colSpan]="12">
    <div *dxTemplate>
      <dx-tag-box
        #tagBoxRef
        [dataSource]="agentsEmailAddressesDataSource"
        [value]="conference?.[ConferenceKeys.bccEmail]"
        (valueChange)="conference && (conference[ConferenceKeys.bccEmail] = $event)"
        [searchEnabled]="true"
        [showDropDownButton]="true"
        [showClearButton]="true"
        [multiline]="false"
        [acceptCustomValue]="false"
        [label]="'BCC'"
        labelMode="floating"
        [valueExpr]="AgentKeys.email_addresses"
        [displayExpr]="AgentKeys.email_addresses"
        class="attendees-box"
        [placeholder]="''"
        [maxDisplayedTags]="3"
        [readOnly]="isReadonlyMode"
      >
        <div *dxTemplate="let data of 'item'">
          <div style="padding: 5px 0">
            <strong>{{ data?.[AgentKeys.email_addresses] }}</strong>
          </div>

          <div *ngFor="let agent of data?.agents; let i = index" style="padding: 5px 0 0 0px">
            {{
              [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
                | fullName
                | empty: "Unknown Agent"
            }}
            <i *ngIf="agent?.p_email === data?.[AgentKeys.email_addresses]" class="dx-icon-agi-key"></i>
          </div>
        </div>
      </dx-tag-box>
    </div>
  </dxi-item>

  <dxi-item
    [colSpan]="4"
    editorType="dxSelectBox"
    [dataField]="ConferenceKeys.summaryEmailTemplate"
    [label]="{ text: 'Registration Summary Email Template' }"
    [editorOptions]="{
      items: emailTemplatesLookup,
      valueExpr: LookupKeys.value,
      displayExpr: LookupKeys.description,
      searchEnabled: true,
      placeholder: '',
    }"
  >
  </dxi-item>
</dx-form>
