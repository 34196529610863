<div class="row mt-2" style="background-color: white">
  <div class="col-6 px-3">The following Agents have permission to view this profile.</div>
  <div class="col-6 px-3">Add a User to the Permissions list</div>
</div>
<div class="row mt-2" style="background-color: white">
  <div class="col-6 px-3">
    <dx-list
      #permissionsList
      [dataSource]="assignedAgentsDataSource$ | async"
      [displayExpr]="'granted_to_name'"
      [height]="400"
      [allowItemDeleting]="canDelete"
      [itemDeleteMode]="'static'"
      (onItemDeleted)="onRemoveAgentFromPermissionsList($event)"
    >
    </dx-list>
  </div>
  <div class="col-4 px-3">
    <dx-select-box
      [dataSource]="agentsDataSource$ | async"
      [displayExpr]="'description'"
      [(value)]="agentToAdd"
      [searchEnabled]="true"
    >
    </dx-select-box>
  </div>
  <div class="col-2 px-3">
    <dx-button *ngIf="canEdit" [icon]="'add'" [disabled]="!agentToAdd" (onClick)="onAddAgentToPermission()"></dx-button>
  </div>
</div>
