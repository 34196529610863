<ag-shr-modal-window
  #notificationAddSegmentGroupModalRef
  [width]="'80vw'"
  [height]="'80vh'"
  [title]="title"
  (onSaveClick)="addNotificationSegmentsGroup()"
  [actionTitle]="'ADD'"
  [useScrollView]="false"
  [saveButtonDisabled]="!segmentsDbIds?.length"
>
  <dx-data-grid
    #segmentsGridGridRef
    class="dynamic-segments-group-modal__grid"
    *ngIf="notificationAddSegmentGroupModalRef?.popupComponent?.visible ?? false"
    [dataSource]="dataSource"
    [showBorders]="true"
    [renderAsync]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [noDataText]="'No Segments Exists'"
    width="100%"
    height="100%"
    (onSelectionChanged)="onSelectionChanged($event)"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-paging [pageSize]="30"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="false" [showInfo]="true" [visible]="showPager()"></dxo-pager>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowDeleting]="false"
      [allowUpdating]="false"
      [allowAdding]="false"
    ></dxo-editing>

    <dxo-selection
      [mode]="'multiple'"
      selectAllMode="allPages"
      showCheckBoxesMode="always"
      [allowSelectAll]="true"
    ></dxo-selection>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <div class="ml-2">
            <b>Segments</b>
          </div>
        </div>
      </dxi-item>

      <dxi-item name="searchPanel" cssClass="ag-grid__toolbar-control"></dxi-item>
    </dxo-toolbar>

    <dxi-column
      [dataField]="BaseModelKeys.dbId"
      [caption]="'Segment'"
      [width]="100"
      [visible]="false"
      sortOrder="asc"
      [sortingMethod]="sortingMethod"
    >
    </dxi-column>

    <dxi-column
      [dataField]="DynamicSegmentsGroupKeys.title"
      dataType="string"
      [caption]="'Title'"
      [width]="200"
    >
    </dxi-column>

    <dxi-column
      [dataField]="DynamicSegmentsGroupKeys.description"
      [caption]="'Description'"
      cellTemplate="notificationCellTemplate"
    >
      <div *dxTemplate="let cell of 'notificationCellTemplate'">
        <div [innerHTML]="cell?.value"></div>
      </div>
    </dxi-column>

    <dxi-column [dataField]="DynamicSegmentsGroupKeys.recipientsCollection" [caption]="'Recipients Collection'">
      <dxo-lookup
        [dataSource]="notificationOptInGroupTypeLookup"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="DynamicSegmentsGroupKeys.collectionEventDbId" [caption]="'Event'">
      <dxo-lookup
        [dataSource]="conferences$ | async"
        [allowClearing]="false"
        [displayExpr]="ConferenceKeys.eventName"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="DynamicSegmentsGroupKeys.filterExpression"
      [caption]="'Filters'"
      [allowFiltering]="false"
      cellTemplate="notificationFilterBuilderCellTemplate"
    >
      <div *dxTemplate="let cell of 'notificationFilterBuilderCellTemplate'">
        <span
          class="dynamic-segments-group-list__filter ag-filter-builder"
          [innerHTML]="cell?.value | filterBuilderGroupDisplayValue: cell?.data?.recipientsCollection : 'html' | async"
        ></span>
      </div>
    </dxi-column>
  </dx-data-grid>
</ag-shr-modal-window>
