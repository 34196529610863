import { Injectable } from '@angular/core';
import { AgentService, DynamicListsGroupService } from 'ag-common-svc/public-api';

@Injectable()
export class NotificationAddListsModalService {
  agents$ = this.agentService.getList([], { sortField: 'p_agent_first_name', includeRef: false });
  lists$ = this.dynamicListsGroupService.lists$;

  constructor(
    private agentService: AgentService,
    private dynamicListsGroupService: DynamicListsGroupService,
  ) {}
}
