<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form
    *var="Entity.conferencePermissionsInfo | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
    class="h-100"
    [(formData)]="conference"
    labelMode="floating"
    [validationGroup]="validationGroup"
    [readOnly]="isReadonlyMode || !canWrite"
  >
    <dxi-item>
      <div *dxTemplate>
        <dx-data-grid
          #permissionsGridTmp
          [dataSource]="dataSource ?? []"
          [showRowLines]="true"
          [showBorders]="false"
          [columnAutoWidth]="true"
          [columnHidingEnabled]="true"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [scrollLeft]="true"
          [noDataText]="'No Agent Selected'"
          [wordWrapEnabled]="true"
          width="100%"
          height="100%"
        >
          <dxo-load-panel [enabled]="true"></dxo-load-panel>
          <dxo-scrolling mode="virtual"></dxo-scrolling>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

          <dxo-editing
            mode="popup"
            [useIcons]="true"
            [allowUpdating]="false"
            [allowDeleting]="
              (Entity.conferencePermissionsInfo | hasPermission: EntityPermissionActivityKeys.delete | async) ||
              (Entity.conferenceList | hasPermission: EntityPermissionActivityKeys.delete | async) ||
              (Entity.conferenceListAdmin | hasPermission: EntityPermissionActivityKeys.delete | async)
            "
            [allowAdding]="false"
          ></dxo-editing>

          <dxo-toolbar>
            <dxi-item name="searchPanel"></dxi-item>
            <dxi-item
              [visible]="
                (Entity.conferencePermissionsInfo | hasPermission: EntityPermissionActivityKeys.create | async) ||
                (Entity.conferenceListAdmin | hasPermission: EntityPermissionActivityKeys.create | async)
              "
              location="after"
              widget="dxButton"
              [options]="{ icon: 'plus', stylingMode: 'text', onClick: showAddTaskOwnerPopup }"
            ></dxi-item>
          </dxo-toolbar>

          <dxi-column
            [dataField]="BaseModelKeys.dbId"
            [caption]="'Agent Name'"
            cellTemplate="agentCellTemplate"
            [width]="300"
            sortOrder="asc"
          ></dxi-column>

          <dxi-column
            [dataField]="AgentKeys.email_addresses"
            caption="Primary Email Address"
            [calculateCellValue]="calculateEmailCellValue"
            [filterOperations]="['contains', 'notcontains', 'startswith', 'endswith']"
            [allowEditing]="false"
            [width]="300"
          >
          </dxi-column>

          <dxi-column
            [dataField]="AgentKeys.roles"
            caption="Roles"
            [allowEditing]="false"
            cellTemplate="rolesCellTemplate"
            [calculateFilterExpression]="calculateRolesFilterExpression"
            [width]="'auto'"
          >
            <dxo-lookup
              [dataSource]="roles$ | async"
              [allowClearing]="true"
              [valueExpr]="BaseModelKeys.dbId"
              [displayExpr]="UserRoleModelKeys.role"
            ></dxo-lookup>
          </dxi-column>

          <dxi-column type="buttons">
            <dxi-button name="delete" hint="Remove Agent"></dxi-button>
          </dxi-column>

          <div *dxTemplate="let cell of 'rolesCellTemplate'">
            <ng-container *ngFor="let item of cell.value | userRolesNameByIds | async">
              <span
                class="badge me-2"
                [ngClass]="{
                  'bg-primary': item?.[UserRoleModelKeys.appId] === EntityApplications.crm,
                  'bg-warning text-dark': item?.[UserRoleModelKeys.appId] === EntityApplications.portal,
                }"
                >{{ item?.[UserRoleModelKeys.role] }}</span
              >
            </ng-container>
          </div>

          <div *dxTemplate="let cell of 'agentCellTemplate'">
            {{ [cell.data?.[AgentKeys.p_agent_first_name], cell.data?.[AgentKeys.p_agent_last_name]] | fullName }}
          </div>
        </dx-data-grid>
      </div>
    </dxi-item>
  </dx-form>
</dx-scroll-view>

<ag-crm-conference-permissions-modal
  #conferencePermissionsModalRef
  (onAfterSaveChanges)="refreshPermissionsGrid($event)"
></ag-crm-conference-permissions-modal>
