import { Component, ViewChild } from '@angular/core';
import { ConferenceRegistrationGridService } from '../conference-registration-grid.service';
import {
  Agent,
  AgentKeys,
  ATTENDEE_WIZARD_STATE_LOOKUP,
  AttendeeKeys,
  BaseModelKeys,
  CONFERENCE_REGISTRATIONS_STATUS_LOOKUP,
  CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP,
  CONFERENCE_REGISTRATIONS_TECKET_STATUS_LOOKUP,
  ConferenceRegistrationCategoryName,
  ConferenceRegistrationCommonTaskStatus,
  ConferenceRegistrationTicketState,
  INVITEE_STATUS_LOOKUP,
  LookupKeys,
  RegistrantModelKeys,
  REGISTRATS_TASKS_TITLE_LIST,
  Role,
  StorageKeys,
} from 'ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { REGISTRATS_GRID__TITLE_LIST } from '../config/conference-registration.config';
import { LoadOptions } from 'devextreme/data';
import { AttendeeDetailsModalSection } from '../../conferences/modals/attendee-details-modal/attendee-details-modal.model';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { getAgentFullName } from 'ag-common-svc/lib/services/agent.service/agent-service-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';

@UntilDestroy()
@Component({
  selector: 'app-invited-participants-grid',
  templateUrl: './invited-participants-grid.component.html',
  styleUrls: ['./invited-participants-grid.component.scss'],
})
export class InvitedParticipantsGridComponent {
  @ViewChild('conferenceRegistrationsGrid', { static: false })
  conferenceRegistrationsGridComponent: DxDataGridComponent;

  protected readonly inProgress$ = this.conferenceRegistrationGridService.inProgress$;
  protected participatedRegistrants$ = this.conferenceRegistrationGridService.participatedAttendees$;
  protected agencies$ = this.conferenceRegistrationGridService.agencies$;
  protected mgaList$ = this.conferenceRegistrationGridService.mgaList$;
  protected assignOwnerList$ = this.conferenceRegistrationGridService.assignOwnerList$;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly emptyMessage: string =
    'No Conference Registrants Currently Exist. Please select another conference.';
  protected readonly titleList = REGISTRATS_GRID__TITLE_LIST;
  protected readonly tasksTitleList = REGISTRATS_TASKS_TITLE_LIST;
  protected readonly taskStatus = CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP;
  protected readonly conferenceRegistrationStatus = CONFERENCE_REGISTRATIONS_STATUS_LOOKUP;
  protected readonly INVITEE_STATUS_LOOKUP = INVITEE_STATUS_LOOKUP;
  protected readonly ConferenceRegistrationCommonTaskStatus = ConferenceRegistrationCommonTaskStatus;
  protected readonly ConferenceRegistrationCategoryName = ConferenceRegistrationCategoryName;
  protected readonly RegistrantModelKeys = RegistrantModelKeys;
  protected readonly attendeeWizardStateLookup = ATTENDEE_WIZARD_STATE_LOOKUP;
  protected readonly ConferenceRegistrationTicketState = ConferenceRegistrationTicketState;
  protected readonly conferenceRegistrationTicketStateLookup = CONFERENCE_REGISTRATIONS_TECKET_STATUS_LOOKUP;

  protected readonly agentsLoadOptions: LoadOptions<Agent> = {
    filter: [AgentKeys.role, '=', Role.CONFERENCE_MANAGER],
  };

  protected AttendeeDetailsModalSection = AttendeeDetailsModalSection;
  protected agentsStore = this.agentElasticSearchService.getStore(this.agentsLoadOptions);
  protected readonly StorageKeys = StorageKeys;

  constructor(
    private agentElasticSearchService: AgentElasticSearchService,
    private conferenceRegistrationGridService: ConferenceRegistrationGridService,
  ) {}

  calculateFullNameCellValue = (attendee: Attendee) => {
    const fullName =
      [attendee?.firstName, attendee?.lastName].filter(Boolean).join(' ') + ` (${attendee?.inviteeEmail})`;

    return fullName;
  };

  protected getAgentFullName = getAgentFullName;

  viewAgentDetails(agentId: string): void {
    // this.showAgentDetails.emit(agentId);
  }

  changeOwner = (
    attendee: Attendee,
    step: ConferenceRegistrationCommonTaskStatus = ConferenceRegistrationCommonTaskStatus.new,
    owner,
  ) => {
    const registrantId = attendee?.registrant?.[BaseModelKeys.dbId];
    const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
    const task = attendee?.registrant?.[RegistrantModelKeys.task];
    const update = {
      [RegistrantModelKeys.task]: {
        [step]: {
          status: task[step].status ?? ConferenceRegistrationCommonTaskStatus.new,
          assignedTo: owner?.assignedTo ?? task[step].assignedTo,
          assignedPersonNote: owner?.assignedToNote ?? '',
          isStepDone: task[step].isStepDone ?? false,
        },
      },
    };

    return this.conferenceRegistrationGridService.updateRegistrant(conferenceDbId, registrantId, update);
  };

  protected showAttendeeModal = this.conferenceRegistrationGridService.showAttendeeModal;
}
