<div class="agency-editor ag-editor">
  <ag-shr-agency-header
    [agencyId]="selectedAgency[BaseModelKeys.dbId]"
    (fieldsUpdated)="handleAgentFieldsChanges($event)"
  ></ag-shr-agency-header>

  <ng-template [ngIf]="detailsView === 'Agency'">
    <dx-tabs
      style="width: 70%"
      [items]="agencyTabs | editorPermittedTabs | async"
      [selectedIndex]="0"
      (onItemClick)="selectAgencyTab($event)"
    >
    </dx-tabs>

    <ng-template [ngIf]="selectedAgencyTab == AgencyTab.ContactInfo">
      <dx-scroll-view height="60%">
        <div class="row mt-2">
          <div class="col-6">
            <ag-shr-addresses
              *ngIf="Entity.agencyContactInfoAddresses | hasPermission | async"
              [title]="'Agency Addresses'"
              [showBillingAddress]="false"
              [canEdit]="Entity.agencyContactInfoAddresses | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="
                Entity.agencyContactInfoAddresses | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agencyContactInfoAddresses | hasPermission: EntityPermissionActivityKeys.create | async
              "
              [addresses]="selectedAgency?.addresses"
              [updateAddress]="onSaveAddresses"
              [typesLookup]="agencyAddressTypesLookup$ | async"
            ></ag-shr-addresses>

            <ag-shr-socials
              *ngIf="Entity.agencyContactInfoSocials | hasPermission | async"
              [socials]="selectedAgency?.socials"
              [socialMediaLookup]="socialMediaLookup$ | async"
              [socialTypesLookup]="socialTypesLookup$ | async"
              [saveSocials]="onSaveSocial"
              [canEdit]="Entity.agencyContactInfoSocials | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="Entity.agencyContactInfoSocials | hasPermission: EntityPermissionActivityKeys.delete | async"
              [canCreate]="Entity.agencyContactInfoSocials | hasPermission: EntityPermissionActivityKeys.create | async"
            ></ag-shr-socials>
          </div>

          <div class="col-6">
            <ag-shr-agency-office-email-address
              *ngIf="Entity.agencyContactInfoOfficeEmailAddresses | hasPermission | async"
              [agencyId]="selectedAgency?.dbId"
              [emails]="selectedAgency?.officeEmailAddress"
              (emailAddressesChange)="selectedAgency.officeEmailAddress = $event"
            ></ag-shr-agency-office-email-address>

            <ag-shr-agency-office-phone-numbers
              *ngIf="Entity.agencyContactInfoOfficePhoneNumbers | hasPermission | async"
              [agencyId]="selectedAgency?.dbId"
              [phoneNumbers]="selectedAgency?.officePhoneNumbers"
              (phoneNumbersChange)="selectedAgency.officePhoneNumbers = $event"
            ></ag-shr-agency-office-phone-numbers>

            <ag-shr-websites
              *ngIf="Entity.agencyContactInfoWebsites | hasPermission | async"
              [parentDbId]="selectedAgency?.dbId"
              [websites]="selectedAgency?.websites"
              [websiteTypesLookup]="websiteTypesLookup$ | async"
              [saveWebsites]="onSaveWebsites"
              [canEdit]="Entity.agencyContactInfoWebsites | hasPermission: EntityPermissionActivityKeys.write | async"
              [canDelete]="
                Entity.agencyContactInfoWebsites | hasPermission: EntityPermissionActivityKeys.delete | async
              "
              [canCreate]="
                Entity.agencyContactInfoWebsites | hasPermission: EntityPermissionActivityKeys.create | async
              "
            ></ag-shr-websites>
          </div>
        </div>
      </dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgencyTab == AgencyTab.AgencyInfo">
      <dx-scroll-view height="60%">
        <div class="row mt-2">
          <div class="col">
            <ag-shr-agency-carriers
              *ngIf="Entity.agencyAgencyInfoCarriers | hasPermission | async"
              [agency]="selectedAgency"
            ></ag-shr-agency-carriers>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <ag-shr-agency-status-activity
              *ngIf="Entity.agencyAgencyInfoStatusActivity | hasPermission | async"
              [agency]="selectedAgency"
            ></ag-shr-agency-status-activity>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <ag-shr-agency-state-licensed-in
              *ngIf="Entity.agencyAgencyInfoStatesLicensedIn | hasPermission | async"
              [agency]="selectedAgency"
            ></ag-shr-agency-state-licensed-in>
          </div>

          <div class="col-6"></div>
        </div>
      </dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgencyTab == AgencyTab.AgencyRelationship">
      <dx-scroll-view height="60%">
        <div class="row mt-2">
          <div class="col">
            <ag-shr-agency-relationship
              *ngIf="Entity.agencyAgencyRelationshipWithAllianceGroup | hasPermission | async"
              [agencyId]="selectedAgency?.dbId"
              [agencyRelationship]="selectedAgency?.relationshipWithAG"
              (agencyRelationshipChange)="agencyRelationshipChange($event)"
            ></ag-shr-agency-relationship>
          </div>
        </div>
      </dx-scroll-view>
    </ng-template>

    <ng-template [ngIf]="selectedAgencyTab == AgencyTab.Media">
      <div class="row">
        <div class="col">
          <ag-shr-agency-media
            *ngIf="Entity.agencyMedia | hasPermission | async"
            [agencyDbId]="selectedAgency?.dbId"
          ></ag-shr-agency-media>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
