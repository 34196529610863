<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="title"
    [isEditVisible]="canEdit"
    (clickEdit)="addressesModalComponent.showModal()"
  ></ag-shr-editor-toolbar>

  <ag-shr-addresses-grid
    class="px-3"
    [addresses]="addresses"
    [isEditable]="false"
    [typesLookup]="typesLookup"
    [showBillingAddress]="showBillingAddress"
    [showPhysicalLocation]="showPhysicalLocation"
    [showShippingAddress]="showShippingAddress"
  ></ag-shr-addresses-grid>
</div>

<ag-shr-modal-window
  #manageAddressModalRef
  [title]="'Add / Edit ' + title"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleAddressSave($event)"
  [onCloseModal]="handleClosePopup"
  [height]="'auto'"
>
  <ng-container *ngIf="manageAddressModalRef?.popupComponent?.visible ?? false">
    <dx-validation-summary
      class="addresses__validation-summary"
      [validationGroup]="addressUniqValidationGroup"
    ></dx-validation-summary>

    <dx-validator [validationGroup]="addressUniqValidationGroup" [adapter]="addressUniqAdapter">
      <dxi-validation-rule
        type="async"
        [validationCallback]="checkIsAddressUniq"
        [message]="'Same address already exists in this profile'"
        [reevaluate]="true"
      ></dxi-validation-rule>
    </dx-validator>

    <ag-shr-address-form
      #addressFormRef
      [isPrimaryBillingVisible]="showBillingAddress"
      [isPhysicalLocationVisible]="showPhysicalLocation"
      [isPrimaryShippingVisible]="showShippingAddress"
      [address]="addressToEdit"
      [typesLookup]="typesLookup"
      [validationGroup]="addressValidationGroup"
    ></ag-shr-address-form>
  </ng-container>
</ag-shr-modal-window>

<ag-shr-modal-window #addressesModalRef [title]="title" [showSaveButton]="false" [inProgress]="inProgress$ | async">
  <ag-shr-addresses-grid
    [addresses]="addresses"
    [isEditable]="true"
    [showBillingAddress]="showBillingAddress"
    [showPhysicalLocation]="showPhysicalLocation"
    [showShippingAddress]="showShippingAddress"
    [hasPermissionsToEdit]="canEdit"
    [hasPermissionsToDelete]="canDelete"
    [typesLookup]="typesLookup"
    (addAddress)="addAddress()"
    (editAddress)="editAddress($event)"
    (removeAddress)="onRowRemoving($event)"
  ></ag-shr-addresses-grid>
</ag-shr-modal-window>
