import DxDataGrid from 'devextreme/ui/data_grid';

export const sortingMethod = (gridInstance: DxDataGrid<any, any>, value1: any, value2: any) => {
  const isSelectedOne = gridInstance?.isRowSelected(value1);
  const isSelectedSecond = gridInstance?.isRowSelected(value2);

  if (isSelectedOne && !isSelectedSecond) {
    return -1;
  }

  if (!isSelectedOne && isSelectedSecond) {
    return 1;
  }

  return 0;
};

export const showPager = (gridInstance: DxDataGrid<any, any>): boolean => gridInstance?.pageCount() > 1;
