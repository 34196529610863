import { ConferenceKeys, ConferenceRegistrationStepName, Entity } from 'ag-common-lib/public-api';
import { Observable } from 'rxjs';

export enum ConferenceFormSections {
  conferenceWelcomeInformation,
  stepsInfoConfiguration,
  conferencePrimaryInformation,
  emailSender,
  event,
  hotel,
  budgetInformation,
  guests,
  excursions,
  permissions,
}

export const sectionControlKeysMap = {
  [ConferenceRegistrationStepName.registrantHotelReservationStep]: {
    activeKey: ConferenceKeys.hotelActive,
    enableKey: ConferenceKeys.hotelEnabled,
  },
  [ConferenceRegistrationStepName.registrantGuestConfigurationStep]: {
    activeKey: ConferenceKeys.guestsActive,
    enableKey: ConferenceKeys.guestsEnabled,
  },
  [ConferenceRegistrationStepName.registrantExcursionsStep]: {
    activeKey: ConferenceKeys.excursionsActive,
    enableKey: ConferenceKeys.excursionsEnabled,
  },
} as const;

export const CONFERENCE_SECTIONS: {
  id: ConferenceFormSections;
  title: string;
  permissionId: Entity;
  isPermitted?: Observable<boolean>;
  enableControlVisible?: boolean;
  activeControlKey?:
    | ConferenceKeys.hotelActive
    | ConferenceKeys.guestsActive
    | ConferenceKeys.eventActive
    | ConferenceKeys.excursionsActive;
  enableControlKey?:
    | ConferenceKeys.hotelEnabled
    | ConferenceKeys.guestsEnabled
    | ConferenceKeys.eventEnabled
    | ConferenceKeys.excursionsEnabled;
}[] = [
  { id: ConferenceFormSections.conferencePrimaryInformation, title: 'Primary Info', permissionId: Entity.conferencePrimaryInfo },

  { id: ConferenceFormSections.emailSender, title: 'Email Sender Configuration', permissionId: Entity.conferenceEmailSenderConfiguration },

  {
    id: ConferenceFormSections.conferenceWelcomeInformation,
    title: 'Welcome Info',
    permissionId: Entity.conferenceWelcomeInfo,
  },

  {
    id: ConferenceFormSections.stepsInfoConfiguration,
    title: 'Steps Configuration',
    permissionId: Entity.conferenceStepsConfiguration,
  },

  {
    id: ConferenceFormSections.hotel,
    title: 'Hotel',
    permissionId: Entity.conferenceHotelInfo,
    enableControlVisible: true,
    enableControlKey: ConferenceKeys.hotelEnabled,
    activeControlKey: ConferenceKeys.hotelActive,
  },

  {
    id: ConferenceFormSections.guests,
    title: 'Guests',
    permissionId: Entity.conferenceGuestsInfo,
    enableControlVisible: true,
    enableControlKey: ConferenceKeys.guestsEnabled,
    activeControlKey: ConferenceKeys.guestsActive,
  },

  {
    id: ConferenceFormSections.excursions,
    title: 'Excursions',
    permissionId: Entity.conferenceExcursionsInfo,
    enableControlVisible: true,
    enableControlKey: ConferenceKeys.excursionsEnabled,
    activeControlKey: ConferenceKeys.excursionsActive,
  },
  {
    id: ConferenceFormSections.permissions,
    title: 'Permissions',
    permissionId: Entity.conferencePermissionsInfo,
  },
  // {
  //   id: ConferenceFormSections.event,
  //   title: 'Event',
  //   permissionId: Entity.conference,
  //   enableControlVisible: true,
  //   enableControlKey: ConferenceKeys.eventEnabled,
  // },
  // { id: ConferenceFormSections.budgetInformation, title: 'Budget Information', permissionId: Entity.conference },
];
