import { Component, HostBinding, ViewChild } from '@angular/core';
import {
  BaseModelKeys,
  BrokenRule,
  Conference,
  ConferenceKeys,
  Constants,
  Entity,
  EntityPermissionActivityKeys,
} from 'ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DxTabPanelComponent } from 'devextreme-angular';
import { ClickEvent } from 'devextreme/ui/button';
import { CONFERENCES_TITLE_LIST } from '../../config/conferences.config';
import { ConferenceModalService } from './conference-form.service';
import { CONFERENCE_SECTIONS, ConferenceFormSections } from './conference-modal.models';
import { validateSingleDxGroup } from 'ag-common-svc/lib/utils/validation';
import { combineLatest, Observable, shareReplay, Subject } from 'rxjs';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ag-crm-conference-modal',
  templateUrl: './conference-modal.component.html',
  styleUrls: ['./conference-modal.component.scss'],
  providers: [ConferenceModalService],
})
export class ConferenceModalComponent {
  @HostBinding('class') className = 'conference-modal';
  @ViewChild('conferenceFormModalRef', { static: true }) conferenceFormModalComponent: ModalWindowComponent;
  @ViewChild('tabsRef', { static: false }) conferenceTabsComponent: DxTabPanelComponent;

  private _errors$ = new Subject<BrokenRule<ConferenceFormSections>[]>();
  protected errors$ = this._errors$.asObservable().pipe(shareReplay());
  protected conferenceSectionsTabs;
  protected sectionsWithWritePermission;
  isCreateMode = false;
  caption = 'Edit Event Information';
  eventNameTitleMask = this.caption + Constants.EVENT_NAME_TITLE;
  protected conferenceFormData: Conference;
  protected readonly inProgress$ = this.conferenceModalService.inProgress$;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly ConferenceFormSections = ConferenceFormSections;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly primaryInfoValidationGroup = 'primaryInfoValidationGroup';
  protected readonly emailSenderFormValidationGroup = 'emailSenderFormValidationGroup';
  protected readonly eventValidationGroup = 'eventValidationGroup';
  protected readonly hotelValidationGroup = 'hotelValidationGroup';
  protected readonly guestsValidationGroup = 'guestsValidationGroup';
  protected readonly excursionsValidationGroup = 'excursionsValidationGroup';
  protected readonly welcomeInfoValidationGroup = 'welcomeInfoValidationGroup';
  protected readonly stepsInfoValidationGroup = 'stepsInfoValidationGroup';

  constructor(
    private conferenceModalService: ConferenceModalService,
    private hasPermissionPipe: HasPermissionPipe,
  ) {}

  protected toggleSectionForWizard = (e: ClickEvent, activeControlKey, isActive) => {
    e?.event?.stopPropagation();

    this.conferenceFormData[activeControlKey] = !isActive;
  };

  protected toggleSection = (e: ClickEvent, enableControlKey, isEnabled) => {
    e?.event?.stopPropagation();

    this.conferenceFormData[enableControlKey] = !isEnabled;
  };
  protected handleSaveConference = async e => {
    const validationResults = await this.validateGroupRules();
    if (validationResults?.errors?.length) {
      return;
    }

    const dbId = this.conferenceFormData[BaseModelKeys.dbId];
    const results = dbId
      ? await this.conferenceModalService.updateConference(dbId, this.conferenceFormData)
      : await this.conferenceModalService.createConference(this.conferenceFormData);
    if (results) {
      this.conferenceModalService?.revertAllChanges();
      this.conferenceFormModalComponent?.hideModal();
    }
  };

  protected validateGroupRules = async () => {
    const errors = [];
    const validationGroups = [
      this.primaryInfoValidationGroup,
      this.eventValidationGroup,
      this.hotelValidationGroup,
      this.guestsValidationGroup,
      this.excursionsValidationGroup,
      this.welcomeInfoValidationGroup,
    ];

    for (const validationGroup of validationGroups) {
      const validationResults = await validateSingleDxGroup(validationGroup);

      if (validationResults.isValid) {
        continue;
      }

      validationResults?.invalidEditors?.forEach(editor => {
        const editorName = editor?.option('name') ? editor?.option('name') : editor?.option('elementAttr')?.id;
        const validationError = editor?.option('validationError');

        const brokenRule: BrokenRule<ConferenceFormSections> = {
          editor,
          label: this.getBrokenRuleLabel(editorName, validationError?.message),
          sectionId: this.getBrokenRuleSectionId(validationGroup),
        };

        errors.push(brokenRule);
      });
    }
    this._errors$.next(errors);

    return { errors };
  };

  showModal = async (data?: Conference): Promise<void> => {
    this.isCreateMode = !data?.[BaseModelKeys.dbId];
    console.log('this.isCreateMode ', this.isCreateMode);
    this.caption = this.isCreateMode ? 'Add Event Information' : 'Edit Event Information';
    this._errors$.next(null);
    this.conferenceSectionsTabs = this.getConferenceTabsDate();
    this.sectionsWithWritePermission = this.getConferenceTabsDate(EntityPermissionActivityKeys.write);
    this.conferenceFormData = await this.conferenceModalService.getFormData(data);
    this.conferenceFormModalComponent?.showModal();
  };

  protected onValidationSummaryItemClick = async (brokenRule: BrokenRule<ConferenceFormSections>): Promise<void> => {
    this.conferenceTabsComponent.selectedIndex = CONFERENCE_SECTIONS.findIndex(
      ({ id }) => id === brokenRule?.sectionId,
    );

    setTimeout(() => {
      brokenRule?.editor?.focus();
    }, 0);
  };

  protected handleClosePopup = this.conferenceModalService.onCancelEdit;

  private getBrokenRuleLabel(editorName: string = '', message: string) {
    const [groupName] = editorName?.split('.');

    switch (groupName) {
      case ConferenceKeys.startDate:
      case ConferenceKeys.endDate:
        return `Event Date: ${message}`;

      case ConferenceKeys.registrationStartDate:
      case ConferenceKeys.registrationEndDate:
        return `Event Registration Date: ${message}`;

      case ConferenceKeys.excursionsBudget:
        return `Excursions Budget: ${message}`;

      case ConferenceKeys.maxGuests:
        return `Maximum Number of Guests: ${message}`;

      case ConferenceKeys.complimentaryGuests:
        return `Maximum Number of Complimentary Guests: ${message}`;

      case ConferenceKeys.eventName:
        return `Event Name: ${message}`;

      default:
        return message;
    }
  }

  private getBrokenRuleSectionId(validationGroup: string): ConferenceFormSections {
    switch (validationGroup) {
      case this.primaryInfoValidationGroup:
        return ConferenceFormSections.conferencePrimaryInformation;
      case this.eventValidationGroup:
        return ConferenceFormSections.event;
      case this.hotelValidationGroup:
        return ConferenceFormSections.hotel;
      case this.guestsValidationGroup:
        return ConferenceFormSections.guests;
      case this.excursionsValidationGroup:
        return ConferenceFormSections.excursions;
      default:
        return ConferenceFormSections.conferenceWelcomeInformation;
    }
  }

  private getConferenceTabsDate = (activity: EntityPermissionActivityKeys = EntityPermissionActivityKeys.read) => {
    return CONFERENCE_SECTIONS.map(section => ({
      ...section,
      isPermitted: combineLatest({
        tabPermission: this.hasPermissionPipe.transform(section.permissionId, activity),
        conferenceListPermission: this.hasPermissionPipe.transform(
            Entity.conferenceList,
            EntityPermissionActivityKeys.create,
        ),
        conferenceListAdminPermission: this.hasPermissionPipe.transform(
            Entity.conferenceListAdmin,
            EntityPermissionActivityKeys.create,
        ),
      }).pipe(
          map(({ tabPermission, conferenceListPermission, conferenceListAdminPermission }) => {
            if (this.isCreateMode) {
              return tabPermission || conferenceListPermission || conferenceListAdminPermission;
            }
            return tabPermission;
          }),
          shareReplay(1),
      ),
    }));
  };
}
