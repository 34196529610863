import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeesListByConferencePipe } from './attendees-list-by-conference.pipe';
import { ConvertFilterGroupToValuePipe } from './convert-filter-group-to-value.pipe';

@NgModule({
  declarations: [AttendeesListByConferencePipe, ConvertFilterGroupToValuePipe],
  imports: [CommonModule],
  exports: [AttendeesListByConferencePipe, ConvertFilterGroupToValuePipe],
})
export class SegmentsPipesModule {}
