<ag-shr-modal-window
  #approveDenyReasonModalRef
  [title]="title"
  [width]="500"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="approveDenyReasonFormData | approveDenyReasonsPermissions | async"
  (onSaveClick)="handleSaveApproveDenyReason($event)"
  (onPopupClose)="handleApproveDenyReasonFormPopupClose($event)"
>
  <dx-form
    *ngIf="approveDenyReasonModalRef?.popupComponent?.visible ?? false"
    #approveDenyReasonFormRef
    [(formData)]="approveDenyReasonFormData"
    labelMode="floating"
  >
    <dxi-item
      *ngIf="approveDenyReasonFormData[ApproveDenyReasonKeys.isSystem]; else notSystemCreatedBy"
      [dataField]="BaseModelKeys.createdBy"
      [label]="{ text: 'Created By' }"
      editorType="dxTextBox"
      [editorOptions]="{
        readOnly: true,
        value: 'System',
      }"
    >
    </dxi-item>

    <ng-template #notSystemCreatedBy>
      <dxi-item
        *ngIf="approveDenyReasonFormData[BaseModelKeys.createdByAgentDbId]"
        [dataField]="BaseModelKeys.createdByAgentDbId"
        [label]="{ text: 'Created By' }"
        editorType="dxSelectBox"
        [editorOptions]="{
          readOnly: true,
          dataSource: agentsDataSource$,
          valueExpr: 'dbId',
          displayExpr: 'p_agent_name',
          placeholder: '',
        }"
      >
      </dxi-item>

      <dxi-item
        *ngIf="!approveDenyReasonFormData[BaseModelKeys.createdByAgentDbId]"
        [dataField]="BaseModelKeys.createdBy"
        [label]="{ text: 'Created By' }"
        editorType="dxTextBox"
        [editorOptions]="{
          readOnly: true,
        }"
      >
      </dxi-item>
    </ng-template>

    <dxi-item
      [dataField]="BaseModelKeys.createdDate"
      [label]="{ text: 'Created Date' }"
      editorType="dxDateBox"
      [editorOptions]="{
        readOnly: true,
        displayFormat: dateFormat,
        useMaskBehavior: true,
        type: 'datetime',
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="ApproveDenyReasonKeys.visibilityLevel"
      [label]="{ text: 'View Type' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        readOnly: !(approveDenyReasonFormData | approveDenyReasonsPermissions | async) || isVisibilityTypeLocked,
        items: approveDenyReasonVisibilityLevelLookup,
        valueExpr: 'value',
        displayExpr: 'description',
        searchEnabled: true,
        placeholder: '',
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="ApproveDenyReasonKeys.activity"
      [isRequired]="true"
      [label]="{ text: 'Activity' }"
      [editorType]="'dxTextArea'"
      [editorOptions]="{ readOnly: !(approveDenyReasonFormData | approveDenyReasonsPermissions | async), height: 150 }"
    ></dxi-item>
  </dx-form>
</ag-shr-modal-window>
