import { Injectable } from '@angular/core';
import {
  ActiveLookup,
  AGENCY_TYPE,
  AgencyKeys,
  BaseModelKeys,
  CarrierModelKeys,
  Lookup,
  LookupKeys,
  Lookups,
} from '@ag-common-lib/public-api';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AgencyService, CarrierService, QueryParam, WhereFilterOperandKeys } from '../../public-api';
import { LookupsManagerService } from './lookups-manager.service';

@Injectable({ providedIn: 'root' })
export class LookupsService {
  readonly statesLookup$: Observable<ActiveLookup[]>;
  readonly gendersLookup$: Observable<ActiveLookup[]>;
  readonly dietaryConsiderationTypesLookup$: Observable<ActiveLookup[]>;
  readonly tShortSizesLookup$: Observable<ActiveLookup[]>;
  readonly suffixesLookup$: Observable<ActiveLookup[]>;
  readonly prefixesLookup$: Observable<ActiveLookup[]>;
  readonly taskCategoryLookup$: Observable<ActiveLookup[]>;
  readonly taskSubcategoryLookup$: Observable<ActiveLookup[]>;
  readonly associationTypeLookup$: Observable<ActiveLookup[]>;
  readonly emailTypeLookup$: Observable<ActiveLookup[]>;
  readonly supportTypesLookup$: Observable<ActiveLookup[]>;
  readonly agencyStatusesLookup$: Observable<ActiveLookup[]>;
  readonly agencyOrganizationAffiliations$: Observable<ActiveLookup[]>;
  readonly agencyPhoneTypes$: Observable<ActiveLookup[]>;
  readonly agencyEmailTypes$: Observable<ActiveLookup[]>;
  readonly agencyAddressTypesLookup$: Observable<ActiveLookup[]>;
  readonly agencySocialTypesLookup$: Observable<ActiveLookup[]>;
  readonly agencySocialMediaLookup$: Observable<ActiveLookup[]>;
  readonly agencyWebsiteTypesLookup$: Observable<ActiveLookup[]>;
  readonly socialTypesLookup$: Observable<ActiveLookup[]>;
  readonly socialMediaLookup$: Observable<ActiveLookup[]>;
  readonly websiteTypesLookup$: Observable<ActiveLookup[]>;

  // Lookups from data
  readonly carriersLookup$: Observable<Partial<ActiveLookup>[]>;
  readonly mgaLookup$: Observable<Partial<ActiveLookup>[]>;
  readonly agencyLookup$: Observable<Partial<ActiveLookup>[]>;

  private readonly orderedSizes = ['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl', 'other'];
  private readonly orderedSuffixes = ['jr', 'sr', 'ii', 'iii', 'iiii', 'iv'];
  private readonly orderedAssociationsTypes = [
    'spouse',
    'business partner',
    'friend',
    'father',
    'mother',
    'brother',
    'sister',
    'son',
    'daughter',
    'grandparent',
    'family member',
  ];

  constructor(
    private lookupsManagerService: LookupsManagerService,
    carriersService: CarrierService,
    agencyService: AgencyService,
  ) {
    this.gendersLookup$ = this.lookupsManagerService
      .getList(Lookups.Genders)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.suffixesLookup$ = this.lookupsManagerService.getList(Lookups.Suffixes).pipe(
      map(this.normalizeLookup),
      map(items => items.sort(this.comparator.bind(this, this.orderedSuffixes))),
      shareReplay(1),
    );
    this.prefixesLookup$ = this.lookupsManagerService
      .getList(Lookups.Prefixes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.statesLookup$ = this.lookupsManagerService
      .getList(Lookups.States)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.taskCategoryLookup$ = this.lookupsManagerService
      .getList(Lookups.TaskCategory)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.taskSubcategoryLookup$ = this.lookupsManagerService
      .getList(Lookups.TaskSubcategory)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.associationTypeLookup$ = this.lookupsManagerService.getList(Lookups.AssociationType).pipe(
      map(this.normalizeLookup),
      map(items => items.sort(this.comparator.bind(this, this.orderedAssociationsTypes))),
      shareReplay(1),
    );
    this.dietaryConsiderationTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.DietaryConsiderationType)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.emailTypeLookup$ = this.lookupsManagerService
      .getList(Lookups.EmailTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.tShortSizesLookup$ = this.lookupsManagerService.getList(Lookups.TShirtSize).pipe(
      map(this.normalizeLookup),
      map(items => items.sort(this.comparator.bind(this, this.orderedSizes))),
      shareReplay(1),
    );
    this.supportTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.SupportTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencyStatusesLookup$ = this.lookupsManagerService
      .getList(Lookups.AgencyStatuses)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencyOrganizationAffiliations$ = this.lookupsManagerService
      .getList(Lookups.AgencyOrganizationAffiliations)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencyPhoneTypes$ = this.lookupsManagerService
      .getList(Lookups.AgencyPhoneTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencyEmailTypes$ = this.lookupsManagerService
      .getList(Lookups.AgencyEmailTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencyAddressTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.AgencyAddressTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.socialTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.SocialTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.socialMediaLookup$ = this.lookupsManagerService
      .getList(Lookups.SocialMedia)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.websiteTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.WebsiteTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencySocialTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.AgencySocialTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencySocialMediaLookup$ = this.lookupsManagerService
      .getList(Lookups.AgencySocialMedia)
      .pipe(map(this.normalizeLookup), shareReplay(1));
    this.agencyWebsiteTypesLookup$ = this.lookupsManagerService
      .getList(Lookups.AgencyWebsiteTypes)
      .pipe(map(this.normalizeLookup), shareReplay(1));

    this.carriersLookup$ = carriersService.getList().pipe(
      map(carriers => {
        return carriers?.map((carrier): Partial<ActiveLookup> => {
          return {
            [BaseModelKeys.dbId]: carrier?.[BaseModelKeys.dbId],
            [LookupKeys.value]: carrier?.[BaseModelKeys.dbId],
            [LookupKeys.description]: carrier?.[CarrierModelKeys.carrierName],
          };
        });
      }),
    );

    this.mgaLookup$ = agencyService.getList().pipe(
      map(agencies => {
        return agencies
          ?.filter(agency => agency?.[AgencyKeys.agencyType] == AGENCY_TYPE.MGA)
          ?.map((agency): Partial<ActiveLookup> => {
            return {
              [BaseModelKeys.dbId]: agency?.[BaseModelKeys.dbId],
              [LookupKeys.value]: agency?.[AgencyKeys.agencyId],
              [LookupKeys.description]: agency?.[AgencyKeys.name],
            };
          });
      }),
    );

    this.agencyLookup$ = agencyService.getList().pipe(
      map(agencies => {
        return agencies?.map((agency): Partial<ActiveLookup> => {
          return {
            [BaseModelKeys.dbId]: agency?.[BaseModelKeys.dbId],
            [LookupKeys.value]: agency?.[AgencyKeys.agencyId],
            [LookupKeys.description]: agency?.[AgencyKeys.name],
          };
        });
      }),
    );
  }

  public getLookupByName = (lookup: Lookups): Observable<Partial<ActiveLookup>[]> => {
    switch (lookup) {
      case Lookups.States:
        return this.statesLookup$;
      case Lookups.EmailTypes:
        return this.emailTypeLookup$;
      case Lookups.Genders:
        return this.gendersLookup$;
      case Lookups.Suffixes:
        return this.suffixesLookup$;
      case Lookups.Prefixes:
        return this.prefixesLookup$;
      case Lookups.TaskCategory:
        return this.taskCategoryLookup$;
      case Lookups.TaskSubcategory:
        return this.taskSubcategoryLookup$;
      case Lookups.AssociationType:
        return this.associationTypeLookup$;
      case Lookups.TShirtSize:
        return this.tShortSizesLookup$;
      case Lookups.DietaryConsiderationType:
        return this.dietaryConsiderationTypesLookup$;
      case Lookups.SupportTypes:
        return this.supportTypesLookup$;
      case Lookups.AgencyStatuses:
        return this.agencyStatusesLookup$;
      case Lookups.AgencyOrganizationAffiliations:
        return this.agencyOrganizationAffiliations$;
      case Lookups.AgencyPhoneTypes:
        return this.agencyPhoneTypes$;
      case Lookups.AgencyEmailTypes:
        return this.agencyEmailTypes$;
      case Lookups.SocialTypes:
        return this.socialTypesLookup$;
      case Lookups.SocialMedia:
        return this.socialMediaLookup$;
      case Lookups.WebsiteTypes:
        return this.websiteTypesLookup$;
      case Lookups.AgencySocialTypes:
        return this.agencySocialTypesLookup$;
      case Lookups.AgencySocialMedia:
        return this.agencySocialMediaLookup$;
      case Lookups.AgencyWebsiteTypes:
        return this.agencyWebsiteTypesLookup$;
      // Lookups from data
      case Lookups.Carriers:
        return this.carriersLookup$;
      case Lookups.MGA:
        return this.mgaLookup$;
      case Lookups.Agency:
        return this.agencyLookup$;
      default:
        return of([]);
    }
  };

  public getTaskSubcategoryLookup = taskCategoryDbId => {
    if (!taskCategoryDbId) {
      return undefined;
    }
    return this.lookupsManagerService
      .getList(Lookups.TaskSubcategory, [
        new QueryParam(LookupKeys.dependsOn, WhereFilterOperandKeys.equal, taskCategoryDbId),
      ])
      .pipe(map(this.normalizeLookup), shareReplay(1));
  };

  private normalizeLookup = (items: Lookup[]) => {
    const list = Array.isArray(items)
      ? items.map(lookup => {
          const {
            [BaseModelKeys.dbId]: dbId,
            [BaseModelKeys.firebaseRef]: reference,
            [LookupKeys.value]: value,
            [LookupKeys.description]: description,
            [LookupKeys.isActive]: isActive,
            [LookupKeys.isDefault]: isDefault,
            [LookupKeys.isAssigned]: isAssigned,
          } = lookup;

          return {
            [BaseModelKeys.dbId]: dbId,
            [LookupKeys.value]: value,
            [LookupKeys.reference]: reference,
            [LookupKeys.description]: description,
            [LookupKeys.isAssigned]: isAssigned,
            [LookupKeys.isDefault]: isDefault,
            [LookupKeys.visible]: isActive,
          };
        })
      : [];

    return list;
  };

  private comparator = (items: string[], left: ActiveLookup, right: ActiveLookup) => {
    const valuesMap = this.getMapping(items);
    const leftValue = valuesMap.get(`${left?.value}`.toLocaleLowerCase()) ?? `${valuesMap.size} ${left?.value}`;
    const rightValue = valuesMap.get(`${right?.value}`.toLocaleLowerCase()) ?? `${valuesMap.size} ${right?.value}`;

    return leftValue.localeCompare(rightValue, 'en', {
      numeric: true,
      sensitivity: 'base',
      ignorePunctuation: true,
    });
  };

  private getMapping = (items: string[]) => {
    return new Map(items.map((item, index) => [item, index.toString()]));
  };
}
