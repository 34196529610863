import { DxFilterOperators, FilterCondition, FilterGroup } from '@ag-common-lib/lib';

export function convertFilter(filter: any[]): FilterGroup {
  if (!Array.isArray(filter) || !filter?.length) {
    return null;
  }
  const firstOperand = filter[0];
  const filterLength = filter?.length;

  if (firstOperand === DxFilterOperators.not) {
    const filterGroup = convertFilter(filter[1]);

    return {
      groupOperator: DxFilterOperators.not,
      filters: [filterGroup],
    };
  }

  if (typeof firstOperand === 'string') {
    const selectedFilterOperations = filterLength === 3 ? filter?.[1] : DxFilterOperators.equal;
    const secondOperand = filter?.[filterLength - 1];
    const condition = {
      field: firstOperand,
      operator: selectedFilterOperations,
      value: secondOperand,
    };
    const filterGroup = {
      groupOperator: DxFilterOperators.and,
      filters: [condition],
    };

    return filterGroup;
  }
  const groupOperator: string = filter.find(item => typeof item === 'string') ?? DxFilterOperators.and;
  const result = { filters: [], groupOperator };

  for (const item of filter) {
    if (!Array.isArray(item)) {
      continue;
    }
    const filterGroup = convertFilter(item);

    result.filters.push(filterGroup);
  }

  return result;
}

export function convertFilterGroup(filterGroup: FilterGroup): any[] {
  const result: any[] = [];
  const length = filterGroup?.filters?.length;
  const groupOperator = filterGroup.groupOperator;

  const addCondition = (condition: FilterGroup | FilterCondition) => {
    if ('filters' in condition) {
      result.push(convertFilterGroup(condition));
      return;
    }

    if (length === 1) {
      result.push(condition.field, condition.operator, condition.value);
      return;
    }

    result.push([condition.field, condition.operator, condition.value]);
  };

  if (groupOperator === DxFilterOperators.not) {
    result.push(groupOperator);
  }

  filterGroup.filters.forEach((condition, index) => {
    addCondition(condition);

    if (index < length - 1) {
      result.push(groupOperator);
    }
  });

  return result;
}

export function getFilteredFieldsNames(filterGroup: FilterGroup): string[] {
  const fieldsNames: string[] = [];
  const addFieldName = (condition: FilterGroup | FilterCondition) => {
    if ('filters' in condition) {
      const field = getFilteredFieldsNames(condition)[0];
      fieldsNames.push(field);
      return;
    }

    if (length === 1) {
      fieldsNames.push(condition.field);
      return;
    }
    fieldsNames.push(condition.field);
  };

  filterGroup?.filters?.forEach((condition) => {
    addFieldName(condition);
  });
  return fieldsNames;
}

export function checkIsMeetFilterCriteria(item: any, filterGroup: FilterGroup) {
  const convert = (filterGroup: FilterGroup): boolean => {
    const groupOperator = filterGroup?.groupOperator;
    const filters = filterGroup?.filters ?? [];

    if (groupOperator === DxFilterOperators.not) {
      const group = filters[0] as FilterGroup;
      const groupResult = convert(group);
      return !groupResult;
    }

    const items = filters?.map(filter => {
      if (!('filters' in filter)) {
        const field = filter.field;
        const operator = filter.operator;
        const value = filter?.value;
        const itemValue = item?.[field];

        switch (operator) {
          case DxFilterOperators.equal:
            return value === itemValue;
          case DxFilterOperators.doNotEqual:
            return value !== itemValue;
          case DxFilterOperators.more:
            return value > itemValue;
          case DxFilterOperators.moreOrEqual:
            return value >= itemValue;
          case DxFilterOperators.less:
            return value < itemValue;
          case DxFilterOperators.lessOrEqual:
            return value <= itemValue;
          case DxFilterOperators.startsWith:
            return `${itemValue}`.startsWith(value);
          case DxFilterOperators.endsWith:
            return `${itemValue}`.endsWith(value);
          case DxFilterOperators.contains:
            return `${itemValue}`.includes(value);
          case DxFilterOperators.notContains:
            return !`${itemValue}`.includes(value);
        }

        return false;
      }
      return convert(filter);
    });

    if (groupOperator === DxFilterOperators.and) {
      return items.every(Boolean);
    }

    if (groupOperator === DxFilterOperators.or) {
      return items.some(Boolean);
    }

    return false;
  };
  return convert(filterGroup);
}
