import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Address,
  AgencyCampaignsStatus,
  Agent,
  AgentCampaigns,
  AgentCampaignsKeys,
  AgentKeys,
  BaseModelKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  Prospect,
  Social,
  Tab,
  Website,
} from 'ag-common-lib/public-api';
import { AgentService, LookupsService, ProspectService } from 'ag-common-svc/public-api';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DxTabsComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { AgentCampaignWizardService } from 'ag-common-svc/lib/components/agent-campaign-wizard/agent-campaign-wizard.service';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';
import { HasAnyPermissionPipe } from 'ag-common-svc/shared/pipes/has-any-permission.pipe';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-agent-editor',
  templateUrl: './agent-editor.component.html',
  providers: [HasPermissionPipe, HasAnyPermissionPipe],
  styleUrls: ['./agent-editor.component.scss'],
})
export class AgentEditorComponent implements OnInit {
  @ViewChild('editorTabs') dxTabsComponent: DxTabsComponent;
  @Output() fullScreenClosed = new EventEmitter<boolean>();

  private _selectedAgent: Agent;
  @Input() set selectedAgent(data: Agent) {
    this._selectedAgent = data;
    this.agentCampaignWizardService.setAgent(data);
  }
  get selectedAgent(): Agent {
    return this._selectedAgent;
  }

  selectedProspect: Prospect;

  viewFullScreen: boolean = false;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  detailsView = 'Agent';
  selectedAgentTab = 'Contact Info';
  selectedProspectTab = 'Prospect';
  selectedAgentIndex = 0;
  readonly CAMPAIGNS_TAB_INDEX = 4;
  readonly AC_TAB_CLASS = 'alliance-campaigns-tab';

  public agentTabs: Tab[] = [
    {
      id: 0,
      text: 'Contact Info',
      isPermitted: this.hasAnyPermissionPipe.transform([
        Entity.agentContactInfoWebsites,
        Entity.agentContactInfoSocials,
        Entity.agentContactInfoAddresses,
        Entity.agentContactInfoPhoneNumbers,
        Entity.agentContactInfoEmailAddresses,
      ]),
    },
    { id: 1, text: 'Personal Info', isPermitted: this.hasPermissionPipe.transform(Entity.agentPersonalInformation) },
    { id: 2, text: 'Agent Info', isPermitted: this.hasPermissionPipe.transform(Entity.agentInformation) },
    { id: 3, text: 'Permissions', isPermitted: this.hasPermissionPipe.transform(Entity.agentPermissions) },
    {
      id: 4,
      text: 'Alliance Campaigns',
      template: 'templateCampaignsTab',
      isPermitted: this.hasPermissionPipe.transform(Entity.agentCampaignWizard),
      disabled: this.setDisabledState(),
    },
    { id: 5, text: 'Media', isPermitted: this.hasPermissionPipe.transform(Entity.agentMedia) },
  ];

  public prospectTabs: Tab[] = [
    { id: 0, text: 'Prospect', template: 'Prospect' },
    { id: 1, text: 'Tab Two', template: 'Tab Two' },
    { id: 2, text: 'Tab Three', template: 'Tab Three' },
    { id: 3, text: 'Tab Four', template: 'Tab Four' },
  ];

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly inProgress$ = new BehaviorSubject<boolean>(false);
  protected readonly socialTypesLookup$ = this.lookupsService.socialTypesLookup$;
  protected readonly socialMediaLookup$ = this.lookupsService.socialMediaLookup$;
  protected readonly websiteTypesLookup$ = this.lookupsService.websiteTypesLookup$;

  constructor(
    private agentService: AgentService,
    private agentCampaignWizardService: AgentCampaignWizardService,
    private prospectService: ProspectService,
    private toastrService: ToastrService,
    private readonly lookupsService: LookupsService,
    private readonly hasPermissionPipe: HasPermissionPipe,
    private readonly hasAnyPermissionPipe: HasAnyPermissionPipe,
  ) {}

  ngOnInit(): void {
    this.prospectService.getById(this._selectedAgent.dbId).then(prospect => {
      if (prospect) {
        this.selectedProspect = prospect;
      }
    });
  }

  selectAgentTab(e) {
    this.selectedAgentTab = e.itemData.text;
    this.selectedAgentIndex = e.itemData.id;
  }

  onContentReady(e: any) {
    e.element.children[0].children[this.CAMPAIGNS_TAB_INDEX].classList.add(this.AC_TAB_CLASS);
  }

  async onAllianceCampaignsClick(e) {
    const campaignsTabHasPermission = await firstValueFrom(
      this.hasPermissionPipe.transform(Entity.agentCampaignWizard, [
        EntityPermissionActivityKeys.write,
        EntityPermissionActivityKeys.delete,
      ]),
    );

    if (!this._selectedAgent[AgentKeys.isActiveCampaigns] && campaignsTabHasPermission) {
      const result = confirm(`<i>Are you sure you want to Activate Alliance Campaigns?</i>`, 'Confirm');
      result.then(dialogResult => {
        if (dialogResult) {
          // create activated agent campaigns object
          const activatedCampaigns: AgentCampaigns = Object.assign(
            { ...this._selectedAgent[AgentKeys.campaigns] },
            {
              [AgentCampaignsKeys.campaignsStatus]: AgencyCampaignsStatus.Registered,
              [AgentCampaignsKeys.userSince]: new Date(),
            },
          );
          // update agent.campaigns by updateFields method
          this.agentService
            .updateFields(this._selectedAgent?.dbId, {
              [AgentKeys.isActiveCampaigns]: true,
              [AgentKeys.campaigns]: activatedCampaigns,
            })
            .then(agent => {
              // redirect and unblock Alliance Campaign Tab
              this._selectedAgent[AgentKeys.campaigns] = agent[AgentKeys.campaigns];
              this.selectedAgentIndex = this.CAMPAIGNS_TAB_INDEX;
              this.selectedAgentTab = 'Alliance Campaigns';
              this.toastrService.success('Alliance Campaigns are active now!');
            })
            .catch(e => this.toastrService.error('Alliance Campaigns activation failed!'));
        } else {
          if (this.dxTabsComponent) {
            // redirect to Contact Info Tab
            this.selectedAgentTab = 'Contact Info';
            this.selectedAgentIndex = 0;
            this.dxTabsComponent.instance.repaint();
          }
          e.preventDefault();
          e.stopPropagation();
        }
      });
    }
  }

  selectProspectTab(e) {
    this.selectedProspectTab = e.itemData.template;
  }

  handleAgentFieldsChanges = ({ agentId, updates }) => {
    Object.assign(this._selectedAgent, updates);
  };

  onSaveSocial = (socialsList: Social[] | null): Promise<Social[]> => {
    return this.agentService.updateFields(this._selectedAgent?.dbId, { socials: socialsList }).then(({ socials }) => {
      this._selectedAgent.socials = socials;
      return socials;
    });
  };

  onSaveWebsites = (websitesList: Website[] | null): Promise<Website[]> => {
    return this.agentService
      .updateFields(this._selectedAgent?.dbId, { websites: websitesList })
      .then(({ websites }) => {
        this._selectedAgent.websites = websites;
        return websites;
      });
  };

  protected onSaveAddresses = async (addresses: Address[]) => {
    await this.agentService.updateFields(this.selectedAgent?.dbId, { [AgentKeys.addresses]: addresses });
    this.selectedAgent[AgentKeys.addresses] = addresses;
  };

  setDisabledState(): boolean {
    return !firstValueFrom(
      this.hasPermissionPipe.transform(Entity.agentCampaignWizard, [
        EntityPermissionActivityKeys.write,
        EntityPermissionActivityKeys.delete,
      ]),
    );
  }
}
