<ag-shr-excursions-info-table
  *var="
    Entity.conferenceAttendeeDetails | hasPermission : EntityPermissionActivityKeys.write | async as writePermission
  "
  [selectedExcursionsDataSource]="selectedExcursionsDataSource$ | async"
  [excursionStatisticsMap]="excursionStatisticsMap$ | async"
  [excursionsBudget]="excursionsBudget$ | async"
  [isEditable]="true"
  [canEdit]="writePermission"
  [conferenceExcursions]="excursions$ | async"
>
  <div class="d-flex" *ngIf="writePermission">
    <div *var="excursionsTaskStatus$ | async as excursionsTaskStatus" class="d-flex flex-row align-items-center gap-2">
      Owner:
      <ag-shr-assign-owner-viewer
        [owner]="{
          assignedTo: excursionsTaskStatus?.assignedTo ?? null,
          assignedToNote: ''
        }"
        [assignOwnerList]="assignOwnerList$ | async"
        [onOwnerChange]="onOwnerChange"
      ></ag-shr-assign-owner-viewer>
    </div>
    <div *var="excursionsTaskStatus$ | async as excursionsTaskStatus" class="d-flex flex-row align-items-center gap-2">
      Status:

      <dx-select-box
        [value]="excursionsTaskStatus?.status"
        (onValueChanged)="onTaskStatusChanged(excursionsTaskStatus?.status, $event)"
        [dataSource]="CONFERENCE_REGISTRATIONS_TASKS_STATUS_LOOKUP"
        valueExpr="value"
        displayExpr="description"
      ></dx-select-box>
    </div>
  </div>
</ag-shr-excursions-info-table>
