import { Injectable } from '@angular/core';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'ag-common-svc/lib/services/agency.service';
import { BehaviorSubject } from 'rxjs';
import { Agency, AgencyKeys } from 'ag-common-lib/public-api';

@Injectable()
export class MgaFormService extends BaseFormService<Agent> {
  filteredManagers$ = new BehaviorSubject<Agent[]>([]);

  constructor(
    private agentService: AgentService,
    private toastrService: ToastrService,
    public agencyService: AgencyService,
  ) {
    super();
  }

  getFormData = async (mga?: Partial<Agent>): Promise<Agent> => {
    const initialData: Partial<Agent> = {
      [AgentKeys.manager_id]: mga.manager_id ?? null,
      [AgentKeys.p_mga_id]: mga.p_mga_id ?? null,
      [AgentKeys.p_agency_id]: mga.p_agency_id ?? null,
      [AgentKeys.upline]: mga.upline ?? null,
      [AgentKeys.is_credited]: mga.is_credited ?? false,
      [AgentKeys.is_manager]: mga.is_manager ?? false,
    };
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  updateMGA(agetId: string, mga: Partial<Agent>): Promise<Agent> {
    this.startProgress();
    this.formChangesDetector.clear();
    return this.agentService
      .updateFields(agetId, mga)
      .then(response => {
        this.toastrService.success('MGA Information Successfully Updated');
        this.onSuccessfulUpdated(Object.keys(response));
        return response;
      })
      .catch(err => {
        this.toastrService.error('MGA Information Update Failed!');
        throw new Error();
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  async buildManagersList(agent: Agent): Promise<void> {
    const agentId = agent?.[AgentKeys.p_agent_id];
    const agencyId = agent?.[AgentKeys.p_agency_id];
    const mgaId = agent?.[AgentKeys.p_mga_id];
    const agency = await this.agencyService.getAgencyByAgencyId(agencyId ?? mgaId);

    if (!agency) {
      this.filteredManagers$.next([]);
      return;
    }

    const filteredManagers = [];
    const managers = await this.getManagers(agent, agency);

    managers?.forEach(manager => {
      if (`${manager?.[AgentKeys.p_agent_id]}` === `${agentId}`) {
        return;
      }

      filteredManagers.push(
        Object.assign({}, manager, {
          [AgentKeys.p_agent_name]: [manager?.p_agent_first_name, manager?.p_agent_last_name].filter(Boolean).join(' '),
        }),
      );
    });

    this.filteredManagers$.next(filteredManagers);
  }

  private async getManagers(agent: Agent, agency: Agency) {
    const agentId = agent?.[AgentKeys.p_agent_id];
    const agencyId = agent?.p_agency_id;
    const agencyLead = agency?.[AgencyKeys.agencyLead];

    if (agencyLead && `${agencyLead}` === `${agentId}`) {
      return this.agentService.getManagersByMGAId(agency.mga ?? agent?.p_mga_id, 'p_agent_name');
    }

    if (agencyId) {
      return this.agentService.getManagersByAgencyId(agencyId, 'p_agent_name');
    }

    return this.agentService.getManagersByMGAId(agencyId, 'p_agent_name');
  }
}
