import { Injectable } from '@angular/core';
import { PushNotificationCampaign, PushNotificationCampaignKeys, PushNotificationChannel } from '@ag-common-lib/lib';
import { BaseFormService } from '../../../../../utils/base-form-service';
import { PushNotificationsChannelsService, ScheduledPushNotificationsService } from 'ag-common-svc/public-api';
import { BaseModelKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject, map, shareReplay } from 'rxjs';
import { isPast, isValid } from 'date-fns';

@Injectable()
export class NotificationSchedulerEditModalService extends BaseFormService<PushNotificationCampaign> {
  formData$ = new BehaviorSubject<PushNotificationCampaign>(this.formData);
  private channels$ = this.pushNotificationsChannelsService.getList().pipe(shareReplay(1));

  private _isDeliveredNotification$ = new BehaviorSubject(false);
  isDeliveredNotification$ = this._isDeliveredNotification$.asObservable();

  channelsMap$ = this.channels$.pipe(
    map(channels => {
      const map = new Map<string, PushNotificationChannel>();
      channels?.forEach(channel => {
        const channelDbId = channel?.[BaseModelKeys.dbId];
        map.set(channelDbId, channel);
      });
      return map;
    }),
  );

  constructor(
    private scheduledPushNotificationsService: ScheduledPushNotificationsService,
    private pushNotificationsChannelsService: PushNotificationsChannelsService,
  ) {
    super();
  }

  getFormData = (notificationData?: Partial<PushNotificationCampaign>) => {
    const isDeliveredNotification =
      !!notificationData?.[BaseModelKeys.dbId] &&
      isValid(notificationData?.[PushNotificationCampaignKeys.deliverAt]) &&
      isPast(notificationData?.[PushNotificationCampaignKeys.deliverAt]);
    this._isDeliveredNotification$.next(isDeliveredNotification);

    const initialData = Object.assign({}, notificationData);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        switch (prop) {
          case PushNotificationCampaignKeys.isBroadcast:
            if (value) {
              Object.assign(initialData, {
                [PushNotificationCampaignKeys.channels]: [],
                [PushNotificationCampaignKeys.dynamicSegmentsIds]: [],
                [PushNotificationCampaignKeys.dynamicListsIds]: [],
                [PushNotificationCampaignKeys.recipients]: [],
              });
            }
            break;
          case PushNotificationCampaignKeys.deliverAt:
            this._isDeliveredNotification$.next(!!target?.[BaseModelKeys.dbId] && isValid(value) && isPast(value));
            break;
        }

        return true;
      },
    });

    this.formData$.next(this.formData);

    return this.formData;
  };

  cloneNotification = () => {
    const omitValues = {
      [PushNotificationCampaignKeys.deliverAt]: null,
    };
    Object.values(BaseModelKeys).forEach(key => Object.assign(omitValues, { [key]: null }));
    Object.assign(this.formData, omitValues);
  };

  handleSave = async () => {
    const dbId = this.formData?.[BaseModelKeys.dbId];
    const action = dbId
      ? this.scheduledPushNotificationsService.updateFields(dbId, this.formData)
      : this.scheduledPushNotificationsService.create(this.formData);

    this.startProgress();

    return action.finally(() => {
      this.stopProgress();
    });
  };
}
