import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { AgMediaUploadModel } from '../ag-media-uploader-modal.models';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { extensionToMimeMap, fileToUIBase64 } from '../ag-media-uploader-modal.utils';
import { getFileExtension } from '../../../utils/file.utils';
import { AgMediaUploaderModalService } from '../ag-media-uploader-modal.service';

@Component({
  selector: 'ag-shr-upload-from-disk-tab',
  templateUrl: './upload-from-disk-tab.component.html',
  styleUrls: ['./upload-from-disk-tab.component.scss'],
})
export class UploadFromDiskTabComponent {
  @HostBinding('class') className = 'upload-from-disk-tab';

  @ViewChild('fileUploader') fileUploader!: DxFileUploaderComponent;

  @Input() accept = ['image/*'];
  @Input() placeholder = 'Click or Drag a Image';
  @Input() size: 'small' | 'medium' | 'large' | 'unspecified' = 'unspecified';
  @Output() mediaUrlChange = new EventEmitter<AgMediaUploadModel | null>();

  protected imagePreviewUrl: string;

  private _file: File;
  private _fileBase64: string;
  private _isFileValid$ = new BehaviorSubject(true);

  constructor(
    private toastrService: ToastrService,
    private agMediaUploaderService: AgMediaUploaderModalService,
  ) {}

  handleIsFileValid = (isValid): void => {
    this._isFileValid$.next(isValid);

    if (isValid) {
      this.agMediaUploaderService.setFile({
        contentType: this._file.type,
        fileName: this._file.name,
        base64: this._fileBase64,
        file: this._file,
      });
    }
  };

  clearFile() {
    this.imagePreviewUrl = null;
    this._file = null;
    this._fileBase64 = null;
    this.fileUploader.instance.reset(); // Clears the DxFileUploader component
  }

  // Method triggered on file input change
  async fileChangeEvent(event: Event) {
    this.imagePreviewUrl = null;
    this._fileBase64 = null;
    const file: File = (event?.target as HTMLInputElement)?.files[0];

    if (!file) {
      return;
    }

    // Convert file to Base64 for other types
    this._fileBase64 = await fileToUIBase64(file).catch(() => {
      this._isFileValid$.next(false);
      return null;
    });

    if (!this._fileBase64) {
      return;
    }

    // Validate the file
    const isFileValid = this.validateFile(file);
    if (!isFileValid) {
      return;
    }

    this._file = file;

    this.setPreview();
  }

  clear() {
    this.imagePreviewUrl = null;
    this._file = null;
  }

  private setPreview = () => {
    const isEmailFile = this.isEmailFile(this._file);

    if (isEmailFile) {
      this.imagePreviewUrl = '/assets/file-placeholders/email-opened.png';
      return;
    }

    this.imagePreviewUrl = this._fileBase64;
  };

  private isEmailFile(file: File): boolean {
    const isEmailType = file.type === 'message/rfc822' || file.type === 'application/vnd.ms-outlook';
    const isEmailExtension = file.name.endsWith('.eml') || file.name.endsWith('.msg');

    return isEmailType || isEmailExtension;
  }

  private validateFile(file: File): boolean {
    return this.validateSize(file.size) && this.isFileTypeAllowed(file);
  }

  private isFileTypeAllowed(file: File): boolean {
    const { type, name } = file;

    const isMimeTypeValid = this.accept.some(acceptedType => type.match(acceptedType));

    const extension = getFileExtension(name);
    const isExtensionValid = this.accept.some(acceptedType => this.extensionMatchesMimeType(acceptedType, extension));

    // Additional validation for .msg (Outlook) files
    if (extension === 'msg') {
      return this.validateOutlookFile(type);
    }

    if (!isMimeTypeValid && !isExtensionValid) {
      this.toastrService.error('Invalid file type! Only specific types are allowed.');
      return false;
    }

    return true;
  }

  private extensionMatchesMimeType(acceptedType: string, extension: string): boolean {
    return extensionToMimeMap[extension] === acceptedType;
  }

  // Further validation for specific file types
  private validateOutlookFile(type: string): boolean {
    return type === 'application/vnd.ms-outlook' || type === '';
  }

  private validateSize(fileSize: number): boolean {
    const allowedSizeMb = 10;
    const maxAllowedSize = allowedSizeMb * 1024 * 1024; // MB => bytes
    if (fileSize > maxAllowedSize) {
      this.toastrService.error(
        `File is too big! Max allowed size is ${allowedSizeMb}MB. Image 1920 px width or height is recommended.`,
      );
      return false;
    }
    return true;
  }
}
