<dx-data-grid
  #agentsGridRef
  class="notification-add-recipients-modal__grid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [renderAsync]="true"
  [columnAutoWidth]="true"
  height="100%"
  (onRowRemoving)="onRowRemoving($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
>
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <div class="ml-2">
          <b>{{ title }}</b>
        </div>
      </div>
    </dxi-item>

    <dxi-item
      [visible]="mode === 'view'"
      location="after"
      widget="dxButton"
      [options]="{ icon: 'plus', onClick: addRecipients }"
    ></dxi-item>
  </dxo-toolbar>

  <dxo-editing mode="row" [useIcons]="true" [allowDeleting]="mode === 'view'"></dxo-editing>

  <dxo-selection
    [mode]="'multiple'"
    selectAllMode="allPages"
    showCheckBoxesMode="always"
    [allowSelectAll]="true"
  ></dxo-selection>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column
    [dataField]="BaseModelKeys.dbId"
    [caption]="'List'"
    [width]="100"
    [visible]="mode === 'add'"
    sortOrder="asc"
    [sortingMethod]="sortingMethod"
    [fixed]="true"
    [fixedPosition]="'left'"
  >
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_agent_id" [caption]="'Agent Id'" [width]="100"> </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_email" dataType="string" [caption]="'Email'"> </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_agent_first_name" dataType="string" [caption]="'First Name'"> </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_agent_last_name" dataType="string" [caption]="'Last Name'"> </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_mga_id" [caption]="'MGA'" [minWidth]="200">
    <dxo-lookup
      [dataSource]="agencies$ | async"
      [allowClearing]="false"
      [displayExpr]="'name'"
      [valueExpr]="'agency_id'"
    >
    </dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_agency_id" [caption]="'Agency'" [minWidth]="200">
    <dxo-lookup
      [dataSource]="agencies$ | async"
      [allowClearing]="true"
      [displayExpr]="'name'"
      [valueExpr]="'agency_id'"
    >
    </dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.agent_type" [caption]="'Agent Type'" [minWidth]="130">
    <dxo-lookup
      [dataSource]="AGENT_TYPE_LOOKUP"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
      [allowClearing]="false"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.is_manager" [caption]="'Is Manager'" width="100">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.is_rmd" [caption]="'Is RMD'" width="100">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>
</dx-data-grid>
