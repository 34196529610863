<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Email Addresses'"
    [isEditVisible]="canEdit"
    (clickEdit)="showEmailAddressesEditorModal()"
  ></ag-shr-editor-toolbar>

  <div class="row my-2 px-3" *ngIf="(emailAddresses$ | async)?.length > 0">
    <div class="col-6 text-start"></div>
    <div class="col-4 text-center"></div>
    <div class="col-2 text-center">
      <span style="font-weight: bold; color: #505d69">Login</span>
    </div>
  </div>
  <div class="row my-2 px-3 text-center" *ngIf="(emailAddresses$ | async)?.length == 0">
    <p class="col editor-empty-text">No Email Addresses Currently Exist</p>
  </div>
  <ng-container *ngFor="let address of emailAddresses$ | async">
    <div class="row mb-2 px-3 py-1">
      <div class="col-6 text-start">
        {{ address.address }}
      </div>
      <div class="col-4 text-center">
        {{ address.email_type | lookupDescription: Lookups.EmailTypes | async }}
      </div>
      <div class="col-2 text-center">
        <i *ngIf="address.is_login" class="dx-icon-check"></i>
      </div>
    </div>
  </ng-container>
</div>

<ag-shr-set-login-email-modal></ag-shr-set-login-email-modal>

<ag-shr-modal-window
  #emailAddressesEditorModalRef
  [title]="'Add / Edit Email Addresses'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="false"
  (onPopupClose)="dataGridRef.instance.cancelEditData()"
>
  <dx-data-grid
    #dataGridRef
    [dataSource]="emailAddressesDataSource$ | async"
    [showRowLines]="true"
    [showBorders]="true"
    [showColumnLines]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
  >
    <dxo-toolbar>
      <dxi-item
        *ngIf="canEdit"
        location="after"
        widget="dxButton"
        locateInMenu="auto"
        cssClass="task-list-manager__toolbar-control"
        [options]="{
          hint: 'CHANGE LOGIN EMAIL',
          icon: 'agi-user-gear',
          onClick: showSetLoginEmailModal,
        }"
      ></dxi-item>

      <dxi-item name="addRowButton" [visible]="canCreate"></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowUpdating]="canEdit"
      [allowDeleting]="canDelete"
      [allowAdding]="canCreate"
    >
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column dataField="address">
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_EMAIL_TEXT"></dxi-validation-rule>
      <dxi-validation-rule type="email" [message]="Messages.INVALID_EMAIL_FORMAT_TEXT"></dxi-validation-rule>

      <dxi-validation-rule
        type="async"
        [message]="Messages.ALREADY_REGISTERED_EMAIL_TEXT"
        [validationCallback]="asyncUniqEmailValidation"
      ></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="email_type" caption="Type">
      <dxo-lookup
        [dataSource]="emailTypeLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
      ></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="is_login" caption="Login" dataType="boolean" [allowEditing]="false"></dxi-column>

    <dxi-column type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete" [visible]="canDeleteEmailAddress"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</ag-shr-modal-window>
