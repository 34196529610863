import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { NotificationRecipientsGridComponent } from './notification-recipients-grid.component';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { DxiColumnModule } from 'devextreme-angular/ui/nested';

@NgModule({
  declarations: [NotificationRecipientsGridComponent],
    imports: [ CommonModule, SharedModule, ModalWindowModule, DxiColumnModule ],
  exports: [NotificationRecipientsGridComponent],
})
export class NotificationRecipientsGridModule {}
