import { NgModule } from '@angular/core';
import { AgentCampaignLogoHeadshotComponent } from './agent-campaign-logo-headshot.component';
import { SharedModule } from '../../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../../button-with-indicator/button-with-indicator.module';
import { AgentCampaignLogoHeadshotService } from './agent-campaign-logo-headshot.service';
import { AgentCampaignPictureComponent } from './agent-campaign-picture/agent-campaign-picture.component';
import { DxDropDownButtonModule, DxPopoverModule, DxTooltipModule } from 'devextreme-angular';
import { AgMediaUploaderModalModule } from '../../ag-media-uploader/ag-media-uploader-modal.module';
import { ImageWithLoaderModule } from '../../image-with-loader/image-with-loader.module';
import { ImageCropperModalModule } from '../../../../lib/components/image-cropper-modal/image-cropper-modal.module';

@NgModule({
  declarations: [AgentCampaignLogoHeadshotComponent, AgentCampaignPictureComponent],
  imports: [
    SharedModule,
    ButtonWithIndicatorModule,
    DxPopoverModule,
    DxTooltipModule,
    AgMediaUploaderModalModule,
    DxDropDownButtonModule,
    ImageWithLoaderModule,
    ImageCropperModalModule,
  ],
  exports: [AgentCampaignLogoHeadshotComponent],
  providers: [AgentCampaignLogoHeadshotService],
})
export class AgentCampaignLogoHeadshotModule {}
