<header class="ag-header">
  <dx-toolbar class="ag-header__toolbar" (onInitialized)="onToolbarInitialized($event)">
    <dxi-item
      [visible]="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu,
      }"
    >
    </dxi-item>

    <dxi-item location="before" [template]="'logoTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'logoTemplate'">
      <img
        alt="AG logo"
        class="ag-header__logo"
        src="assets/ag-logo-sm-blue.png"
        (click)="navigateToPath(AppRoutes.Home)"
      />
    </div>

    <dxi-item location="before" [visible]="!!title" [template]="'headerTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'headerTemplate'">
      <span class="ag-header__title" (click)="navigateToPath(AppRoutes.Home)">
        {{ title }}
      </span>
    </div>

    <dxi-item location="before" widget="dxMenu" locateInMenu="auto" [options]="menuOptions$ | async"></dxi-item>

    <dxi-item
      location="after"
      widget="dxDropDownButton"
      locateInMenu="never"
      cssClass="ag-header__user-menu"
      [options]="userBoxOptions$ | async"
      [height]="menuHeight"
    ></dxi-item>
  </dx-toolbar>
</header>
