import { Pipe, PipeTransform } from '@angular/core';
import { BaseModelKeys, Agent } from '@ag-common-lib/public-api';
import { firstValueFrom } from 'rxjs';
import { AgentService, QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/public-api';

@Pipe({
  name: 'recipientsDataSourceByAgentDbIds',
})
export class RecipientsDataSourceByAgentDbIdsPipe implements PipeTransform {
  constructor(private agentService: AgentService) {}

  async transform(
    recipientsDbIds: string[],
    operand:
      | WhereFilterOperandKeys.notIn
      | WhereFilterOperandKeys.in
      | WhereFilterOperandKeys.arrayContains = WhereFilterOperandKeys.in,
  ): Promise<Agent[]> {
    const dbIdsCount = recipientsDbIds?.length;
    const promises: Promise<any[]>[] = [];
    if (!dbIdsCount && operand === WhereFilterOperandKeys.in) {
      return await Promise.resolve([]);
    }

    if (!dbIdsCount && operand === WhereFilterOperandKeys.notIn) {
      const promise = firstValueFrom(
        this.agentService.getList([], { sortField: 'p_agent_first_name', includeRef: false }),
      );
      return await Promise.resolve(promise);
    }

    const batchSize = operand === WhereFilterOperandKeys.notIn ? 10 : 30; // according to FB docs
    const batchCount = Math.ceil(dbIdsCount / batchSize);

    for (let i = 0; i < batchCount; i++) {
      const startIndex = i * batchSize;
      const endIndex = (i + 1) * batchSize;
      const batchValues = recipientsDbIds.slice(startIndex, endIndex);

      const qp = new QueryParam(BaseModelKeys.dbId, operand, batchValues);

      const promise = firstValueFrom(
        this.agentService.getList([qp], { sortField: 'p_agent_first_name', includeRef: false }),
      );

      promises.push(promise);
    }
    return await Promise.all(promises).then(items => items.flat(1));
  }
}
