import { Injectable } from '@angular/core';
import { AgentService, ConferenceService, DynamicSegmentsGroupService } from 'ag-common-svc/public-api';
import { shareReplay } from 'rxjs';

@Injectable()
export class NotificationAddSegmentsModalService {
  agents$ = this.agentService.getList([], { sortField: 'p_agent_first_name', includeRef: false });
  conferences$ = this.conferenceService.getList().pipe(shareReplay(1));
  segments$ = this.dynamicSegmentsGroupService.segments$;

  constructor(
    private agentService: AgentService,
    private conferenceService: ConferenceService,
    private dynamicSegmentsGroupService: DynamicSegmentsGroupService,
  ) {}
}
