import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import {
  Agent,
  AGENT_STATUS,
  AgentKeys,
  AgentMedia,
  AGMedia,
  AGMediaKeys,
  Lookup,
  Lookups,
  MediaSize,
} from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { AgentHeaderService } from './agent-header.service';
import { AgentHeaderKeys } from './agent-header.model';
import { map, shareReplay } from 'rxjs/operators';
import { DropZoneComponent } from '../../../../components/drop-zone/drop-zone.component';
import { ModalWindowComponent } from '../../../../components/modal-window/modal-window.component';
import { FullAddressPipe } from '../../../../../shared/pipes/full-address.pipe';
import { PhoneNumberMaskPipe } from '../../../../../shared/pipes/phone-number-mask.pipe';
import { AgMediaUploaderModalComponent } from '../../../ag-media-uploader/ag-media-uploader-modal.component';
import { ImageCropperModalComponent } from '../../../../components/image-cropper-modal/image-cropper-modal.component';
import { WasabiImgPipe } from '../../../../../shared/pipes/wasabi-img.pipe';
import { AgentImagesService } from '../../../../services/agent-images.service';
import { Entity } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model';

@Component({
  selector: 'ag-shr-agent-header',
  templateUrl: './agent-header.component.html',
  styleUrls: ['./agent-header.component.scss'],
  providers: [AgentHeaderService, FullAddressPipe, PhoneNumberMaskPipe, WasabiImgPipe, AgentImagesService],
})
export class AgentHeadersComponent {
  @HostBinding('class') className = 'agent-header';
  // eslint-disable-next-line
  @Input('agentId') set _agentId(value: string) {
    this.agentId = value;
    this.mediaPathPrefix = `agents/${value}`;
    this.agent$ = this.agentHeaderService
      .getAgentById(value)
      .pipe(map((agent: Agent) => (this.agentHeaderFormDetails = this.agentHeaderService.getFormData(agent))));

    this.agentMediaDataSource$ = this.agentImagesFetchUtil.fetchAgentImages(this.agentId).pipe(shareReplay(1));
  }
  // eslint-disable-next-line
  @Output() onFieldsUpdated = new EventEmitter<{ agentId: string; updates: Partial<Agent> }>();
  @ViewChild('agentHeaderModalRef', { static: true }) agentHeaderModalComponent: ModalWindowComponent;
  @ViewChild('agentProfilePictureModalRef', { static: true }) agentProfilePictureModalComponent: ModalWindowComponent;
  @ViewChild('profilePictureDropZoneRef', { static: true }) profilePictureDropZoneComponent: DropZoneComponent;
  @ViewChild('agentHeaderFormRef', { static: false }) agentHeaderFormComponent: DxFormComponent;
  @ViewChild(AgMediaUploaderModalComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent; // ViewChild for DxTooltipComponent
  @ViewChild(ImageCropperModalComponent, { static: false }) imageCropperModalComponent: ImageCropperModalComponent; // ViewChild for DxTooltipComponent

  mediaPathPrefix: string;
  agentMediaDataSource$: Observable<AgentMedia[]>;
  public AgentKeys = AgentKeys;
  public AgentHeaderKeys = AgentHeaderKeys;
  public agentHeaderFormDetails;
  public inProgress$: Observable<boolean>;
  public validationGroup = 'agentHeaderValidationGroup';
  public selectedAgentHeaderType$: BehaviorSubject<Lookup>;
  public selectedPrefix$: BehaviorSubject<Lookup>;
  public selectedSuffix$: BehaviorSubject<Lookup>;
  public agent$: Observable<Agent>;

  private agentId: string;
  protected readonly Lookups = Lookups;
  readonly agentStatus = AGENT_STATUS;
  readonly MediaSize = MediaSize;

  protected readonly editOptions;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private agentHeaderService: AgentHeaderService,
    public phoneNumberMaskPipe: PhoneNumberMaskPipe,
    public fullAddressPipe: FullAddressPipe,
    private agentImagesFetchUtil: AgentImagesService,
    private wasabiImgPipe: WasabiImgPipe,
  ) {
    this.inProgress$ = agentHeaderService.inProgress$;
    this.selectedPrefix$ = agentHeaderService.selectedPrefix$;
    this.selectedSuffix$ = agentHeaderService.selectedSuffix$;
    this.editOptions = [
      { icon: 'mdi mdi-upload', text: 'Change Picture', onClick: this.onUploadClicked.bind(this) },
      { icon: 'mdi mdi-pencil', text: 'Edit Thumbnail', onClick: this.handleCropImage },
    ];
  }

  onUploadClicked(): void {
    this.mediaUploaderComponent.showModal();
  }

  onImageCropped = payload => {
    return this.agentHeaderService.onImageCropped(this.agentId, payload);
  };

  onMediaChanged = async (mediaUrl: AGMedia) => {
    await this.agentHeaderService.onProfileImageChanged(this.agentId, mediaUrl);
  };

  handleCropImage = async () => {
    const headshot = await (await firstValueFrom(this.agent$)).head_shot;
    this.imageCropperModalComponent.showModal(
      this.wasabiImgPipe.transform(headshot?.wasabiPath),
      headshot?.imageCropperPayload,
    );
  };

  public saveAgentHeaderInfoUpdates = () => {
    const validationResults = this.agentHeaderFormComponent.instance.validate();

    if (validationResults.isValid) {
      this.agentHeaderService.handleSave(this.agentId).then(data => {
        this.onFieldsUpdated.emit(data);
        this.agentHeaderModalComponent.hideModal();
      });
    }
  };

  public showEditorModal = () => {
    this.agentHeaderModalComponent.showModal();
  };

  public showProfilePictureEditorModal = () => {
    this.agentProfilePictureModalComponent.showModal();
  };

  public handleClosePopup = e => {
    this.agentHeaderService.onCancelEdit(e);
  };

  public handlePrefixSelect = item => {
    this.selectedPrefix$.next(item);
  };

  public handleSuffixSelect = item => {
    this.selectedSuffix$.next(item);
  };

  public phoneNumberDisplayExpr = item => {
    return this.phoneNumberMaskPipe.transform(item, true);
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
