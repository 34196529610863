import { Component, Input, HostBinding } from '@angular/core';
import {
  AgentKeys,
  Conference,
  ConferenceKeys,
  Constants,
  ElasticSearchAgent,
  EMAIL_TEMPLATE_LOOKUP,
  EmailAddressKeys,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LookupKeys,
  Messages,
} from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST } from '../../../config/conferences.config';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import DataSource from 'devextreme/data/data_source';
import { getAgentFullName } from 'ag-common-svc/lib/services/agent.service/agent-service-utils';
import CustomStore from 'devextreme/data/custom_store';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';
import { AgentEmailAddressesService } from 'ag-common-svc/public-api';

@Component({
  selector: 'ag-crm-conference-email-sender-form',
  templateUrl: './conference-email-sender-form.component.html',
  styleUrls: ['./conference-email-sender-form.component.scss'],
})
export class ConferenceEmailSenderFormComponent {
  @HostBinding('class') className = 'conference-email-sender-form';
  @Input() conference: Partial<Conference>;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected readonly LookupKeys = LookupKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;
  protected readonly requiredMessage = Messages.REQUIRED_TEXT;
  protected readonly emailTemplatesLookup = EMAIL_TEMPLATE_LOOKUP;
  protected readonly agentsEmailAddressesDataSource = this.agentEmailAddressesService.agentsEmailAddressesDataSource;
  protected dataSource = new DataSource({
    paginate: true,
    pageSize: 250,
    store: new CustomStore({
      key: 'dbId',
      loadMode: 'processed',
      load: async loadOptions => {
        const filter: any[] = [];

        if (loadOptions.filter) {
          this.conference?.[ConferenceKeys.bccEmail]?.forEach((item: any) => {
            if (filter?.length) {
              filter.push('or');
            }
            filter.push([AgentKeys.email_addresses, DxFilterOperators.contains, item?.address]);
          });
        }

        if (loadOptions.searchValue) {
          [AgentKeys.email_addresses, AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name].forEach(field => {
            if (filter?.length) {
              filter.push('or');
            }

            filter.push([field, DxFilterOperators.contains, loadOptions.searchValue]);
          });
        }

        loadOptions.filter = filter;
        const data = [];
        const response = await this.agentElasticSearchService.getFromElastic(loadOptions);
        const aggregations = response?.aggregations;
        const emailsAggregations = aggregations?.[AgentKeys.email_addresses];

        response?.data?.forEach((agent: ElasticSearchAgent) => {
          const fullName = getAgentFullName(agent);
          agent?.[AgentKeys.email_addresses]?.forEach(emailAddress => {
            const address = emailAddress?.[EmailAddressKeys.address];
            data.push({
              name: fullName,
              address: address,
            });
          });
        });

        return {
          data,
          totalCount: emailsAggregations?.doc_count,
        };
      },
    }),
  });

  constructor(
    private agentElasticSearchService: AgentElasticSearchService,
    private agentEmailAddressesService: AgentEmailAddressesService,
  ) {}

  protected valueExpr = e => {
    return `${e?.name}_${e?.address}`;
  };
}
