<ag-shr-modal-window
  #notificationAddListsModalRef
  [width]="'80vw'"
  [height]="'80vh'"
  [title]="title"
  (onSaveClick)="addNotificationLists()"
  [actionTitle]="'ADD'"
  [useScrollView]="false"
  [saveButtonDisabled]="!listsDbIds?.length"
>
  <dx-data-grid
    #listsGridRef
    class="notification-add-lists-modal__grid"
    *ngIf="notificationAddListsModalRef?.popupComponent?.visible ?? false"
    [dataSource]="dataSource"
    [showBorders]="true"
    [renderAsync]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [noDataText]="'No Lists Exists'"
    width="100%"
    height="100%"
    (onSelectionChanged)="onSelectionChanged($event)"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-paging [pageSize]="30"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="false" [showInfo]="true" [visible]="showPager()"></dxo-pager>

    <dxo-editing
      mode="row"
      [useIcons]="true"
      [allowDeleting]="false"
      [allowUpdating]="false"
      [allowAdding]="false"
    ></dxo-editing>

    <dxo-selection
      [mode]="'multiple'"
      selectAllMode="allPages"
      showCheckBoxesMode="always"
      [allowSelectAll]="true"
    ></dxo-selection>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <div class="ml-2">
            <b>Mailing Lists</b>
          </div>
        </div>
      </dxi-item>

      <dxi-item name="searchPanel" cssClass="ag-grid__toolbar-control"></dxi-item>
    </dxo-toolbar>

    <dxi-column
      [dataField]="BaseModelKeys.dbId"
      [caption]="'List'"
      [width]="100"
      [visible]="false"
      sortOrder="asc"
      [sortingMethod]="sortingMethod"
    >
    </dxi-column>

    <dxi-column [dataField]="DynamicListsGroupKeys.title" dataType="string" [caption]="'Title'" [width]="200">
    </dxi-column>

    <dxi-column
      [dataField]="DynamicListsGroupKeys.description"
      [caption]="'Description'"
      cellTemplate="notificationCellTemplate"
    >
      <div *dxTemplate="let cell of 'notificationCellTemplate'">
        <div [innerHTML]="cell?.value"></div>
      </div>
    </dxi-column>
  </dx-data-grid>
</ag-shr-modal-window>
